import { Component, Input, OnInit } from '@angular/core';
import { LogService } from './services/log.service';
import { ConfigurationService } from './services/configuration.service';
import { AppInsightsMonitoringService } from './services/app-insights-monitoring.service';
import { CjaTagService } from './services/cja-tag.service';

@Component({
  selector: 'cs-fad',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [CjaTagService]
})
export class AppComponent implements OnInit {
  disclaimerVisible = true;
  disclaimer: string;
  marketDivision: string;
  pageWidthStyling = false;
  @Input() configurationUrl = './fad-configuration.json';
  @Input() embedSource?: string;
  isCernerPage=false;
  slotUrl: string;

  constructor(
    private logService: LogService,
    private configurationService: ConfigurationService,
    private appInsightsMonitor: AppInsightsMonitoringService
  ) {}

  ngOnInit(): void {
    this.logService.debug('Application Loaded');
    this.isCernerPage=this.configurationService.getCernerPage();
    this.slotUrl=this.configurationService.getSlotUrl();
    const tokenFromWindowSession =
      window.sessionStorage.getItem('CERNER_BCS_WINDOW_TOKEN') ?? '';
    if (tokenFromWindowSession !== '') {
      this.isCernerPage = false;
    }
    this.disclaimer = this.configurationService.getDisclaimerCopy();
    this.marketDivision = this.configurationService.getConfigType();
    this.pageWidthStyling = this.configurationService.getIsFiltersLeftSidePanelRequired();
  }
}
