import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

@Component({
  selector: 'cs-provider-role-checkbox',
  templateUrl: './provider-role-checkbox.component.html',
  styleUrls: ['./provider-role-checkbox.component.scss']
})
export class ProviderRoleCheckboxComponent implements OnChanges {
  @Input() selectCheckBox: boolean;

  isProviderRoleTypesSelected = false;

  @Output() includeProviderRoleTypes = new EventEmitter<boolean>();

  ngOnChanges(): void {
    this.isProviderRoleTypesSelected = this.selectCheckBox;
  }

  checkProviderRole(): void {
    this.isProviderRoleTypesSelected = !this.isProviderRoleTypesSelected;
    sessionStorage.setItem('manualSelection', String(this.isProviderRoleTypesSelected));
    this.includeProviderRoleTypes.emit(this.isProviderRoleTypesSelected);
  }
}
