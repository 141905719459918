import { SearchFacet } from './search-facet';

export class SearchFilter {
  searchDistanceMiles: number;
  currentPageNumber: number;
  pageSize: number;
  pages: number;
  isMoreDataAvailable: boolean;
  totalResults: number;
  searchTerm: string;
  sortBy: number;
  sortByName: string;
  facets: SearchFacet[];
}

export interface CjaTagSearchFilter {
  facility?: string;
  medicalGroup?: string;
  serviceLine?: string;
  scheduleFilterDetails: string;
  scheduleLocation?: string;
  scheduleKeyword?: string;
  scheduleBookOnline: boolean;
  totalResultsCount: number;
  searchURL: string;
  eventName: string;
}
