export interface SelectedLocation extends LocationCookieData {
  address: string;
  googlePlaceId: string;
  isLocationValid: boolean;
}

export interface LocationByIpResult {
  ip: string;
  location: LocationByIpLocationData;
}

export interface LocationByIpLocationData extends LocationCookieData {
  ip: string;
}

export interface LocationCookieData {
  neighborhood: string;
  city: string;
  latitude: number;
  longitude: number;
  name: string;
  state: string;
  zip: string;
}

export class LocationConverter {
  public static convertTo(locationCookieData: LocationCookieData): SelectedLocation {
    if (!locationCookieData || (!locationCookieData.city && !locationCookieData.state)) {
      return null;
    }
    return {
      name: locationCookieData.name,
      neighborhood: locationCookieData.neighborhood,
      city: locationCookieData.city,
      state: locationCookieData.state,
      latitude: locationCookieData.latitude,
      longitude: locationCookieData.longitude,
      zip: locationCookieData.zip,
      isLocationValid: true
    } as SelectedLocation;
  }
}

export interface LocationSuggestionResult {
  locationName: string;
  googlePlaceId: string;
  sessionToken: string;
}

export interface LocationDetailResult {
  neighborhood: string;
  city: string;
  country: string;
  latitude: number;
  longitude: number;
  name: string;
  sessionToken: string;
  state: string;
  zip: string;
}
