import { Component, Input, ViewChild, ElementRef, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'cs-hospital-affiliations',
  templateUrl: './hospital-affiliations.component.html',
  styleUrls: ['./hospital-affiliations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HospitalAffiliationsComponent {
  @Input() affiliations: string[] = [];
  @Input() hideExtraContent: boolean;

  @ViewChild('expandableList') expandableList: ElementRef;
}
