import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Provider } from '../../models/provider';

@Component({
  selector: 'cs-get-to-know',
  templateUrl: './get-to-know.component.html',
  styleUrls: ['./get-to-know.component.scss']
})
export class GetToKnowComponent {
  @Input() provider: Provider;
  @Input() videoId: string;
  @Input() getToKnowContent: any;
  @Input() isShowProviderVideo: boolean;
  @Input() showHospitalAffiliations: boolean;
  @Input() inMyOwnWordsTitle: string;
  @Input() hideExtraPhilosophyContent: boolean;
  @Input() showMoreLessButtonForPhilosophy: boolean;
  @Input() hideExtraOwnwordsContent: boolean;
  @Input() showMoreLessButtonForOwnwords: boolean;
  @Input() isUpdatedProviderDetails = false;
  @Input() routId: string;
  @Output() callAdobeService = new EventEmitter<Provider>();

  onStateChange(provider) {
    this.callAdobeService.emit(provider);
  }

  medGrpLinkClicked() {
    //save the access of non fad url in session and navigate to suggestAnEditLink
    if (!window.sessionStorage.getItem('nonFadUrlAccessed')) {
      window.sessionStorage.setItem('nonFadUrlAccessed', 'true');
    }
  }
}
