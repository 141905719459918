import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RuntimeConfiguration } from './configuration-injection.service';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class SchedulingService {
  runtimeConfig: RuntimeConfiguration;

  constructor(public http: HttpClient, private configService: ConfigurationService) {
    this.runtimeConfig = this.configService.getRuntimeConfiguration();
  }

  getLocationsByApptointmentType(apptType: string, locationIds: string = null): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': this.runtimeConfig.apimSubscriptionKey
      })
    };

    let locationQuery = '';

    if (locationIds && locationIds !== '') {
      locationQuery = `&locations=${locationIds}`;
    }
    let httpParams = new HttpParams();
    httpParams = httpParams.append('types', `${apptType}${locationQuery}`);
    const url = `${this.runtimeConfig.schedulingApiBaseUrl}/v1/provider/location/schedule`;
    return this.http.get(url.toString(), {
      headers: httpOptions.headers,
      params: httpParams
    });
  }

  getV5AuthToken(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': this.runtimeConfig.apimSubscriptionKey
      })
    };

    return this.http.get(`${this.runtimeConfig.schedulingApiBaseUrl}/v2/visit/authtoken`, httpOptions);
  }

  getV5AppointmentTypes(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.get(this.runtimeConfig.covidAppointmentTypes, httpOptions);
  }

  getLocationsByApptointmentTypeV5(
    appointmentTypes: string[],
    locationSourceIds: string[],
    authToken: string,
    system = 'geSac'
  ): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': this.runtimeConfig.apimSubscriptionKey,
        Authorization: `Bearer ${authToken}`
      })
    };

    const body = {
      appointmentTypes,
      locationSourceIds,
      system
    };

    return this.http.post(this.runtimeConfig.v5OpenSlotsEndpoint, body, httpOptions);
  }

  validateNotableToken(token: string): Observable<any> {
    return this.http.get(`${this.runtimeConfig.notableTokenValidationUrl}?token=${token}`, this.getHttpOptions());
  }

  validateCSHToken(token: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-virt-reg': token
      })
    };

    return this.http.get(`${this.runtimeConfig.cshValidationUrl}`, httpOptions);
  }

  getMarketLocations(): Observable<any> {
    return this.http.get(this.runtimeConfig.marketLocations, this.getHttpOptions());
  }

  getHttpOptions(): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
  }
}
