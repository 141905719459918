import { FadUrlBuilderStrategy } from './fad-url-builder-strategy';
import { UrlBuilderParams } from './url-builder-params';

export class SearchTermUrlBuilderStrategy extends FadUrlBuilderStrategy {
  shouldBuild(urlBuilderParams: UrlBuilderParams): boolean {
    return !urlBuilderParams.medGroup && !this.hasLocationData(urlBuilderParams);
  }

  createUrlSegments(urlBuilderParams: UrlBuilderParams): void {
    this.addSegments('', 't');
    if (urlBuilderParams.searchTerm) {
      this.addSegments(urlBuilderParams.searchTerm.stripDash('_'));
    }
  }
}
