<div class="banner-info-card">
  <div class="card-information">
    <div class="banner-card-header" *ngFor="let banner of banners">
      <div>
        <div
          class="banner-header"
          [ngClass]="{
            'dh-banner': divisionCodes.includes('dignity-health'),
            'chi-banner': !divisionCodes.includes('dignity-health')
          }"
        >
          <img *ngIf="showProviderLogos && banner.bannerImage" src="{{ banner.bannerImage }}" alt="" class="banner-image" />
          {{ banner.header }}
        </div>
        <p [innerHTML]="banner.content" class="banner-description"></p>
      </div>
    </div>
  </div>
</div>
