<div class="page-header" style="text-align: center">
  <img src="{{ headerImageUrl }}" alt="" />
  <div class="border"></div>
</div>

<div class="page-footer">
  <div class="border"></div>
  <p class="p3">{{ footer }}</p>
</div>

<table aria-label="Provider details">
  <th scope="col">
    <tr>
      <td>
        <div class="page-header-space"></div>
      </td>
    </tr>
  </th>
  <tbody class="body">
    <tr>
      <td>
        <div class="provider-image-container" *ngIf="providerImage">
          <img class="provider-image" src="{{ providerImage.url }}" alt="{{ provider.displayFullName }}" (error)="getAltImageSrc($event)" />
        </div>
        <div class="provider-heading">
          <div class="provider-name">
            <h3>
              {{ provider.displayFullName }}
              <img *ngFor="let imageUrl of logoUrls" src="{{ imageUrl?.url }}" alt="{{ imageUrl?.altName }}" class="provider-logo" />
              <div class="provider-specialty">
                <p *ngFor="let speciality of providerSpecialities" class="p1">{{ speciality }}</p>
                <p *ngFor="let nonSeoSpeciality of nonSEOSecondarySpecialities" class="p1">{{ nonSeoSpeciality }}</p>
              </div>
            </h3>
          </div>
        </div>
        <div *ngIf="provider.personalInfo != null && provider.personalInfo != ''">
          <p [innerHTML]="provider.personalInfo"></p>
        </div>
        <cs-badges [isPrint]="true" [provider]="provider"></cs-badges>
        <div class="office-details" *ngIf="officeAddresses">
          <div *ngIf="officeAddresses.length > 0">
            <h2>Locations</h2>
            <div class="office" *ngFor="let office of officeAddresses; let i = index">
              <ng-container *ngTemplateOutlet="officeDetails; context: { office: office, i: i }"></ng-container>
            </div>
          </div>
          <div *ngIf="provider.languages.length > 0">
            <h2>Languages</h2>
            <div *ngFor="let item of provider.languages">
              <p>{{item}}</p>
            </div>
          </div>
          <div *ngIf="provider.gender">
            <h2>Gender</h2>
            <p>{{provider.gender}}</p>
          </div>
        </div>
        <div class="medical-group" *ngIf="getToKnowContent.length > 0">
          <h2><strong>Medical group</strong></h2>
          <div *ngFor="let getToKnow of getToKnowContent" class="getToKnowContent">
            <img class="provider-logo" src="{{ getToKnow.logo }}" alt="" />
            <p [innerHTML]="getToKnow.content"></p>
          </div>
        </div>
        <div class="board-cert" *ngIf="provider.certifications.length > 0">
          <h2><strong>Board certifications</strong></h2>
          <div *ngFor="let item of provider.certifications">
            <p>{{item}}</p>
          </div>
        </div>
        <ng-template #officeDetails let-office="office" let-i="i">
          <div class="office-details">
            <div class="office-address">
              <div class="office-name">
                <h3
                  class="office-name-text"
                  [ngClass]="{
                  'dh': divisionCodes.includes('dignity-health'),
                  'chi': !divisionCodes.includes('dignity-health')
                }"
                  *ngIf="office.name && (office.name.length == 1 ? !office.name.includes('.') : true)"
                >
                  {{ office.name }}
                </h3>
                <h3
                  class="office-name-text"
                  [ngClass]="{
                  'dh': divisionCodes.includes('dignity-health'),
                  'chi': !divisionCodes.includes('dignity-health')
                }"
                  *ngIf="!office.name"
                >
                  {{ provider.officeNames[0] }}
                </h3>
              </div>
              <div class="street">
                <div *ngIf="office.phones?.length > 0 || office.faxes?.length > 0; else noPhoneAndFax">
                  <p>
                    {{ office.address }},
                    <span> {{ office.city }}<span *ngIf="office.city">, </span>{{ office.state }} {{ office.zip }} </span>
                  </p>
                </div>
                <ng-template #noPhoneAndFax>
                  <p>{{ office.address }}</p>
                  <p>{{ office.city }}<span *ngIf="office.city">, </span>{{ office.state }} {{ office.zip }}</p>
                </ng-template>
              </div>
              <div class="contact-number phone invoca-phone" *ngIf="office.phones?.length > 0">
                <p>{{ office.phones }}</p>
              </div>
              <div class="contact-number fax" *ngIf="office.faxes?.length > 0">
                <p>Fax: {{ office.faxes }}</p>
              </div>
            </div>
          </div>
        </ng-template>
      </td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <td>
        <div class="page-footer-space"></div>
      </td>
    </tr>
  </tfoot>
</table>
