import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { setDialogPosition } from '../../utils/utils';
import { FilterService } from 'src/app/services/filter.service';

@Component({
  selector: 'cs-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss']
})
export class FilterDialogComponent implements OnChanges, OnInit {
  @Input() filterTitle: string;
  @Input() hideDialogButtons;
  @Input() resetActive = false;
  @Input() showCloseIcon = false;
  @Input() enableFiltering = true;
  @Input() hideDialog = true;
  @Output() applyFilters = new EventEmitter<any>();
  @Output() contentReset = new EventEmitter<any>();
  @Output() selectionReset = new EventEmitter<any>();
  @Output() buttonHover = new EventEmitter<boolean>();
  @Output() showDialogClick = new EventEmitter<any>();
  showDialog = false;
  isFilterProcessing = false;

  @ViewChild('filterContainer') filterContainer: ElementRef;

  @HostListener('window:click', ['$event'])
  onClick(event: MouseEvent) {
    if (!this.filterContainer.nativeElement.contains(event.target)) {
      this.showDialog = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    setDialogPosition();
  }

  constructor(private filterService: FilterService) { }

  ngOnInit(): void {
    this.filterService.isProcessing$.subscribe(isProcessing => {
      this.isFilterProcessing = isProcessing;
    });
  }

  showDialogClicked() {
    this.showDialog = !this.showDialog;
    this.showDialogClick.emit();
  }

  clearFilterContent() {   
    if(this.isFilterProcessing) {return;}
    this.filterService.setProcessingState(true);
    this.resetActive = false;
    this.contentReset.emit();
    this.buttonHover.emit(false);
    this.showDialog = !this.showDialog;
    setTimeout(()=> {
      this.filterService.setProcessingState(false);
    },3000);
  }

  clearFilterSelection() {
    this.resetActive = false;
    this.selectionReset.emit();
  }

  filterChange() {
    this.applyFilters.emit();
    this.showDialog = false;
  }

  emitHover(hover: boolean) {
    this.buttonHover.emit(hover);
  }

  onFocusLost(event: FocusEvent | any) {
    this.showDialog = false;
  }

  ngOnChanges(): void {
   this.filterService.displayDialog$.subscribe(item  => {
      if (item === false) {
        this.showDialog = false;
      }
    });
  }
}
