import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { FadLinkService } from 'src/app/services/fad-link.service';
import { OfficeAddress } from '../../models/office-address';
import { Provider } from '../../models/provider';

@Component({
  selector: 'cs-locations-list',
  templateUrl: './locations-list.component.html',
  styleUrls: ['./locations-list.component.scss']
})
export class LocationsListComponent {
  @Input() provider: Provider;

  @Input() officeAddresses: OfficeAddress[] = [];
  @Output() selectedOfficeChanged = new EventEmitter<OfficeAddress>();

  @Input() hideExtraContent = true;
  @Input() showMoreLessButton = false;
  @Input() centerPoint: OfficeAddress;
  @Input() isDesktopViewport = false;
  @Input() hasMoreThanOneOffice = false;
  @Input() divisionCodes: string[];
  @Input() isUpdatedProviderDetails = false;
  @Input() routId: '';
  officeTemplate = 'OfficeDetails';
  changeCount: number;
  moreLessExpanded = false;

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.isDesktopViewport = event.target.innerWidth > 991;
  }

  constructor(public route: Router, private fadLinkServer: FadLinkService, private configurationService: ConfigurationService) {
    if(this.isUpdatedProviderDetails) {
      this.officeTemplate = 'newofficeDetails';
    }
  
  }
  
  setSelectedOffice(office: OfficeAddress): void {
    this.changeCount++;
    this.centerPoint = office;
    this.selectedOfficeChanged.emit(office);
  }

  handleMoreLess(): void {
    this.hideExtraContent = !this.hideExtraContent;
    this.moreLessExpanded = !this.moreLessExpanded;
  }

  loadAllProvidersForLocation(locationId: string): void {
    const baseUrl = this.configurationService.getRuntimeConfiguration().fadBaseUrl;

    const marketCode = this.configurationService.getMarketCodes()[0];
    let routeValue = '';
    let embedSource = '';
    try {
      embedSource = this.configurationService.getEmbedSource();
      if (!embedSource) {
        const falElement = document.querySelector('fal-saas');
        if (falElement) {
          embedSource = falElement.getAttribute('embedsource');
        }
      }
    }
    catch (err) {
      console.log(err);
    }
    if (embedSource === 'cerner') {
      routeValue = `${baseUrl}${this.configurationService.getRedirectToFADinCerner()}/l/location-${locationId}`;
    }
    else if (embedSource === 'mychart') {
        routeValue = `${this.configurationService.getRedirectURLPathForFADEpic()}/l/location-${locationId}`;
    }
    else if(document.body.firstElementChild?.firstElementChild?.tagName === 'FAL-SAAS' || 
      document.body.firstElementChild?.tagName === 'CS-FAD' || 
      document.body.firstElementChild?.tagName === 'FAD-SAAS') {
        routeValue = `${baseUrl}${marketCode}/l/location-${locationId}`;
    }
    else {
          routeValue = `${this.configurationService.getRedirectInAEM()}/l/location-${locationId}`;
    }
    window.sessionStorage.setItem('LocationIdFromFAL', locationId);
    window.open(routeValue, '_self');
  }


  get viewAllLinkNeeded(): boolean {
    const markets = this.configurationService.getMarketCodes();
    return markets.includes('vmfh');
  }

  
}
