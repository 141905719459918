import { CommonModule } from '@angular/common';
import { HttpClientJsonpModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from './tooltip/tooltip.module';
import { GuidedFlowModule } from '@dignity-health/ngx-guided-flow';
import { DignityHealthComponentsModule } from '../dignity-health-components.module';
import { BadgesModule } from './badges/badges.module';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { EmergencyCareComponent } from './emergency-care/emergency-care.component';
import { ExpandableListComponent } from './expandable-list/expandable-list.component';
import { LegalCopyComponent } from './legal-copy/legal-copy.component';
import { LocationSearchComponent } from './location-search/location-search.component';
import { ProviderCardComponent } from './provider-card/provider-card.component';
import { ProviderDetailsNavigationComponent } from './provider-details-navigation/provider-details-navigation.component';
import { ProviderRoleCheckboxComponent } from './provider-role-checkbox/provider-role-checkbox.component';
import { FocusedDirective } from './provider-search/focused.directive';
import { ProviderSearchComponent } from './provider-search/provider-search.component';
import { QuicklinksSearchComponent } from './quicklinks-search/quicklinks-search.component';
import { RegionalHelplineNumbersComponent } from './regional-helpline-numbers/regional-helpline-numbers.component';
import { ResultCardModule } from './result-card/result-card.module';
import { ScrollToTopComponent } from './scroll-to-top/scroll-to-top.component';
import { SearchInputComponent } from './search-input/search-input.component';
import { SvgIconComponent } from './svg-icon/svg-icon.component';

@NgModule({
  declarations: [
    LegalCopyComponent,
    EmergencyCareComponent,
    ProviderSearchComponent,
    LocationSearchComponent,
    QuicklinksSearchComponent,
    SvgIconComponent,
    ProviderDetailsNavigationComponent,
    ExpandableListComponent,
    DisclaimerComponent,
    ProviderRoleCheckboxComponent,
    SearchInputComponent,
    RegionalHelplineNumbersComponent,
    FocusedDirective,
    ScrollToTopComponent,
    ProviderCardComponent
  ],
  imports: [
    CommonModule,
    TooltipModule,
    FormsModule,
    ResultCardModule,
    DignityHealthComponentsModule,
    HttpClientJsonpModule,
    BadgesModule
  ],
  exports: [
    LegalCopyComponent,
    EmergencyCareComponent,
    ProviderSearchComponent,
    LocationSearchComponent,
    QuicklinksSearchComponent,
    SvgIconComponent,
    ProviderDetailsNavigationComponent,
    ExpandableListComponent,
    DisclaimerComponent,
    ProviderRoleCheckboxComponent,
    SearchInputComponent,
    RegionalHelplineNumbersComponent,
    TooltipModule,
    BadgesModule,
    FocusedDirective,
    DignityHealthComponentsModule,
    ResultCardModule,
    HttpClientJsonpModule,
    ScrollToTopComponent,
    ProviderCardComponent,
    GuidedFlowModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {}
