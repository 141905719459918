import { Inject, Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { SelectedLocation } from '../models/location';
import { Provider, ProviderInterfaceHelper } from '../models/provider';
import { ConfigurationService } from './configuration.service';
import { DOCUMENT } from '@angular/common';
import { ActivatedRouteSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TitleTagHandlerService {
  marketCode = '';
  isTagEnabled = false;
  canonicals = true;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/explicit-module-boundary-types
  constructor(private titleService: Title, private metaService: Meta, private configService: ConfigurationService, @Inject(DOCUMENT) private dom) {
    const titleTag = this.configService.showTitleTag();
    this.canonicals = this.configService.injectCanonicals();
    if (Object.keys(titleTag).length > 0) {
      this.isTagEnabled = titleTag.enable;
      this.marketCode = titleTag.marketCode;
    }
  }

  setPageTitle(searchTerm: string, selectedLocation: string, routeSnapshot: ActivatedRouteSnapshot) {
    searchTerm = searchTerm?.replace(/-+/g, ' ').toTitleCase();
    if (selectedLocation) {
      selectedLocation = this.formatLocation(selectedLocation);
    }

    if (this.isTagEnabled) {
      let title = '';

      if (searchTerm && selectedLocation) {
        title = `${searchTerm} in ${selectedLocation} `;
      } else if (searchTerm && !selectedLocation) {
        title = `${searchTerm} `;
      } else if (!searchTerm && selectedLocation) {
        title = `Doctors in ${selectedLocation} `;
      } else {
        title = 'Find a doctor ';
      }
     
      title = this.setSearchToggleFilers(title, routeSnapshot);
      title = this.setSearchFilterTitle(title, routeSnapshot);

      title = title.replace(/,(?=\s*$)/, '');
      title += `| ${this.marketCode}`;

      this.titleService.setTitle(title);
      this.setPageMeta(title);
    }
  }


  setSearchToggleFilers(title: string, routeSnapshot: ActivatedRouteSnapshot):string {
    if (routeSnapshot?.queryParamMap) {
      if(routeSnapshot.queryParamMap.get('bookonline')){
        title += 'Online Scheduling, ';
      }
      if(routeSnapshot.queryParamMap.get('videovisits')){
        title += 'Video Visits, ';
      }
      if(routeSnapshot.queryParamMap.get('acceptingnew')){
        title += 'Accepting New Patients, ';
      }
      if(routeSnapshot.queryParamMap.get('allVisits')){
        title += 'Online Scheduling - All Visits, ';
      }
    }
    return title;
  }

  setSearchFilterTitle(title: string, routeSnapshot: ActivatedRouteSnapshot): string{
    if (routeSnapshot?.queryParamMap) {
      if(routeSnapshot.paramMap.get('specialty')){
        title += `${this.formatFilters(routeSnapshot.paramMap.get('specialty'))}, `;
      }
      if(routeSnapshot.queryParamMap.get('specialties')){
        title += `${this.formatFilters(decodeURIComponent(routeSnapshot.queryParamMap.get('specialties')).split('|').join(', '))}, `;
      }
      if(routeSnapshot.paramMap.get('medGroup')) {
        title += `${this.formatFilters(decodeURIComponent(routeSnapshot.paramMap.get('medGroup')).split('|').join(', '))}, `;
      }
      if(routeSnapshot.queryParamMap.get('gender')){
        title += `${this.formatFilters(routeSnapshot.queryParamMap.get('gender'))}, `;
      }
      if(routeSnapshot.queryParamMap.get('languages')){
        title += `${this.formatFilters(decodeURIComponent(routeSnapshot.queryParamMap.get('languages')).split('|').join(', '))}, `;
      }
    }
    return title;
  }

  formatFilters(param: string): string{
    if (param.includes('-')) {
      const splitParam = param.split('-');
      const titleCaseParam = splitParam.map(x => x.toTitleCase());
      return titleCaseParam.join(' ');
    }
    return param.toTitleCase();
  }


  setPageTitleforNoRecords(location: SelectedLocation): void {
    let title = '';
    if (location) {
      title = `Find a doctor in ${location?.city}, ${location?.state} | ${this.configService.getMarketCodes().toString().toUpperCase()}`;
    } else {
      title = `Find a doctor | ${this.configService.getMarketCodes().toString().toUpperCase()}`;
    }
    this.titleService.setTitle(title);
  }

  setProviderPageTitle(provider: Provider): void {
    if (this.isTagEnabled) {
      let cityStateZip = '';
      if (provider.offices[0]?.addresses.length > 0) {
        cityStateZip = `${provider.offices[0].addresses[0].city}, ${provider.offices[0].addresses[0].state?.toUpperCase()} ${
          provider.offices[0].addresses[0].zip
        }`;
      }

      this.updateProviderFullName(provider);
      const primarySpec = provider.primarySpecialty ? `${' | '+provider.primarySpecialty?.toTitleCase() +' | '}`: ' | ';
      const location = cityStateZip ?
        `${provider.displayFullName}${primarySpec}${cityStateZip} | ${this.marketCode}`:
        `${provider.displayFullName}${primarySpec}${this.marketCode}`;
      this.titleService.setTitle(location);
      this.setProviderDetailsMeta(provider, location);
    }

  }

  updateProviderFullName(provider: Provider): void {
    if(!this.configService.showProviderPrefix())
    {
      provider.displayFullName =  ProviderInterfaceHelper.removeProviderNamePrefix(provider.firstName, provider.lastName, provider.degree);
    }
  }

  setPageMeta(title: string): void {
    if (this.configService.injectCanonicals()) {
      this.setMetaProperty('og:title', title );
      this.setMetaProperty('og:site_name', this.marketCode );
      this.setMetaProperty('twitter:site', this.marketCode);
    }
  }

  setProviderDetailsMeta(provider: Provider, providerTitle: string): void {
    if (this.configService.injectCanonicals()) {
      const discription = provider.personalInfo?.split('</p>')[0]?.replace(/<[^>]*>/g, '');
      this.setMetaName('description', discription);
      this.setMetaProperty('og:description', discription );
      this.setMetaProperty('og:title', providerTitle );
      this.setMetaProperty('twitter:card', 'summary_large_image');
      this.setMetaProperty('og:site_name', this.marketCode );
      this.setMetaProperty('twitter:site', this.marketCode );
      this.setMetaProperty('og:image', provider.images[0]?.url);
    }
  }

  setNoFollowMetaPage(): void {
    if (this.configService.injectCanonicals()) {
    this.setMetaName('robots', 'noindex,nofollow');
    }
  }

  removeMetaNoIndexName(): void {
    if (this.configService.injectCanonicals()) {
    this.metaService.removeTag('name=\'robots\'');
    }
  }

  setMetaName(metaName: string, metaContent: string): void {
    const metaDescription = this.dom.querySelector(`meta[name='${metaName}']`) || null;
    if (metaDescription === null) {
      this.metaService.addTag({ name: metaName, content: metaContent });
    } else {
      this.metaService.updateTag({ name: metaName, content: metaContent });
    }
  }

  setMetaProperty(metaProperty: string, metaContent: string): void {
    const metaDescription = this.dom.querySelector(`meta[property='${metaProperty}']`) || null;
    if (metaDescription === null) {
      this.metaService.addTag({ property: metaProperty, content: metaContent });
    } else {
      this.metaService.updateTag({ property: metaProperty, content: metaContent });
    }
  }

  setPageCanonicals(url: string): void {
    if(this.configService.injectCanonicals()) {
      const head = this.dom.getElementsByTagName('head')[0];
      let element = this.dom.querySelector('link[rel=\'canonical\']') || null;
      if (element === null) {
        element = this.dom.createElement('link');
        head.appendChild(element);
      }
      element.setAttribute('rel','canonical');
      element.setAttribute('href',url);
      this.setMetaProperty('og:url', url);
    }
  }

  removePageCanonicals(strUrl: string): void {
    try {
      if(this.configService.injectCanonicals()) {
        const element = this.dom.querySelectorAll('link[rel=\'canonical\']') || null;
        if (element != null) {
          element.forEach(x => {
            if (x.href === strUrl) {
              x.remove();
            }
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  formatLocation(selectedLocation: string): string {
    if (selectedLocation.includes('-')) {
      const splitLocation = selectedLocation.split('-');
      if (splitLocation.length > 1) {
        const state = `${splitLocation[splitLocation.length - 1].toUpperCase()}`;
        const city = splitLocation.slice(0, splitLocation.length - 1).join(' ');
        selectedLocation = `${city.toTitleCase()}, ${state.toUpperCase()}`;
      }
    }
    return selectedLocation;
  }

  setErrorPageTagTitle(titleStr: string): void {
    const titleString = `${titleStr} | ${this.marketCode}`;
    this.titleService.setTitle(titleString);
    const metaRobots = this.dom.querySelector('meta[name=\'robots\']') || null;
    if(metaRobots === null) {
      this.metaService.addTag({ name: 'robots', content: 'noindex, nofollow' });
    }
    else {
      this.metaService.updateTag({ name: 'robots', content: 'noindex, nofollow' });
    }
    
    const metaGoogleBot = this.dom.querySelector('meta[name=\'googlebot\']') || null;
    if(metaGoogleBot === null) {
      this.metaService.addTag({ name: 'googlebot', content: 'noindex, nofollow' });
    }
    else {
      this.metaService.updateTag({ name: 'googlebot', content: 'noindex, nofollow' });
    }
  }
}
