import { FadUrlBuilderStrategy } from './fad-url-builder-strategy';
import { UrlBuilderParams } from './url-builder-params';

export class LocationAndTermUrlBuilderStrategy extends FadUrlBuilderStrategy {
  createUrlSegments(urlBuilderParams: UrlBuilderParams): void {
    this.addSegments('', 'r');
    this.addCityAndStateSegment(urlBuilderParams.selectedLocation);
    this.addSegments(urlBuilderParams.searchTerm.stripDash('_'));
  }

  shouldBuild(urlBuilderParams: UrlBuilderParams): boolean {
    return !urlBuilderParams.medGroup && !!urlBuilderParams.searchTerm && this.hasLocationData(urlBuilderParams);
  }
}
