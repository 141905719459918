import { HttpParams } from '@angular/common/http';

declare module '@angular/common/http' {
  interface HttpParams {
    setSanitizedKey(key: string, value: string | number): HttpParams;
    setNonString(key: string, value: number | boolean): HttpParams;
    setKeyValue(keyValue: string): HttpParams;
    setPipeDelimitedArray(key: string, values: string[] | number[]): HttpParams;
    setNonNull(key: string, value: string | number | boolean): HttpParams;
    getNumber(key: string): number;
    getBoolean(key: string): boolean;
  }
}

HttpParams.prototype.setSanitizedKey = function(this: HttpParams, key: string, value: string | number): HttpParams {
  key = key.replace(/=$/, '').replace(/^&/, '');
  return this.set(key, value.toString());
};

HttpParams.prototype.setNonString = function(this: HttpParams, key: string, value: number | boolean): HttpParams {
  return this.set(key, value.toString());
};

HttpParams.prototype.setKeyValue = function(this: HttpParams, keyValue: string): HttpParams {
  const array = keyValue.split('=');
  return this.setSanitizedKey(array[0], array[1]);
};

HttpParams.prototype.setPipeDelimitedArray = function(this: HttpParams, key: string, values: string[] | number[]): HttpParams {
  if (!values || values.length === 0) {
    return this;
  }
  return this.set(key, values.join('|'));
};

HttpParams.prototype.setNonNull = function(this: HttpParams, key: string, value: string | number | boolean): HttpParams {
  if (value === null || value === undefined) {
    return this;
  }
  return this.set(key, value.toString());
};

export {};
