import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MyProvidersComponent } from 'src/app/components/my-providers/my-providers.component';
import { SharedModule } from 'src/app/components/shared.module';
import { SearchLandingComponent } from './search-landing.component';

@NgModule({
  declarations: [SearchLandingComponent, MyProvidersComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild([
      {
        path: '',
        component: SearchLandingComponent
      }
    ])
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SearchLandingModule {}
