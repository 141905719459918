import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConstants } from 'src/app/constants/api-constants.new';
import { KeywordSuggestionResponse, Provider, ProviderSuggestionResponse, ProviderSuggestionV2Response } from 'src/app/models/provider';
import { ApiResponse } from 'src/app/utils/api-response';
import { ConfigurationService } from '..//configuration.service';
import { ProviderSearchResponse } from '../filter.service';
import { ProviderLegacyService } from './provider.legacy.service';

@Injectable({
  providedIn: 'root'
})
export class ProviderNewService extends ProviderLegacyService {
  constructor(public http: HttpClient, public configurationService: ConfigurationService) {
    super(http, configurationService);
    this.apiBaseUrl = this.runtimeConfig.newApiBaseUrl;
    this.apiConstants = ApiConstants;
  }
  getProviderDetailsFull(npi: number | string, page = 1, pageSize = 20): Observable<ApiResponse<ProviderSearchResponse>> {
    npi = npi.toString().slice(0, 10);

    const httpParams = this.addRequiredProviderHttpParams();
    const PROVIDER_DETAILS_BASE_API = `${this.apiBaseUrl}${this.apiConstants.providerDetailsApi}${npi}`;

    return this.http.get<ApiResponse<ProviderSearchResponse>>(PROVIDER_DETAILS_BASE_API, {
      headers: this.apiOptions.headers,
      params: httpParams
    });
  }
  normalizeProviderBeforeScheduling(provider: Provider): Provider {
    if (provider && provider.onlineBookingAvailability) {
      let normalizedBookingAvailability = [];
      const validCount = provider.onlineBookingAvailability.filter((s) => s.supportsOnlineBooking).length;
      if (validCount > 0) {
        [...new Set(provider.onlineBookingAvailability.map((item) => item.officeAddressId))].forEach((bookingAvailability) => {
          const normalizedList = [];
          const officeAddressIdList = provider.onlineBookingAvailability.filter((a) => a.officeAddressId && a.officeAddressId === bookingAvailability);
          for (const officeAddressId of officeAddressIdList) {
            if (normalizedList.length === 0) {
              normalizedList.push(officeAddressId);
            } else {
              normalizedList[0].appointmentTypes = [...normalizedList[0].appointmentTypes, ...officeAddressId.appointmentTypes];
              normalizedList[0].availableTimes = [...normalizedList[0].availableTimes, ...officeAddressId.availableTimes];
            }
          }
          normalizedBookingAvailability = [...normalizedBookingAvailability, ...normalizedList];
        });

        normalizedBookingAvailability.forEach((a) => {
          let dateList = [];

          if (a.availableTimes) {
            [...new Set(a.availableTimes.map((item) => item.date))].forEach((availableDates) => {
              const normalizedList = [];
              const datesList = a.availableTimes.filter((dl) => dl.date === availableDates);
              for (const dateValue of datesList) {
                if (normalizedList.length === 0) {
                  normalizedList.push(dateValue);
                } else {
                  normalizedList[0].times = [...normalizedList[0].times, ...dateValue.times];
                }
              }
              dateList = [...dateList, ...normalizedList];
            });
            dateList.forEach((date) => {
              const times = [];
              [...new Set(date.times.map((dateAvailability) => dateAvailability.time))].forEach((time) => {
                date.times
                  .filter((appointmentTime) => appointmentTime.time === time)
                  .forEach((t) => {
                    times.push(t);
                  });
              });
              date.times = [...times];
            });
            a.availableTimes = [...dateList];
          }
        });
      }

      provider.onlineBookingAvailability = normalizedBookingAvailability;

      for (const onlineBookingItem of provider.onlineBookingAvailability) {
        if (!onlineBookingItem.nextAvailableTimes && onlineBookingItem.appointmentTypes) {
          onlineBookingItem.appointmentTypes.forEach((appItem) => {
            appItem.id = appItem.name;
            appItem.remoteId = appItem.name;
          });
          onlineBookingItem.nextAvailableTimes = [];
          onlineBookingItem.officeAddressId = onlineBookingItem.officeAddressId.toString();
          const appointmentTypeIds = onlineBookingItem.appointmentTypes?.map((a) => a.id) || [];
          onlineBookingItem.availableTimes?.forEach((currentDateAvailability: any) => {
            currentDateAvailability.times?.forEach((currentTimeAvailability: any) => {
              currentTimeAvailability.appointmentTypes?.forEach((currentAppointmentAvailability: any) => {
                if (appointmentTypeIds.length > 0 && appointmentTypeIds.indexOf(currentAppointmentAvailability.id) >= 0) {
                  appointmentTypeIds.splice(appointmentTypeIds.indexOf(currentAppointmentAvailability.id), 1);
                  onlineBookingItem.nextAvailableTimes.push({
                    appointmentType: currentAppointmentAvailability.id,
                    time: currentTimeAvailability.time
                  });
                }
              });
            });
          });
        }
      }
      provider.onlineBookingAvailability.forEach((obj) => {
        if (obj.appointmentTypes) {
          obj.appointmentTypes = [...new Map(obj.appointmentTypes.map((item) => [item['visitId'], item])).values()];
        }
      });
    }
    if(provider.onlineBookingAvailability.length===0){
      provider.isBookOnline=false;
    }
    console.log(provider);
    return provider;
  }

  getTypedProviderSuggestions(query: string, suggestionType: string): Observable<ApiResponse<ProviderSuggestionResponse>> {
    let httpParams = this.addRequiredProviderHttpParams();
    const url = `${this.apiBaseUrl}${this.apiConstants.suggestionsBaseApiUrl}providers`;
    httpParams = httpParams.append('mode', 'Auto');
    if (suggestionType === 'specialty') {
      httpParams = httpParams.append('fields', 'specialties');
      httpParams = httpParams.append('searchQuery', query); 
    }
    if (suggestionType === 'provider'){
      httpParams = httpParams.append('fields', 'primarySpecialty,procedures,conditions,insuranceCarriers,fullName');
      httpParams = httpParams.append('searchQuery', query);
    }
    return this.http.get<ApiResponse<ProviderSuggestionResponse>>(url.toString(), {
      headers: this.apiOptions.headers,
      params: httpParams
    });
  }

  getTypedProviderSuggestionsV2(query: string): Observable<ApiResponse<ProviderSuggestionV2Response>> {
    let httpParams = this.addRequiredProviderHttpParams();
    httpParams = httpParams.append('mode', 'Auto');
    httpParams = httpParams.append('fields', 'specialties');
    httpParams = httpParams.append('searchQuery', query);
    const url = `${this.apiBaseUrl}${this.apiConstants.suggestionsBaseApiUrl}providers`;
    return this.http.get<ApiResponse<ProviderSuggestionV2Response>>(url.toString(), {
      headers: this.apiOptions.headers,
      params: httpParams
    });
  }

  getTypedKeywordSuggestions(query: string): Observable<ApiResponse<KeywordSuggestionResponse>> {
    let httpParams = this.addRequiredProviderHttpParams();
    httpParams = httpParams.append('mode', 'Auto');
    httpParams = httpParams.append('fields', 'procedures,conditions,insuranceCarriers');
    httpParams = httpParams.append('query', query);
    const url = `${this.apiBaseUrl}${this.apiConstants.suggestionsBaseApiUrl}autocomplete`;
    return this.http.get<ApiResponse<KeywordSuggestionResponse>>(url.toString(), {
      headers: this.apiOptions.headers,
      params: httpParams
    });
  }
}
