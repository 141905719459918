import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'identity-manager-client-library';
import { ConfigurationService } from './configuration.service';

@Injectable({
    providedIn: 'root'
})
export class BlockitSessionService {
    constructor(
        private http: HttpClient,
        private oidcSecurityService: OidcSecurityService,
        private configurationService: ConfigurationService
    ) { }

    getAuthToken(): string {
        return this.oidcSecurityService.getToken();
    }

    getBlockItSessionData() {
        const headers = {
            Authorization: `Bearer ${this.getAuthToken()}`,
            'Content-Type': 'application/json',
            'Ocp-Apim-Subscription-Key': `${this.configurationService.getRuntimeConfiguration().ocpApimSubscriptionKey}`
        };

        const body = {
            BypassCiam: false,
            userProfile: null
        };
        return this.http
            .post<any>(
                this.configurationService.getRuntimeConfiguration().appointmentDomainAPI,
                body,
                { headers }
            ).subscribe(res => {
                sessionStorage.setItem('blockItUrl', res.result.returnUrl);
            });
    }

    getBlockItData() {
        const headers = {
            Authorization:
                `Bearer ${this.configurationService.getRuntimeConfiguration().blockItToken}`,
            'Content-Type': 'application/json'
        };

        const body = {
            booking_form_type: 'input',
            page: 'profile',
            patient: {
                given_name_1: '',
                given_name_2: '',
                family_name: '',
                sex: '',
                dob: '',
                phone: '',
                email: '',
                address_1: '',
                address_2: '',
                city: '',
                state: '',
                postal_code: ''
            }
        };

        this.http
            .post<any>(
                this.configurationService.getRuntimeConfiguration().blockItUrl,
                body,
                { headers }
            )
            .subscribe((data) => {
                sessionStorage.setItem('blockItUrl', data.url);
            });
    }

    //get the blockit url
    getBlockItURL() {
        const divisionCodes = this.configurationService?.getDivisionCodes().length > 0 ? this.configurationService.getDivisionCodes()[0] : null;
        if (divisionCodes?.toLowerCase() == 'dignity-health') {
            this.getAuthToken() ? this.getBlockItSessionData() : this.getBlockItData();
        }
    }
}
