import { animate, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';
import { LocationByIpResult, LocationConverter, SelectedLocation } from '../../../models/location';
import { ApiResponse } from '../../../utils/api-response';
import { v4 as uuidv4 } from 'uuid';
import { LocationService } from '../../../services/location.service';
import { SessionObjectNames } from 'src/app/models/stringconst-names';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Component({
  selector: 'cs-fad-lite-location-search',
  templateUrl: './fad-lite-location-search.component.html',
  styleUrls: ['./fad-lite-location-search.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [style({ opacity: 0 }), animate(500, style({ opacity: 1 }))]),
      transition(':leave', [animate(500, style({ opacity: 0 }))])
    ])
  ]
})
export class FadLiteLocationSearchComponent implements OnInit, OnChanges, OnDestroy {
  @Input() selectedLocation: SelectedLocation;
  @Input() invalidLocation: boolean;
  @Input() autoSuggestionLoadingMessage: string;
  @Output() setLocation = new EventEmitter<SelectedLocation>();
  @Output() locationEnter = new EventEmitter<SelectedLocation>();
  @Output() closeProviderAutoSuggestionContainer = new EventEmitter<any>();

  autocompleteActive: boolean;
  locations: SelectedLocation[] = [];
  locationName = '';
  highlightedlocation = -1;
  results$: Subscription;
  subject = new ReplaySubject<string>(1);
  showLoadingMessage = false;
  inputInFocus = false;
  changeBtnStyle:string;
  errorMessage:string;
  private readonly inputDebounceMS = 300;
  private inputModelChanged: Subject<string> = new Subject<string>();

  @ViewChild('locationSuggestionsContainer') locationSuggestionsContainer: ElementRef;
  @ViewChild('locationInput') locationInput: ElementRef;

  @HostListener('window:click', ['$event'])
  onClick(event: MouseEvent) {
    if (this.locationInput && this.locationInput.nativeElement.contains(event.target)) {
      if (this.locationName.length > 0 && !this.selectedLocation) {
        this.setAutocompleteActive(true);
      }

      if (window.innerWidth < 768) {
        this.locationInput.nativeElement.style.boxShadow = '0px 2px 8px rgba(77, 82, 90, 0.3)';
      }
    } else {
      this.setAutocompleteActive(false);
      this.locationInput.nativeElement.style.boxShadow = 'none';
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (event.target.innerWidth > 768) {
      this.locationInput.nativeElement.style.boxShadow = 'none';
    }
  }

  constructor(private locationService: LocationService, private configurationService: ConfigurationService) {}

  ngOnInit(): void {
    window.sessionStorage.removeItem(SessionObjectNames.LocationSessionId);

    this.results$ = this.subject
      .pipe(
        filter((searchText) => !!searchText && searchText.length > 0),
        switchMap((locationSearchTerm) => this.getLocationSuggestionsTyped(locationSearchTerm)),
        tap((locations) => {
          this.showLoadingMessage = false;
          if (!this.inputInFocus) {
            this.setAutocompleteActive(false);
            return;
          }
          this.locations = locations;
          this.setAutocompleteActive(this.locations.length > 0);
        })
      )
      .subscribe();

    this.inputModelChanged.pipe(debounceTime(this.inputDebounceMS)).subscribe((event) => this.onChangeSearch(event));
    this.changeBtnStyle= this.configurationService.getSearchButton();
  }

  ngOnDestroy(): void {
    this.results$.unsubscribe();
    this.subject.unsubscribe();
  }

  ngOnChanges() {
    this.locationName = this.selectedLocation?.name ?? '';
  }

  async findGeoLocation() {
    if (navigator.geolocation) {
      await new Promise<void>((res, rej) => {
        const timeout = setTimeout(() => rej(), 8000);

        navigator.geolocation.getCurrentPosition(
          () => {
            clearTimeout(timeout);
            res();
          },
          () => {
            clearTimeout(timeout);
            rej();
          },
          {
            enableHighAccuracy: true,
            maximumAge: 5000,
            timeout: 5000
          }
        );
      })
        .then(() => {
          this.getGeoLocation();
        })
        .catch(() => {
          this.getGeoLocation();
        });
    } else {
      this.getGeoLocation();
    }
  }

  getLocationSuggestionsTyped(locationSearchTerm: string): Observable<SelectedLocation[]> {
    return this.locationService.getTypedLocationSuggestions(locationSearchTerm).pipe(
      map((response) => {
        let locations: SelectedLocation[] = [];
        if (response.isValid) {
          locations = response.result.map(
            (l) =>
              ({
                name: l.locationName,
                googlePlaceId: l.googlePlaceId,
                address: '',
                zip: '',
                city: '',
                state: '',
                neighborhood: ''
              } as SelectedLocation)
          );
        }
        return locations;
      }),
      map((selectedLocations) => selectedLocations.filter(
          (l, index, self) => index === self.findIndex((item) => item.name === l.name && item.googlePlaceId === l.googlePlaceId)
        ))
    );
  }

  onInputChange(event) {
    this.showLoadingMessage = true;
    this.setAutocompleteActive(false);
    this.inputModelChanged.next(event);
  }

  onChangeSearch(val: string) {
    this.locations = [];
    this.setLocation.emit({
      name: val
    } as any);
    val = val.replace(/^[^a-zA-Z0-9,]+$/, '');
    if (val && val.match(/^ *$/) === null) {
      if (window.sessionStorage.getItem(SessionObjectNames.LocationSessionId) === null) {
        const token = uuidv4().replace(/-/g, '');
        window.sessionStorage.setItem(SessionObjectNames.LocationSessionId, token);
      }
      this.subject.next(val);
    } else {
      this.setAutocompleteActive(false);
      this.clearSession();
    }
  }

  setSelectedLocation(location: SelectedLocation) {
    const zipPattern = /\d+/g;
    const isZipExist = location.name.match(zipPattern);
    this.locationService
      .getLocationDetail(location.googlePlaceId)
      .pipe(
        map((response) => ({
            name: location.name,
            neighborhood: response.result.neighborhood,
            city: response.result.city,
            state: response.result.state,
            latitude: response.result.latitude,
            longitude: response.result.longitude,
            zip: isZipExist ? response.result.zip : '',
            isLocationValid: true
          } as SelectedLocation))
      )
      .subscribe((locationSel: SelectedLocation) => {
        this.setAutocompleteActive(false);
        this.setLocation.emit(locationSel);
      });
  }

  clearSession() {
    this.setAutocompleteActive(false);
    window.sessionStorage.removeItem(SessionObjectNames.LocationSessionId);
  }

  setAutocompleteActive(value: boolean) {
    this.autocompleteActive = value;
    this.highlightedlocation = -1;
  }

  private getGeoLocation() {
    this.locationService
      .getLocationByIP()
      .pipe(map((result: ApiResponse<LocationByIpResult>) => LocationConverter.convertTo(result.result.location)))
      .subscribe((location: SelectedLocation) => {
        this.showLoadingMessage = false;
        this.setLocation.emit(location);});
  }

  handleLocationEnter() {
    if (this.autocompleteActive && this.highlightedlocation >= 0 && this.highlightedlocation <= this.locations.length) {
      this.setSelectedLocation(this.locations[this.highlightedlocation]);
    } else if (!this.autocompleteActive) {
      this.locationEnter.emit();
    }
  }

  setIndex(index: number) {
    if (index <= -1 || index >= this.locations.length) {
      return;
    }

    this.highlightedlocation = index;
  }

  handleKeyPress($event: KeyboardEvent) {
    const keyCode = $event.key.toLowerCase();

    if (keyCode === 'arrowdown') {
      $event.preventDefault();
      this.setIndex(this.highlightedlocation + 1);
    } else if (keyCode === 'arrowup') {
      $event.preventDefault();
      this.setIndex(this.highlightedlocation - 1);
    }
  }

  closeProviderAutoSuggestion(): void {
    this.inputInFocus = true;
    this.closeProviderAutoSuggestionContainer.emit();
  }

  onFocusLost(event: FocusEvent | any): void {
    if (
      (event.target && !this.locationSuggestionsContainer.nativeElement.contains(event.target)) ||
      (event.relatedTarget && !this.locationSuggestionsContainer.nativeElement.contains(event.relatedTarget))
    ) {
      this.inputInFocus = false;
    }
  }

  getLocationError(): string{
    if(!this.invalidLocation && this.selectedLocation?.name !== 'undefined' && this.selectedLocation?.name !== null && this.selectedLocation?.name !== ' ')
    {
      this.errorMessage = 'We’re unable to recognize this location. Please type a new city or zip in the Location search field.';
    }
    else{
      this.errorMessage = '';
    }
    return this.errorMessage;
  }
}
