import { Component, Input } from '@angular/core';
import { DynamicFilterContentDetails } from 'src/app/models/DynamicFilterContentDetails';
import { ResizeService } from 'src/app/services/resize.service';

@Component({
  selector: 'cs-dynamic-filter-content',
  templateUrl: './dynamic-filter-content.component.html',
  styleUrls: ['./dynamic-filter-content.component.scss']
})
export class DynamicFilterContentComponent {
  @Input() medicalGroup = 'default medical group';
  @Input() dynamicFilterContents: DynamicFilterContentDetails[];

  constructor(private resizeService: ResizeService) {}

  get isDesktopViewport() {
    return this.resizeService.isDesktopViewport;
  }
}
