import { Component, OnInit } from '@angular/core';
import { ResizeService } from '../../services/resize.service';
import { ERLinkCategories } from '../../models/configuration';
import { ConfigurationService } from '../../services/configuration.service';
import { SessionObjectNames } from 'src/app/models/stringconst-names';

@Component({
  selector: 'cs-fad-lite-search-landing',
  templateUrl: './fad-lite-search-landing.component.html',
  styleUrls: ['./fad-lite-search-landing.component.scss']
})
export class FadLiteSearchLandingComponent implements OnInit {
  showMyProviders: boolean;
  landingPageHeader: string;
  showLegalCopy: boolean;
  legalCopy: string;
  legalCopyToolTip: string;
  legalCopyToolTipInfo: string;
  showEmergencyCare: boolean;
  showEmergencyCopy: boolean;
  showRegionalHelplineNumbers: boolean;
  helplineNumbers: any[];
  isDesktopViewport: boolean;
  displayInfo: string;
  displayHeadingInvalid: string;
  emergencyCareLinkContent: ERLinkCategories[] = [];
  myProvidersLinkContent: string;
  emergencyCopy: string;
  emergencyNumber: string;
  constructor(private resizeService: ResizeService, private configService: ConfigurationService) { }

  ngOnInit(): void {
    window.sessionStorage.removeItem(SessionObjectNames.SearchFrom);
    this.showMyProviders = this.configService.showMyProviders();
    this.landingPageHeader = this.configService.getLandingPageHeader();
    this.showEmergencyCare = this.configService.showEmergencyCare();
    this.showRegionalHelplineNumbers = this.configService.showRegionalHelplineNumbers();
    this.displayInfo = this.configService.getHelplineHeading();
    this.displayHeadingInvalid = this.configService.getHelplineHeadingInvalid();
    this.isDesktopViewport = this.resizeService.isDesktopViewport;
    if (this.configService.getHelplineNumbers().length > 0) {
      this.helplineNumbers = this.configService.getHelplineNumbers();
    } else {
      this.helplineNumbers = [
        {
          heading: 'Arizona',
          phone: '(855) 697-0781'
        },
        {
          heading: 'California',
          phone: '(866) 914-3627'
        },
        {
          heading: 'Nevada',
          phone: '(866) 984-3627'
        }
      ];
    }
    this.getHelplineNumbers();
    this.emergencyCareLinkContent = this.configService.getEmergencyCareLinkScheme();
    this.myProvidersLinkContent = this.configService.myProvidersLinkScheme();
    this.showEmergencyCare = this.configService.showEmergencyCare();
    this.showEmergencyCopy = this.configService.showEmergencyCopy();
    this.emergencyCopy = this.configService.getEmergencyCopy();
    this.emergencyNumber = this.configService.getEmergencyNumber();
    this.showLegalCopy = this.configService.showLegalCopy();
    this.legalCopy = this.configService.getLegalCopy();
    this.legalCopyToolTip = this.configService.getLegalCopyToolTip();
    this.legalCopyToolTipInfo = this.configService.getLegalCopyToolTipInfo();
    this.setRootElementCss();
  }

  getHelplineNumbers() {
    this.helplineNumbers.forEach((helpline: any) => {
      const cleaned = helpline.phone.toString().replace(/\D/g, '');
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        helpline.displayPhone = `(${match[1]}) ${match[2]}-${match[3]}`;
        helpline.phone = `+1${match[0]}`;
      } else {
        return;
      }
    });
  }
  setRootElementCss() {
    try {
      const elements = document.body.querySelectorAll<HTMLElement>('fad-saas');
      if (elements) {
        elements.forEach(element => {

          element.classList.add('fad-root-element');
        });
      }
    }
    catch (err) {
      console.log(err);
    }
  }

  get hideHeaderForVMFHAEMMobileView() {
    return !(this.resizeService.isMobileViewport && window.location.host.toLowerCase().includes('vmfh'));
  }
}
