import { ActivatedRoute } from '@angular/router';
import { ConfigurationService } from '../configuration.service';
import { ProviderService } from '../provider.service';
import { FilterLegacyService } from './filter.legacy.service';
import { FilterNewService } from './filter.new.service';

export function FilterServiceFactory(providerService: ProviderService, configService: ConfigurationService, route: ActivatedRoute) {
  if (configService.useLegacyAPI()) {
    return new FilterLegacyService(providerService, configService, route);
  }
  return new FilterNewService(providerService, configService, route);
}
