<div class="provider-search" [ngClass]="{'ps-changeStyle':changeBtnStyle}" #providerSuggestionsContainer>
  <span class="provider-search-icon">
    <svg viewBox="0 0 18 19" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.7505 16.7591L16.7556 17.754C16.4286 18.0845 15.8942 18.0845 15.5638 17.754L12.0587 14.2489C11.9005 14.0907 11.8126 13.8763 11.8126 13.6512C11.8126 13.4184 11.5418 13.2845 11.3478 13.4132C10.1914 14.1797 8.80489 14.6251 7.31254 14.6251C3.27305 14.6251 0 11.352 0 7.31254C0 3.27305 3.27305 0 7.31254 0C11.352 0 14.6251 3.27305 14.6251 7.31254C14.6251 8.80489 14.1797 10.1914 13.4132 11.3478C13.2845 11.5418 13.4184 11.8126 13.6512 11.8126C13.8763 11.8126 14.0907 11.9005 14.2489 12.0587L17.754 15.5638C18.081 15.8942 18.081 16.4286 17.7505 16.7591ZM2.81252 7.31253C2.81252 9.8016 4.82697 11.8126 7.31254 11.8126C9.80161 11.8126 11.8126 9.7981 11.8126 7.31253C11.8126 4.82346 9.79811 2.8125 7.31254 2.8125C4.82347 2.8125 2.81252 4.82696 2.81252 7.31253Z"
      />
    </svg>
  </span>
  <input
    maxlength="80"
    data-nav-type="find a doctor"
    data-nav-element="fad search text"
    [ngClass]="{ active: autocompleteActive }"
    data-nav-location="fad search initiation"
    type="text"
    [(ngModel)]="searchTerm"
    (ngModelChange)="onInputChange($event)"
    [placeholder]="this.searchPlaceholder"
    (blur)="onFocusLost($event)"
    (keyup.enter)="handleKeywordChange()"
    (keyup.escape)="setAutocompleteActive(false)"
    (keydown)="keyDownHandler($event)"
    role="combobox"
    aria-autocomplete="list"
    [attr.aria-expanded]="autocompleteActive"
    [attr.aria-busy]="inputInFocus && showLoadingMessage && searchTerm !== '' && !autocompleteActive"
    (focus)="onFocusGained()"
    tabindex="0"
    #search
  />
  <ul
    *ngIf="inputInFocus && showLoadingMessage && searchTerm !== ''"
    [attr.aria-label]="autoSuggestionLoadingMessage"
    class="provider-suggestions-container"
  >
    <li
      role="option"
      class="providers-list"
      *ngIf="(showSpecialtySuggestions && suggestions.specialties.length === 0) || suggestions.providers.length === 0"
    >
      <ul>
        <li class="loading-suggestion" [attr.aria-label]="autoSuggestionLoadingMessage">{{ autoSuggestionLoadingMessage }}</li>
      </ul>
    </li>
  </ul>
  <ul
    *ngIf="inputInFocus && autocompleteActive && searchTerm !== ''"
    aria-label="Specialties Providers Keywords"
    class="provider-suggestions-container"
  >
    <li class="specialties-list" *ngIf="showSpecialtySuggestions && suggestions.specialties.length > 0">
      <h3><p>Specialties</p></h3>
      <ul>
        <li
          *ngFor="let specialty of suggestions.specialties.slice(0, specialtySliceIndex); let k = index"
          (click)="specialtyClick(specialty)"
          [innerHTML]="queryHighlight(specialty)"
          [csFocused]="activeSpecialtyDropdownIndex === k"
          [class.focused]="activeSpecialtyDropdownIndex === k"
          [attr.aria-selected]="activeSpecialtyDropdownIndex === k"
          [ngClass]="{ selected: activeSpecialtyDropdownIndex === k }"
          (mouseenter)="handleMouseEnter(k, 'specialty')"
          [attr.aria-label]="specialty"
          tabindex="0"
        ></li>
      </ul>
      <div class="show-more-less" *ngIf="suggestions.specialties.length > 3">
        <a
          href="javascript:;"
          role="button"
          [attr.aria-label]=" specialtySliceIndex < suggestions.specialties.length  ? 'Show more specialties' : 'Show less specialties'"
          (mouseenter)="seeLessMoreHover('specialty')"
          (click)="expandLessCick('specialty')"
          #specialitySeeMore
        >
          {{ specialtySliceIndex < suggestions.specialties.length ? 'More' : 'Less' }}
          <svg
            class="more-arrow"
            [ngClass]="{ 'less-arrow': specialtySliceIndex === suggestions.specialties.length }"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 1L6 6L11 1" />
          </svg>
        </a>
      </div>
    </li>
    <hr
      *ngIf="(showSpecialtySuggestions && suggestions.specialties.length > 0) && (suggestions.providers.length > 0 || suggestions.keywords.length > 0)"
    />
    <li class="providers-list" *ngIf="suggestions.providers.length > 0">
      <h3><p>Providers</p></h3>
      <ul>
        <li
          *ngFor="let provider of suggestions.providers.slice(0, providerSliceIndex); let i = index"
          (click)="goToProvider(provider.npi, provider.firstName, provider.lastName, checkforPrimarySpeciality(provider))"
          [innerHTML]="providerHighlight(provider)"
          [csFocused]="activeProviderDropdownIndex === i"
          [class.focused]="activeProviderDropdownIndex === i"
          [attr.aria-selected]="activeProviderDropdownIndex === i"
          [attr.aria-label]="getProviderName(provider)"
          [ngClass]="{ selected: activeProviderDropdownIndex === i }"
          (mouseenter)="handleMouseEnter(i, 'provider')"
          tabindex="0"
        >
          <a></a>
        </li>
      </ul>
      <ng-container *ngIf="enableAutoSuggestionProviders && providerSliceIndex > 3 && providerCount > 10">
        <div class="show-all-providers">
          <a href="javascript:;" (click)="keywordSeeAllProviderSearchClick()" #seeAllproviders>
            {{ providerSliceIndex < suggestions.providers.length ? '' : 'See all providers'}}
            <svg xmlns="http://www.w3.org/2000/svg"></svg>
          </a>
        </div>
      </ng-container>

      <div class="show-more-less" *ngIf="suggestions.providers.length > 3">
        <a
          href="javascript:;"
          role="button"
          [attr.aria-label]="providerSliceIndex < suggestions.providers.length ? 'Show more providers' : 'Show less providers'"
          (mouseenter)="seeLessMoreHover('provider')"
          (click)="expandLessCick('provider')"
          #providerSeeMore
        >
          {{ providerSliceIndex < suggestions.providers.length ? 'More' : 'Less' }}
          <svg
            class="more-arrow"
            [ngClass]="{ 'less-arrow': providerSliceIndex === suggestions.providers.length }"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 1L6 6L11 1" />
          </svg>
        </a>
      </div>
    </li>
    <hr *ngIf="suggestions.providers.length > 0 && suggestions.keywords.length > 0" />
    <li class="keywords-list" *ngIf="suggestions.keywords.length > 0">
      <h3><p>Keywords</p></h3>
      <ul>
        <li
          *ngFor="let keyword of suggestions.keywords.slice(0, keywordSliceIndex); let j = index"
          (click)="keywordClick(keyword)"
          [innerHTML]="queryHighlight(keyword)"
          [csFocused]="activeKeywordDropdownIndex === j"
          [class.focused]="activeKeywordDropdownIndex === j"
          [attr.aria-selected]="activeKeywordDropdownIndex === j"
          [ngClass]="{ selected: activeKeywordDropdownIndex === j }"
          (mouseenter)="handleMouseEnter(j, 'keyword')"
          [attr.aria-label]="keyword"
          tabindex="0"
        ></li>
      </ul>
      <div class="show-more-less" *ngIf="suggestions.keywords.length > 3">
        <a
          href="javascript:;"
          role="button"
          [attr.aria-label]="keywordSliceIndex < suggestions.keywords.length ? 'Show more keywords' : 'Show less keywords'"
          (mouseenter)="seeLessMoreHover('keyword')"
          (click)="expandLessCick('keyword')"
          #keywordSeeMore
        >
          {{ keywordSliceIndex < suggestions.keywords.length ? 'More' : 'Less' }}
          <svg
            class="more-arrow"
            [ngClass]="{ 'less-arrow': keywordSliceIndex === suggestions.keywords.length }"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 1L6 6L11 1" />
          </svg>
        </a>
      </div>
    </li>
  </ul>
</div>
