<div>
  <cs-accordion [title]="'Insurance'" *ngIf="showInsuranceFilter">
    <div class="toggle-two-col-list">
      <div *ngFor="let insurance of filtersModel.availableInsurances" class="toggle-col-option" (click)="toggleInsurance(insurance)">
        <span class="option-checkbox">
          <svg class="unchecked" *ngIf="!checkInsurance(insurance)" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <rect rx="2.5" width="19" height="19" x="0.5" y="0.5" />
          </svg>
          <svg class="checked" *ngIf="checkInsurance(insurance)" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <rect rx="3" />
            <path d="M10.0001 15L4.16675 9.16663L5.83341 7.49996L10.0001 11.6666L20.0001 1.66663V4.99996L10.0001 15Z" />
          </svg>
        </span>
        {{ insurance.name }}&nbsp;<span class="option-count">({{ insurance.count }}) </span>
      </div>
    </div>
  </cs-accordion>

  <cs-accordion [title]="'Language'" *ngIf="showLanguageFilter">
    <div class="toggle-two-col-list">
      <div *ngFor="let language of filtersModel.availableLanguages" class="toggle-col-option" (click)="toggleLanguage(language)">
        <span class="option-checkbox">
          <svg class="unchecked" *ngIf="!checkLanguage(language)" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <rect rx="2.5" width="19" height="19" x="0.5" y="0.5" />
          </svg>
          <svg class="checked" *ngIf="checkLanguage(language)" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <rect rx="3" />
            <path d="M10.0001 15L4.16675 9.16663L5.83341 7.49996L10.0001 11.6666L20.0001 1.66663V4.99996L10.0001 15Z" />
          </svg>
        </span>
        {{ language.name }}&nbsp;<span class="option-count">({{ language.count }}) </span>
      </div>
    </div>
  </cs-accordion>
</div>
