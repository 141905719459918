import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ProviderRating } from '../models/provider-rating';
import { RuntimeConfiguration } from './configuration-injection.service';
import { ConfigurationService } from './configuration.service';
import { LogService } from '../services/log.service';

@Injectable({
  providedIn: 'root'
})
export class ProviderRatingService {
  apiOptions: { headers: HttpHeaders };
  runtimeConfig: RuntimeConfiguration;

  constructor(public http: HttpClient, private logService: LogService, private configurationService: ConfigurationService) {
    this.runtimeConfig = this.configurationService.getRuntimeConfiguration();
  }

  getProviderRatings(npis: number[] | string[], includeComments = false): Observable<ProviderRating[]> {
    const useLegacyAPI = this.configurationService.useLegacyAPI();
    const useNewRatingsEndpoint = this.configurationService.useNewRatingsEndpoint();
    if (useLegacyAPI || !useNewRatingsEndpoint) {
      return this.http.post<ProviderRating[]>(this.runtimeConfig.pressGaneyRatingsUrl, npis, this.apiOptions).pipe(
        catchError((err: Error) => {
          this.logService.error(err.toString());
          return of(Array<ProviderRating>());
        })
      );
    }

    if (this.runtimeConfig.newApiBaseUrl) {
      const ratingsUrl = `${this.runtimeConfig.newApiBaseUrl}getratings?showComments=${includeComments ? 'true' : 'false'}`;
      return this.http.post<ProviderRating[]>(ratingsUrl, npis, this.apiOptions).pipe(
        catchError((err: Error) => {
          this.logService.error(err.toString());
          return of(Array<ProviderRating>());
        })
      );
    }

    return of(Array<ProviderRating>());
  }
}
