import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { DocAsapBookingLauncherComponent } from '../doc-asap-booking-launcher/doc-asap-booking-launcher.component';
import { ButtonModule, LoadingSpinnerModule, ModalModule } from '@dignity-health/ngx-components';

@NgModule({
  declarations: [DocAsapBookingLauncherComponent],
  imports: [CommonModule, ButtonModule, ModalModule, LoadingSpinnerModule],
  exports: [DocAsapBookingLauncherComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DocAsapBookingLauncherModule {}
