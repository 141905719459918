<div class="provider-info-card" role="application" [class.result-card]="!showImprovedUX && !resizeService.isDesktopViewport" #resultCard>
  <div class="basic-info">
    <div class="img-holder" *ngIf="!showProviderVideoLinkMarketConfig">
      <a
        routerLink="{{ providerLink }}"
        target="{{ providerTarget }}"
        (click)="$event.preventDefault(); goToProvider()"
        *ngIf="fadUrl.isFadSpa"
        [attr.aria-label]="providerDisplayNameInformation(provider.displayFullName)"
        tabindex="0"
      >
        <img
          src="{{ providerImage }}"
          class="provider-image"
          [ngClass]="{ 'provider-cards': isProviderCard }"
          (error)="getAltImageSrc($event)"
          alt=""
          loading="lazy"
        />
      </a>
      <a
        href="{{ providerLink }}"
        target="{{ providerTarget }}"
        (click)="$event.preventDefault(); goToProvider()"
        *ngIf="!fadUrl.isFadSpa"
        [attr.aria-label]="providerDisplayNameInformation(provider.displayFullName)"
        tabindex="0"
      >
        <img
          src="{{ providerImage }}"
          class="provider-image"
          [ngClass]="{ 'provider-cards': isProviderCard }"
          (error)="getAltImageSrc($event)"
          alt=""
          loading="lazy"
      /></a>
    </div>
    <div class="img-holder" *ngIf="showProviderVideoLinkMarketConfig">
      <a
        routerLink="{{ providerLink }}"
        target="{{ providerTarget }}"
        (click)="$event.preventDefault(); goToProvider()"
        tabindex="-1"
        [attr.aria-label]="providerDisplayNameInformation(provider.displayFullName)"
        *ngIf="fadUrl.isFadSpa"
        tabindex="0"
      >
        <img
          src="{{ providerImage }}"
          class="provider-image"
          [ngClass]="{ 'provider-cards': isProviderCard }"
          (error)="getAltImageSrc($event)"
          alt=""
          aria-hidden="true"
          loading="lazy"
        />
      </a>
      <a
        href="{{ providerLink }}"
        target="{{ providerTarget }}"
        (click)="$event.preventDefault(); goToProvider()"
        tabindex="0"
        [attr.aria-label]="providerDisplayNameInformation(provider.displayFullName)"
        *ngIf="!fadUrl.isFadSpa"
      >
        <img
          src="{{ providerImage }}"
          class="provider-image"
          [ngClass]="{ 'provider-cards': isProviderCard }"
          (error)="getAltImageSrc($event)"
          alt=""
          aria-hidden="true"
          loading="lazy"
      /></a>
      <!-- Need to add video property in place of youtube url -->
      <div
        class="video-button"
        (click)="displayVideo()"
        (keydown.enter)="displayVideo()"
        *ngIf="showProviderVideoLink"
        role="button"
        tabindex="0"
        attr.aria-label="View Video of {{provider?.displayFullName}}"
      >
        <svg width="80" height="24" viewBox="0 0 80 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 0H80V22C80 23.1046 79.1046 24 78 24H2C0.895429 24 0 23.1046 0 22V0Z" fill="#F5F5F5"></path>
          <circle cx="12" cy="12" r="7"></circle>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M10 16V8L16 12.0002L10 16Z" fill="white"></path>
          <text x="24" y="16" fill="#626567" font-size="10px">View Video</text>
        </svg>
      </div>
    </div>

    <div class="card-information">
      <div class="result-card-header">
        <div class="provider-info">
          <div class="provider-name-header">
            <h3 class="h3" *ngIf="fadUrl.isFadSpa" aria-hidden="true">
              <div
                class="provider-name"
                [ngClass]="{
              'dh': divisionCodes.includes('dignity-health'),
              'chi': !divisionCodes.includes('dignity-health')
              }"
              >
                <a
                  routerLink="{{ providerLink }}"
                  target="{{ providerTarget }}"
                  (click)="$event.preventDefault(); goToProvider()"
                  class="provider-name"
                  *ngIf="fadUrl.isFadSpa"
                >
                  <span aria-hidden="true">{{ provider.displayFullName }}</span></a
                >
              </div>
            </h3>
            <span *ngIf="fadUrl.isFadSpa">
              <img
                *ngFor="let imageUrl of logoUrls"
                src="{{ imageUrl.url }}"
                alt="{{ imageUrl?.altName }}"
                class="provider-logo"
                loading="lazy"
                tabindex="0"
            /></span>
            <h3 class="h3" *ngIf="!fadUrl.isFadSpa">
              <div
                class="provider-name"
                [ngClass]="{
              'dh': divisionCodes.includes('dignity-health'),
              'chi': !divisionCodes.includes('dignity-health')
              }"
              >
                <a
                  href="{{ providerLink }}"
                  target="{{ providerTarget }}"
                  (click)="$event.preventDefault(); goToProvider()"
                  class="provider-name h3"
                  *ngIf="!fadUrl.isFadSpa"
                >
                  {{ provider.displayFullName }}
                </a>
              </div>
            </h3>
            <span *ngIf="!fadUrl.isFadSpa">
              <img
                *ngFor="let imageUrl of logoUrls"
                src="{{ imageUrl.url }}"
                alt="{{ imageUrl?.altName }}"
                class="provider-logo"
                loading="lazy"
                tabindex="0"
            /></span>
          </div>
          <div class="provider-specialty-m">
            <p *ngFor="let speciality of providerSpecialities" class="p1">{{ speciality }}</p>
          </div>
          <div class="provider-specialty-m" style="margin-top: auto">
            <p *ngFor="let Secondaryspeciality of nonSEOSecondarySpecialities" class="p1">{{ Secondaryspeciality }}</p>
          </div>
          <div class="desktop-container">
            <div
              [class.new-star-ratting]="showImprovedUX"
              tabindex="0"
              role="link"
              [attr.aria-label]="'Average stars ' + provider.rating?.overallRating.value + 'out of 5 and  ' + (provider.rating?.totalRatingCount) + ' reviews'"
            >
              <div *ngIf="showStarRatings && showStarRatingsBanner">
                <div *ngIf="provider.rating" class="provider-rating" [ngClass]="{ 'has-rating': provider.rating?.totalRatingCount > 0 }">
                  <dhcl-star-rating
                    [reviews]="provider.rating?.totalRatingCount"
                    [rating]="provider.rating?.overallRating.value"
                    (click)="provider.rating?.totalRatingCount !== 0 ? goToProvider(true) : false"
                    [showRatingText]="showRatingText"
                    [showNumReviews]="showNumReviews"
                  ></dhcl-star-rating>
                  <dhcl-tooltip
                    *ngIf="provider.rating?.totalRatingCount === 0 || provider.rating?.overallRating.value === 0"
                    class="rating-tooltip"
                  >
                    {{ ratingTooltipText }}
                  </dhcl-tooltip>
                </div>
                <div *ngIf="provider.loadingRating" class="stars-container">
                  <div class="stars">
                    <svg
                      *ngFor="let star of stars; let i = index"
                      width="18"
                      height="16"
                      viewBox="0 0 18 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <defs>
                        <linearGradient
                          xmlns="http://www.w3.org/2000/svg"
                          y1="100%"
                          x2="0"
                          y2="100%"
                          id="grad107b5e38-b154-4ba7-931d-08d12ec9961f-4"
                          x1="1%"
                        >
                          <stop stop-color="#727779" offset="0%"></stop>
                          <stop stop-color="var(--dhcl-color-yellow)" offset="0%"></stop>
                        </linearGradient>
                      </defs>
                      <path
                        d="M8.67027 0.871503C8.85549 0.376165 9.55613 0.376166 9.74135 0.871502L11.1349 4.59828C11.3558 5.18902 11.9084 5.59051 12.5385 5.61804L16.5135 5.79174C17.0418 5.81482 17.2583 6.48117 16.8444 6.81039L13.7307 9.28736C13.2371 9.67999 13.0261 10.3296 13.1946 10.9374L14.2577 14.7715C14.399 15.2811 13.8322 15.6929 13.3912 15.4011L10.0733 13.2051C9.54734 12.8571 8.86428 12.8571 8.33834 13.2051L5.02041 15.4011C4.57941 15.6929 4.01258 15.2811 4.15389 14.7715L5.21704 10.9374C5.38556 10.3296 5.17448 9.67999 4.68092 9.28736L1.56717 6.81039C1.15331 6.48117 1.36982 5.81482 1.89815 5.79174L5.87315 5.61804C6.50323 5.59051 7.05584 5.18902 7.27674 4.59828L8.67027 0.871503Z"
                        fill="url(#grad107b5e38-b154-4ba7-931d-08d12ec9961f-4)"
                        stroke="#DE7925"
                      ></path>
                    </svg>
                  </div>
                  <em class="loading-test">Loading ratings...</em>
                </div>
              </div>
            </div>
          </div>
          <div class="provider-badge">
            <cs-badges [provider]="provider" *ngIf="!isMobileViewport"></cs-badges>
          </div>
        </div>
        <div *ngIf="!isMobileViewport" class="desktop-scheduling">
          <div *ngIf="!preferredProviderSelect" class="launch-btn-container">
            <div class="schedule-call" *ngIf="showCallToScheduleCTA">
              <div *ngIf="resizeService.isDesktopViewport" class="desktop-call p" [class.desktop-call-old]="!showImprovedUX">
                <svg *ngIf="showImprovedUX" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M15.2309 15.4191L15.9809 12.1691C16.0622 11.816 15.8778 11.4535 15.5465 11.3097L12.0465 9.8097C11.7403 9.67845 11.3809 9.76595 11.1715 10.0253L9.62154 11.9191C7.22466 10.7941 5.23091 8.82845 4.08404 6.38158L5.97779 4.83158C6.23716 4.61908 6.32466 4.26283 6.19341 3.95658L4.69341 0.456576C4.54654 0.122201 4.18404 -0.0621745 3.83091 0.0190755L0.580913 0.769076C0.243413 0.847201 -0.000337601 1.1472 -0.000337601 1.50033C-0.000337601 9.50345 6.48404 16.0003 14.4997 16.0003C14.8497 16.0003 15.1528 15.7597 15.2309 15.4191Z"
                    fill="#0071C2"
                  />
                </svg>
                <svg *ngIf="!showImprovedUX" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M15.2309 15.4191L15.9809 12.1691C16.0622 11.816 15.8778 11.4535 15.5465 11.3097L12.0465 9.8097C11.7403 9.67845 11.3809 9.76595 11.1715 10.0253L9.62154 11.9191C7.22466 10.7941 5.23091 8.82845 4.08404 6.38158L5.97779 4.83158C6.23716 4.61908 6.32466 4.26283 6.19341 3.95658L4.69341 0.456576C4.54654 0.122201 4.18404 -0.0621745 3.83091 0.0190755L0.580913 0.769076C0.243413 0.847201 -0.000337601 1.1472 -0.000337601 1.50033C-0.000337601 9.50345 6.48404 16.0003 14.4997 16.0003C14.8497 16.0003 15.1528 15.7597 15.2309 15.4191Z"
                    fill="#4D525A"
                  />
                </svg>
                Call to schedule an appointment
              </div>
              <a
                href="tel:{{ providerOffices[0]?.phones[0] }}"
                class="tablet-call p p2"
                *ngIf="resizeService.isTabletViewport && showCallToScheduleCTA && !showImprovedUX"
                data-event-name="click-to-call"
                data-event-detail="engagement|click to call|generic"
              >
                <span>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M15.2309 15.4191L15.9809 12.1691C16.0622 11.816 15.8778 11.4535 15.5465 11.3097L12.0465 9.8097C11.7403 9.67845 11.3809 9.76595 11.1715 10.0253L9.62154 11.9191C7.22466 10.7941 5.23091 8.82845 4.08404 6.38158L5.97779 4.83158C6.23716 4.61908 6.32466 4.26283 6.19341 3.95658L4.69341 0.456576C4.54654 0.122201 4.18404 -0.0621745 3.83091 0.0190755L0.580913 0.769076C0.243413 0.847201 -0.000337601 1.1472 -0.000337601 1.50033C-0.000337601 9.50345 6.48404 16.0003 14.4997 16.0003C14.8497 16.0003 15.1528 15.7597 15.2309 15.4191Z"
                    />
                  </svg>
                  Call to schedule
                </span>
              </a>
              <a
                href="tel:{{ providerOffices[0]?.phones[0] }}"
                class="table-call-new p p2"
                *ngIf="resizeService.isTabletViewport && showCallToScheduleCTA && showImprovedUX"
                data-event-name="click-to-call"
                data-event-detail="engagement|click to call|generic"
              >
                <span>
                  <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M15.2309 15.4191L15.9809 12.1691C16.0622 11.816 15.8778 11.4535 15.5465 11.3097L12.0465 9.8097C11.7403 9.67845 11.3809 9.76595 11.1715 10.0253L9.62154 11.9191C7.22466 10.7941 5.23091 8.82845 4.08404 6.38158L5.97779 4.83158C6.23716 4.61908 6.32466 4.26283 6.19341 3.95658L4.69341 0.456576C4.54654 0.122201 4.18404 -0.0621745 3.83091 0.0190755L0.580913 0.769076C0.243413 0.847201 -0.000337601 1.1472 -0.000337601 1.50033C-0.000337601 9.50345 6.48404 16.0003 14.4997 16.0003C14.8497 16.0003 15.1528 15.7597 15.2309 15.4191Z"
                      fill="#4D525A"
                    />
                  </svg>
                  Call to schedule an appointment
                </span>
              </a>
            </div>
            <div *ngIf="showGuidedFlowCTA" class="launcher-button">
              <button dhclButton buttonType="primary" buttonSize="small" (click)="launchGuidedFlow() " [attr.aria-label]="buttonLabelText">
                Schedule an appointment
              </button>
            </div>
            <div *ngIf="showDocAsapCTA">
              <cs-doc-asap-booking-launcher
                [srcUrl]="srcUrl"
                [docAsapId]="docAsapId"
                [buttonSize]="'small'"
                [launcherButtonText]="'Schedule an appointment'"
                [provider]="provider"
              ></cs-doc-asap-booking-launcher>
            </div>

            <div class="launcher-button" *ngIf="showImprovedUX">
              <button dhclButton buttonType="secondary" çlass="profile-button" buttonSize="small" (click)="goToProvider()">
                View Profile
              </button>
            </div>
            <cs-patient-age-restrictions
              *ngIf="(showPatientAgeRestrictions && showGuidedFlowCTA) || (showAgeRestrictionRuleForDH)"
              [showImprovedUX]="showImprovedUX"
              [patientAgeGroups]="provider.patientAgeGroups"
              [showAgeRestrictionRuleForDH]="showAgeRestrictionRuleForDH"
              [ageRestrictionRule]="ageRestrictionRule"
              [isProviderSearchResultPage]="true"
            ></cs-patient-age-restrictions>
            <label class="unavailable-text" *ngIf="showTemporarilyUnavailableCTA"> Online scheduling is temporarily unavailable </label>
          </div>
          <div *ngIf="preferredProviderSelect" class="launcher-button">
            <button dhclButton buttonType="primary" buttonSize="small" (click)="makeAsPreferredProvider()">Select</button>
          </div>
        </div>
      </div>
      <div class="additional-information" *ngIf="!isMobileViewport && !showImprovedUX">
        <div class="affiliations-locations" *ngIf="showAffiliations && provider.hospitalNames.length > 0">
          <cs-hospital-affiliations
            *ngIf="showAffiliations && !showImprovedUX && provider.hospitalNames.length > 0"
            [affiliations]="provider.hospitalNames"
            [hideExtraContent]="hideExtraContent"
          >
          </cs-hospital-affiliations>
        </div>
        <div class="locations" [class.location-container]="!(showAffiliations && provider.hospitalNames.length > 0)">
          <cs-result-card-location-list [provider]="provider" [isProviderCardContent]="isProviderCard"> </cs-result-card-location-list>
        </div>
      </div>
      <div class="provider-specialty-info" [class.provider-info-new]="showImprovedUX" *ngIf="!isProviderCard && isMobileViewport">
        <div class="provider-specialty-m">
          <cs-badges [provider]="provider" *ngIf="isMobileViewport"></cs-badges>
        </div>
      </div>
    </div>
  </div>
  <div class="additional-information" *ngIf="!isMobileViewport && showImprovedUX">
    <cs-result-card-location-list [provider]="provider" [isProviderCardContent]="isProviderCard"> </cs-result-card-location-list>
  </div>

  <cs-hospital-affiliations
    [affiliations]="provider.hospitalNames"
    *ngIf="!showImprovedUX && !isProviderCard && isMobileViewport && showAffiliations && provider.hospitalNames.length > 0"
    [hideExtraContent]="hideExtraContent"
  >
  </cs-hospital-affiliations>
  <ng-container *ngIf="isMobileViewport">
    <cs-result-card-location-list
      [provider]="provider"
      [isProviderCardContent]="isProviderCard"
      class="result-card-locations"
    ></cs-result-card-location-list>
  </ng-container>
  <div *ngIf="isMobileViewport" class="mobile-scheduling">
    <div *ngIf="!preferredProviderSelect" class="launch-btn-container">
      <a
        href="tel:{{ providerOffices[0]?.phones[0] }}"
        data-event-name="click-to-call"
        data-event-detail="engagement|click to call|generic"
        class="schedule-call p p2"
        [class.schedule-call-new]="showImprovedUX"
        *ngIf="showCallToScheduleCTA"
      >
        <span>
          <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15.2309 15.4191L15.9809 12.1691C16.0622 11.816 15.8778 11.4535 15.5465 11.3097L12.0465 9.8097C11.7403 9.67845 11.3809 9.76595 11.1715 10.0253L9.62154 11.9191C7.22466 10.7941 5.23091 8.82845 4.08404 6.38158L5.97779 4.83158C6.23716 4.61908 6.32466 4.26283 6.19341 3.95658L4.69341 0.456576C4.54654 0.122201 4.18404 -0.0621745 3.83091 0.0190755L0.580913 0.769076C0.243413 0.847201 -0.000337601 1.1472 -0.000337601 1.50033C-0.000337601 9.50345 6.48404 16.0003 14.4997 16.0003C14.8497 16.0003 15.1528 15.7597 15.2309 15.4191Z"
            />
          </svg>
          Call to schedule
        </span>
      </a>
      <div *ngIf="showGuidedFlowCTA" class="launcher-button">
        <button dhclButton buttonType="primary" buttonSize="small" (click)="launchGuidedFlow()">Schedule an appointment</button>
      </div>
      <div class="launcher-button" *ngIf="showImprovedUX">
        <button dhclButton buttonType="secondary" çlass="profile-button" buttonSize="small" (click)="goToProvider()">View Profile</button>
      </div>
      <div *ngIf="showDocAsapCTA" style="margin: 15px 0">
        <cs-doc-asap-booking-launcher
          [srcUrl]="srcUrl"
          [docAsapId]="docAsapId"
          [buttonSize]="'small'"
          [launcherButtonText]="'Schedule an appointment'"
          [provider]="provider"
        ></cs-doc-asap-booking-launcher>
      </div>
      <cs-patient-age-restrictions
        *ngIf="(showPatientAgeRestrictions && showGuidedFlowCTA) || (showAgeRestrictionRuleForDH)"
        [patientAgeGroups]="'provider.patientAgeGroups'"
        [showImprovedUX]="showImprovedUX"
        [showAgeRestrictionRuleForDH]="showAgeRestrictionRuleForDH"
        [ageRestrictionRule]="ageRestrictionRule"
      ></cs-patient-age-restrictions>
      <label class="unavailable-text" *ngIf="showTemporarilyUnavailableCTA"> Online scheduling is temporarily unavailable </label>
    </div>
    <div *ngIf="preferredProviderSelect" class="launcher-button">
      <button dhclButton buttonType="primary" buttonSize="small" (click)="makeAsPreferredProvider()">Select</button>
    </div>
  </div>
  <div>
    <cs-in-network-banner *ngIf="showMedGroupBanner && provider.firstNetworkProvider" [provider]="provider"></cs-in-network-banner>
  </div>
</div>
