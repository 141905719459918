import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiConstants } from 'src/app/constants/api-constants.new';
import { GlobalParams } from 'src/app/global-params';
import { ILocationAutoSuggSearchParams } from 'src/app/models/configuration';
import { LocationByIpResult, LocationDetailResult, LocationSuggestionResult, SelectedLocation } from 'src/app/models/location';
import { SessionObjectNames } from 'src/app/models/stringconst-names';
import { ApiResponse } from 'src/app/utils/api-response';
import { ConfigurationService } from '..//configuration.service';
import { LocationLegacyService } from './location.legacy.service';
import { catchError, concatMap, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LocationNewService extends LocationLegacyService {
  constructor(public http: HttpClient, public configurationService: ConfigurationService) {
    super(http, configurationService);
    this.apiBaseUrl = this.runtimeConfig.newApiBaseUrl;
    this.apiConstants = ApiConstants;
  }
  getLocationByIP(): Observable<ApiResponse<LocationByIpResult>> {
    const LOCATION_DETAILS_API = `${this.apiBaseUrl}${this.apiConstants.locationDetailsApi}`;
    return this.http.get<ApiResponse<LocationByIpResult>>(`${LOCATION_DETAILS_API}`, this.apiOptions);
  }

  getTypedLocationSuggestions(location: string, sessionToken?: string): Observable<ApiResponse<LocationSuggestionResult[]>> {
    const zipPattern = /\d+/g;
    if (location?.match(zipPattern) && location?.split(',').length < 3 && !GlobalParams.isNeighborhoodSearch) {
      location = location.replace(/^\D+/g, '').trim();
    }

    let httpParams = this.getLocationSearchParams(sessionToken);
    httpParams = httpParams.append('searchQuery', location.replace(/\s/g, '-'));
    const url = `${this.apiBaseUrl}${this.apiConstants.suggestionsBaseApiUrl}locations`;

    return this.http.get<ApiResponse<LocationSuggestionResult[]>>(url, {
      headers: this.apiOptions.headers,
      params: httpParams
    });
  }

  getLocationSearchParams(sessionToken?: string): HttpParams {
    let httpParams = new HttpParams();
    const token = sessionToken ?? window.sessionStorage.getItem(SessionObjectNames.LocationSessionId) ?? '';
    httpParams = httpParams.append('api-version', '2');
    httpParams = httpParams.append('sessionToken', token);

    if (Object.keys(this.configurationService.getAutoSuggLocationSearchParams()).length > 0) {
      httpParams = this.autoSuggLocationSearchParams(this.configurationService.getAutoSuggLocationSearchParams(), httpParams);
    } else {
      httpParams = httpParams.append('latitude', null);
      httpParams = httpParams.append('longitude', null);
    }
    return httpParams;
  }

  autoSuggLocationSearchParams(_suggParams: ILocationAutoSuggSearchParams, httpParams: HttpParams): HttpParams {
    // All three Params are Mandaorty as per US
    if (_suggParams.latitude && _suggParams.longitude && _suggParams.radius) {
      httpParams = httpParams.set('latitude', _suggParams.latitude);
      httpParams = httpParams.set('longitude', _suggParams.longitude);
      httpParams = httpParams.set('radius', _suggParams.radius);
      httpParams = httpParams.set('strictbounds', true);
    }

    return httpParams;
  }

  getLocationDetail(placeId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('locationId', placeId);
    httpParams = httpParams.append('api-version', '2');
    const detailUrl = `${this.apiBaseUrl}${this.apiConstants.suggestionsBaseApiUrl}location-detail`;
    return this.http.get<ApiResponse<LocationDetailResult>>(detailUrl.toString(), {
      headers: this.apiOptions.headers,
      params: httpParams
    });
  }

  getTypedLocationDetail(placeId: string): Observable<ApiResponse<LocationDetailResult>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('locationId', placeId);
    httpParams = httpParams.append('api-version', '2');
    const url = `${this.apiBaseUrl}${this.apiConstants.suggestionsBaseApiUrl}location-detail`;

    return this.http
      .get<ApiResponse<LocationDetailResult>>(url, {
        headers: this.apiOptions.headers,
        params: httpParams
      })
      .pipe(
        tap((response) => {
          if (response.result) {
            window.sessionStorage.setItem(SessionObjectNames.LocationSessionId, response.result.sessionToken);
          }
        })
      );
  }

  public queryLocation(location: string): Observable<SelectedLocation> {
    if (!location) {
      return of(null);
    }
    return this.getTypedLocationSuggestions(location).pipe(
      catchError(() => of(null)),
      concatMap((suggestionResponse: ApiResponse<LocationSuggestionResult[]>) => {
        if (!suggestionResponse.isValid || suggestionResponse.result.length === 0) {
          return of({ isLocationValid: false } as SelectedLocation);
        }
        const zipPattern = /\d+/g;
        const isZipExist = location.match(zipPattern);
        let placeId = '';
        if (isZipExist) {
          placeId = suggestionResponse.result[0].googlePlaceId;
        } else {
          placeId = suggestionResponse.result.find((l) =>
            l.locationName
              .replace(/[,.]|-|\s/g, '')
              .trim()
              .toLowerCase()
              .includes(
                location
                  .replace(/[,.]|-|\s/g, '')
                  .trim()
                  .toLowerCase()
              )
          ).googlePlaceId;
        }
        return this.getLocationDetailInfo(placeId, isZipExist);
      })
    );
  }

  private getLocationDetailInfo(locationId: string, isZipExist: RegExpMatchArray): Observable<SelectedLocation> {
   return this.getTypedLocationDetail(locationId).pipe(
      map((detailResponse) => {
        GlobalParams.isNeighborhoodSearch = false;
        return {
          googlePlaceId: locationId,
          name: detailResponse.result.name + (isZipExist && detailResponse.result.zip ? ' ' + detailResponse.result.zip : ''),
          neighborhood: detailResponse.result.neighborhood ? detailResponse.result.neighborhood : '',
          latitude: detailResponse.result.latitude,
          longitude: detailResponse.result.longitude,
          city: detailResponse.result.city,
          state: detailResponse.result.state,
          isLocationValid: true,
          zip: isZipExist ? detailResponse.result.zip : ''
        } as SelectedLocation;
      }),
      catchError(() => of(null))
    );
  }

}
