import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CovidLocation } from '../../models/covid-location.model';

@Component({
  selector: 'cs-covid-vaccination-location-card',
  templateUrl: './covid-vaccination-location-card.component.html',
  styleUrls: ['./covid-vaccination-location-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CovidVaccinationLocationCardComponent {
  @Input() location: CovidLocation;
  @Output() bookAppointment = new EventEmitter<any>();

  btnOkClickHandler(): void {
    const locationInformation = [this.location.id, this.location.scheduleId];
    this.bookAppointment.emit(locationInformation);
  }
}
