import { Component,Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { clearAllSessionVariables } from 'src/app/utils/utils';

@Component({
  selector: 'cs-cta-new-search',
  templateUrl: './cta-new-search.component.html',
  styleUrls: ['./cta-new-search.component.scss']
})
export class CtaNewSearchComponent implements OnInit {

  baseUrl: string;
  routeValue: string;
  embedSource: string;
  marketCode: string;
  isCernerPage: boolean;
  @Input() isDignityHealthDivision: boolean;
  dhMarketText = '';
  constructor(private configurationService: ConfigurationService,public router: Router) { }

  
  ngOnInit(): void {
    this.isCernerPage= this.configurationService.getCernerPage();
    this.baseUrl = this.configurationService.getRuntimeConfiguration().fadBaseUrl;
    this.embedSource = this.configurationService.getEmbedSource();
    this.marketCode = this.configurationService.getMarketCodes()[0];
    if(this.isDignityHealthDivision)
    {
      this.dhMarketText = 'below';
    }
  }

  navigateTo(): void {
    clearAllSessionVariables();

    if (this.embedSource === 'cerner') {
      this.routeValue = `${this.baseUrl}${this.configurationService.getRedirectToFADinCerner()}`;
    } else if (this.embedSource === 'mychart') {
      this.routeValue = `${this.configurationService.getRedirectURLPathForFADEpic()}`;
    } else if (document.body.firstElementChild?.tagName === 'CS-FAD' || document.body.firstElementChild?.tagName === 'FAD-SAAS') {
      this.routeValue = `${this.baseUrl}${this.marketCode}`;
    }else if (this.isCernerPage) {
      this.router.navigate(['']);
    } else {
      this.routeValue = `${this.configurationService.getRedirectInAEM()}`;
    }
    window.open(this.routeValue, '_self');
  }
}
