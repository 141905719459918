import { Component, Input, HostListener, ChangeDetectionStrategy } from '@angular/core';
import { LogoDetails } from 'src/app/models/configuration';
import { Provider } from '../../models/provider';
import { ProviderImage } from '../../models/provider-image';

@Component({
  selector: 'cs-provider-hero',
  templateUrl: './provider-hero.component.html',
  styleUrls: ['./provider-hero.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProviderHeroComponent {
  @Input() provider: Provider;
  @Input() referringUrl: string;
  @Input() providerImage: ProviderImage;
  @Input() showSuggestAnEditLink: boolean;
  @Input() isMobileViewport: boolean;
  @Input() logoUrls: LogoDetails[] = [];
  @Input() ratingTooltipText: string;
  @Input() showRatingText: boolean;
  @Input() showNumReviews: boolean;
  @Input() showStarRatings: boolean;
  @Input() showStarRatingsBanner: boolean;
  @Input() providerSpecialities: string[];
  @Input() suggestAnEditLinkContent: string;
  @Input() malePlaceholder: string;
  @Input() femalePlaceholder: string;
  isDesktopViewport: boolean;
  @Input() nonSEOSecondarySpecialities: string[];
  stars = [1,2,3,4,5];
  @Input() showPrintablePdf: boolean;
 
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isDesktopViewport = event.target.innerWidth > 767;
  }

  getAltImageSrc(event: any) {
    event.target.src = this.provider.gender?.toLowerCase() === 'male' ? this.malePlaceholder : this.femalePlaceholder;
  }

  goToComments() {
    const commentSection: HTMLElement = document.querySelector('div.provider-comments-container');
    commentSection?.scrollIntoView();
  }
  
  printPage() {
    window.print();
  }
}
