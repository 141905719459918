<div class="search-fields">
  <div class="search-fields-group" #searchFieldsGroup>
    <fieldset class="provider-search-field" #searchInputs tabindex="0">
      <legend class="sr-only">Search for a Provider</legend>
      <cs-provider-search
        [searchTerm]="keyword"
        [hideProviderSuggestions]="hideProviderSuggestions"
        [autoSuggestionLoadingMessage]="autoSuggestionLoadingMessage"
        (searchTermChanged)="keywordFromInput($event)"
        (keywordEnter)="keyEnterHandler($event)"
        (goToProviderEvent)="goToProvider($event)"
        (specialtyChanged)="goToFilters($event)"
        (tabPressedOnProviderSearch)="setProviderSearchActive()"
      >
      </cs-provider-search>
      <cs-location-search
        [selectedLocation]="selectedLocation"
        [invalidLocation]="isValidSearchLocation"
        [autoSuggestionLoadingMessage]="autoSuggestionLoadingMessage"
        (setLocation)="selectedLocation = $event"
        (locationEnter)="getSearchResultsBtnclk()"
        (closeProviderAutoSuggestionContainer)="closeProviderAutoSuggestionContainer()"
      ></cs-location-search>

      <button
        data-nav-type="find a doctor"
        (click)="getSearchResultsBtnclk()"
        data-nav-element="fad search button"
        data-nav-location="fad search initiation"
        aria-label="Search available providers"
        class="search-button"
        [ngClass]="{'search-btn':showRoundBtn}"
      >
        <span *ngIf="showSearchBtn">
          <svg width="18" height="18" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M17.7505 16.7591L16.7556 17.754C16.4286 18.0845 15.8942 18.0845 15.5638 17.754L12.0587 14.2489C11.9005 14.0907 11.8126 13.8763 11.8126 13.6512C11.8126 13.4184 11.5418 13.2845 11.3478 13.4132C10.1914 14.1797 8.80489 14.6251 7.31254 14.6251C3.27305 14.6251 0 11.352 0 7.31254C0 3.27305 3.27305 0 7.31254 0C11.352 0 14.6251 3.27305 14.6251 7.31254C14.6251 8.80489 14.1797 10.1914 13.4132 11.3478C13.2845 11.5418 13.4184 11.8126 13.6512 11.8126C13.8763 11.8126 14.0907 11.9005 14.2489 12.0587L17.754 15.5638C18.081 15.8942 18.081 16.4286 17.7505 16.7591ZM2.81252 7.31254C2.81252 9.80161 4.82698 11.8126 7.31254 11.8126C9.80161 11.8126 11.8126 9.79811 11.8126 7.31254C11.8126 4.82347 9.79811 2.81252 7.31254 2.81252C4.82347 2.81252 2.81252 4.82698 2.81252 7.31254Z"
              fill="white"
            />
          </svg>
        </span>
        <span>{{showRoundBtn}}</span>
      </button>
    </fieldset>
  </div>

  <div *ngIf="!isValidSearchLocation && selectedLocation?.name !== 'undefined'" class="search-fields-group">
    <div class="provider-search-field">
      <div *ngIf="!isVMFHMarket" class="no-location-error" tabindex="0">
        <ng-container *ngTemplateOutlet="locationError"></ng-container>
      </div>
      <div *ngIf="isVMFHMarket" class="no-location-error-vmfh" tabindex="0">
        <ng-container *ngTemplateOutlet="locationError"></ng-container>
      </div>
    </div>
  </div>

  <div class="provider-role-checkbox" *ngIf="includeProviderRoleTypesCheckbox">
    <cs-provider-role-checkbox [selectCheckBox]="includeProviderRoleTypes" (includeProviderRoleTypes)="providerRoleTypesSelected($event)">
    </cs-provider-role-checkbox>
  </div>

  <cs-quicklinks-search
    (quicklinkCategoryClick)="getQuickLinksResults($event)"
    [quickLinksCategories]="quickLinksCategories"
    [quickLinksButtonAlignment]="quickLinksButtonAlignment"
    *ngIf="configShowQuickLinks && showQuicklinks"
  >
  </cs-quicklinks-search>
</div>

<ng-template #locationError>
  <span>
    <svg viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 7.5C15 3.3871 11.6129 0 7.5 0C3.35685 0 0 3.3871 0 7.5C0 11.6431 3.35685 15 7.5 15C11.6129 15 15 11.6431 15 7.5ZM7.5 9.0121C8.25605 9.0121 8.89113 9.64718 8.89113 10.4032C8.89113 11.1895 8.25605 11.7944 7.5 11.7944C6.71371 11.7944 6.10887 11.1895 6.10887 10.4032C6.10887 9.64718 6.71371 9.0121 7.5 9.0121ZM6.16935 4.02218C6.13911 3.81048 6.32056 3.62903 6.53226 3.62903H8.4375C8.64919 3.62903 8.83064 3.81048 8.8004 4.02218L8.58871 8.13508C8.55847 8.34677 8.40726 8.46774 8.22581 8.46774H6.74395C6.5625 8.46774 6.41129 8.34677 6.38105 8.13508L6.16935 4.02218Z"
      />
    </svg>
  </span>
  <span class="no-location-text">
    We’re unable to recognize this location. Please type a new city or zip in the Location search field.</span
  >
</ng-template>
