import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { RuntimeConfiguration } from '../../services/configuration-injection.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { FadLinkService } from 'src/app/services/fad-link.service';
import { Router } from '@angular/router';

@Component({
  selector: 'cs-provider-details-navigation',
  templateUrl: './provider-details-navigation.component.html',
  styleUrls: ['./provider-details-navigation.component.scss']
})
export class ProviderDetailsNavigationComponent implements OnInit,AfterViewInit {
  @Input() referringUrl: string;
  @Input() redirectFromFAL: boolean;
  @Output() navigationEmitter = new EventEmitter();
  runtimeConfig: RuntimeConfiguration;
  btnContent = ' Back to search results';
  isCernerPage: boolean;
  @ViewChild('bckSearchbtn') bckSearchBtn: ElementRef;
  constructor(
    private location: Location,
    public fadLinkServer: FadLinkService,
    public configurationService: ConfigurationService,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.runtimeConfig = this.configurationService.getRuntimeConfiguration();
    this.isCernerPage = this.configurationService.getCernerPage();
    const locationParam = sessionStorage.getItem('falLocationDetailsParam');
    if (this.redirectFromFAL || sessionStorage.getItem('FAL_Provider_redirectto-fad') === 'true') {
      if (locationParam !== null && locationParam.length > 0) {
        this.btnContent = ' Back to location details page';
      }
      sessionStorage.removeItem('FALredirecttoFAD');
      sessionStorage.removeItem('FAL_Provider_redirectto-fad');
    }
  }
  ngAfterViewInit() {
    if (this.isCernerPage) {
      this.bckSearchBtn.nativeElement.focus();
    }
  }
  returnToResults() {
    // Shouldn't we remove from Session Storage immediately during ngOnInit? That way we don't risk it being left in session storage.
    // There are definitely some bugs where the button shows up when it shouldn't.
    // Also, this component shouldn't get an ngIf around it. It should simply decide if it's going to put any contents in the html based on
    // the searchResultsOrigin being in session storage or not.
    // If it's not, then it can simply remove itself using ViewContainerRef .detach or .remove
    if (this.isCernerPage) {
      this.checkFadCernerSearchResult();
      return;
    }
    sessionStorage.removeItem('searchResultsOrigin');
    if (this.referringUrl) {
      this.setWindowLocationHref(this.referringUrl);
    } else if (sessionStorage.getItem('SchedApp_redirectto-fad') && !this.referringUrl) {
      this.setWindowLocationHref(sessionStorage.getItem('SchedApp_redirectto-fad'));
    } else if (window.sessionStorage.getItem('nonFadUrlAccessed') && window.sessionStorage.getItem('fadSearchResultsUrl')) {
      window.sessionStorage.removeItem('nonFadUrlAccessed');
      this.setWindowLocationHref(window.sessionStorage.getItem('fadSearchResultsUrl'));
    } else if (this.redirectFromFAL) {
      const falLocationNameParam = sessionStorage.getItem('falLocationDetailsParam');
      const encodedFalLocationNameParam = encodeURIComponent(falLocationNameParam);
      let falRedirectUrl = `${this.runtimeConfig.falBaseUrl}${encodedFalLocationNameParam}`;

      let embedSource = '';
      try {
        embedSource = this.configurationService.getEmbedSource();
        if (!embedSource) {
          const falElement = document.querySelector('fal-saas');
          if (falElement) {
            embedSource = falElement.getAttribute('embedsource');
          }
        }
      } catch (err) {
        console.log(err);
      }
      if (embedSource === 'cerner') {
        const domain = new URL(this.runtimeConfig.falBaseUrl);
        falRedirectUrl = `${domain.origin}/${this.configurationService.getRedirectToFALinCerner()}/${encodedFalLocationNameParam}`;
      } else if (!this.fadLinkServer.isSelfHosted() || embedSource === 'mychart') {
        if (embedSource === 'mychart') {
          falRedirectUrl = `${this.configurationService.getRedirectURLPathForFALEpic()}/${falLocationNameParam
            .removeSpecialCharacters()
            .sanitizeHyphens()}`;
        } else {
          falRedirectUrl = `${this.configurationService.getRedirectToLocation()}${encodedFalLocationNameParam}`;
        }
      }

      window.sessionStorage.removeItem('FALredirecttoFAD');
      window.sessionStorage.removeItem('falLocationDetailsParam');
      // Removing Session used for filters opration of medical group
      sessionStorage.removeItem('includeProviderRoleTypes');
      sessionStorage.removeItem('selectedMedicalGroup');
      sessionStorage.removeItem('PreviousSelectedMediGrpPrvdRole');
      sessionStorage.removeItem('medGroupQueryParam');
      sessionStorage.removeItem('manualSelection');
      sessionStorage.removeItem('SelectedMedGroupFiltersModel');
      window.open(falRedirectUrl, '_self');
    } else {
      this.checkFadSearchResultURL();
    }
  }
  private checkFadSearchResultURL() {
    if (window.sessionStorage.getItem('fadSearchResultsUrl')) {
      this.setWindowLocationHref(window.sessionStorage.getItem('fadSearchResultsUrl'));
    } else {
      this.location.back();
    }
  }
  private checkFadCernerSearchResult() {
    if (window.sessionStorage.getItem('cernerfadSearchUrl') && window.sessionStorage.getItem('cernerfadSearchUrl').toString().includes('t')) {
      this.router.navigateByUrl(window.sessionStorage.getItem('cernerfadSearchUrl'));
    }
    else
    {
      window.sessionStorage.setItem('cernerfadSearchUrl','');
      this.router.navigate(['']);
    }
  }
  private setWindowLocationHref(url: string): void {
    window.location.href = url;
  }
}
