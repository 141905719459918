import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { ProviderService } from '../services/provider.service';
import { Observable } from 'rxjs';

@Injectable()
export class ProviderDetailsResolver  {
  constructor(private providerService: ProviderService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const providerId = route.paramMap.get('providerId');
    const regexMatch = /(\d+)/s;
    const match = regexMatch.exec(providerId);
    return this.providerService.getProviderDetailsFull(match[0]);
  }
}
export const providerDetailsResolver : ResolveFn<Observable<any>> = route => inject(ProviderDetailsResolver).resolve(route);
