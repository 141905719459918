import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { SelectedLocation } from '../models/location';
import { FacetSummary, FiltersModel } from '../models/filters';
import { Provider } from '../models/provider';
import { SearchFilter } from '../models/search-filter';
import { ApiResponse } from '../utils/api-response';
import './../utils/http-params-prototypes';
import { Params } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export abstract class FilterService {
  public showGuidedFlow = false;
  private displayDialog = new Subject<boolean>();

  private processing = new BehaviorSubject<boolean>(false);
  isProcessing$ = this.processing.asObservable();

  displayDialog$ = this.displayDialog.asObservable();

  setProcessingState(state:boolean) {
    this.processing.next(state);
  }

  abstract getFilterStates(queryParams: Params, filtersModel: FiltersModel, medGroupCode?: string, searchstring?: string): FiltersModel;

  abstract createQueryParams(filtersModel: Partial<FiltersModel>): Params | null | undefined;

  abstract getFacetInfo(searchFilters: SearchFilter, filtersModel: FiltersModel): FiltersModel;

  abstract convertFilterModelToQueryString(
    searchTerm: string,
    locationInfo: SelectedLocation,
    filtersModel: FiltersModel,
    serachFrom: string,
    searchFilters?: SearchFilter,
    pageNumber?: number
  ): [string, HttpParams];

  abstract getTypedFilterSearchResults(
    searchTerm: string,
    locationInfo: SelectedLocation,
    filtersModel: FiltersModel,
    serachFrom: string,
    searchFilters?: SearchFilter,
    pageNumber?: number,
    searchSessionId?: string,
    includeProviderRole?: boolean
  ): Observable<ApiResponse<ProviderSearchResponse>>;

  abstract getFilteredSearchResults(
    searchTerm: string,
    locationInfo: SelectedLocation,
    filtersModel: FiltersModel,
    serachFrom: string,
    searchFilters?: SearchFilter,
    pageNumber?: number
  );

  abstract splitAndMapQueryString(queryString: string): FacetSummary[];

  abstract handleManualProviderRoleSelectionProcess(filtersModel: FiltersModel);

  handleDialogDisplay(display: boolean) {
    this.displayDialog.next(display);
  }
}

export interface ProviderSearchResponse extends SearchFilter {
  providers: Provider[];
}
export interface Value {
  label: string;
  value: string;
  count: number;
  selected: boolean;
}

export interface FacetResponse {
  type: string;
  values: Value[];
}

export interface ProviderSearchResponse {
  searchDistanceMiles: number;
  currentPageNumber: number;
  pageSize: number;
  pages: number;
  isMoreDataAvailable: boolean;
  totalResults: number;
  searchTerm: string;
  sortBy: number;
  sortByName: string;
  facets: FacetResponse[];
  providers: Provider[];
  recommendations: [];
}

export interface LocationSearchRequest {
  locationId: string;
  searchSessionId: string;
  page: number;
  distance: number;
  sortby: number;
  includeProviderRoleType: boolean;
  patients: string;
  languages: string;
  bookOnline: string;
  gender: string;
  videoCalls: string;
  specialties?: string;
  medGroups?: string;
  allVisits: string;
}
