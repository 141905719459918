import { Injectable } from '@angular/core';
import { ConfigurationService } from './configuration.service';
import { LogLevel } from '../models/log-level';
import { FadConfigurationData } from '../models/configuration';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  configLogLevel: number;
  constructor(private configService: ConfigurationService) {}

  writeToLog(message: string, logLevel: number, ...optionalParams: unknown[]): void {
    this.configLogLevel = this.configService.getLogLevel();
    if (this.configLogLevel <= logLevel) {
      switch (logLevel) {
        case LogLevel.Debug:
          console.debug(message, logLevel, optionalParams);
          break;
        case LogLevel.Info:
          console.info(message, logLevel, optionalParams);
          break;
        case LogLevel.Warn:
          console.warn(message, logLevel, optionalParams);
          break;
        case LogLevel.Error:
          console.error(message, logLevel, optionalParams);
          break;
        default:
          console.log(message, logLevel, optionalParams);
          break;
      }
    }
  }

  debug(msg: string, ...optionalParams: unknown[]): void {
    this.writeToLog(msg, LogLevel.Debug, optionalParams);
  }

  info(msg: string, ...optionalParams: unknown[]): void {
    this.writeToLog(msg, LogLevel.Info, optionalParams);
  }

  warn(msg: string, ...optionalParams: unknown[]): void {
    if (optionalParams[1]) {
      const config = optionalParams[1] as FadConfigurationData;
      if (config?.interfaces?.logging?.logLevel <= LogLevel.Warn) {
        console.warn(msg, LogLevel.Warn, optionalParams[0]);
      }
    } else {this.writeToLog(msg, LogLevel.Warn, optionalParams);}
  }

  error(msg: string, ...optionalParams: unknown[]): void {
    this.writeToLog(msg, LogLevel.Error, optionalParams);
  }

  fatal(msg: string, ...optionalParams: unknown[]): void {
    this.writeToLog(msg, LogLevel.Fatal, optionalParams);
  }

  log(msg: string, ...optionalParams: unknown[]): void {
    this.writeToLog(msg, LogLevel.All, optionalParams);
  }
}
