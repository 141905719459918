<div class="search-quicklinks">
  <div class="fad-suggetion-lead-label">
    <span class="p p2">Or choose from these categories:</span>
  </div>
  <div class="link-list">
    <div class="mobile-row">
      <div *ngFor="let category of quickLinksCategories?.slice(0, 2)" tabindex="0">
        <ng-container *ngTemplateOutlet="quicklinks ; context : {category: category}"></ng-container>
      </div>
    </div>
    <div class="mobile-row">
      <div *ngFor="let category of quickLinksCategories?.slice(2)" tabindex="0">
        <ng-container *ngTemplateOutlet="quicklinks ; context : {category: category}"></ng-container>
      </div>
    </div>
  </div>
</div>
<div class="quicklinks-divider"></div>

<ng-template #quicklinks let-category="category">
  <div class="search-quicklinks-item p p2" [ngClass]='{"search-quicklinks-item-icon": !!category.icon}' (click)="quicklinkClick(category)">
    <cs-svg-icon *ngIf="!!category.icon" [name]="category.icon"></cs-svg-icon>
    <span data-nav-type="find a doctor" data-nav-element="fad search suggestion" data-nav-location="fad search initiation"
      >{{ !!category.alternateText ? category.alternateText : category.value }}</span
    >
  </div>
</ng-template>
