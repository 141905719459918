import { Component, Input, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Location } from '@angular/common';
import { SelectedLocation } from '../../models/location';
import { FiltersModel } from '../../models/filters';
import { Params, Router } from '@angular/router';
import { CookieService } from '../../services/cookie.service';
import { ConfigurationService } from '../../services/configuration.service';
import { FadUrlBuilder } from '../../utils/url/fad-url-builder';
import { UrlBuilderParams } from '../../utils/url/url-builder-params';
import { QuickLinksCategories } from '../../../app/models/configuration';
import { SessionObjectNames } from 'src/app/models/stringconst-names';

@Component({
  selector: 'cs-find-doctor-widget',
  templateUrl: './find-doctor-widget.component.html',
  styleUrls: ['./find-doctor-widget.component.scss']
})
export class FindDoctorWidgetComponent implements OnInit {
  landingPageHeader: string;

  @Input() singleColumn: boolean;
  @Input() configurationUrl = './fad-configuration.json';
  @Input() fadBaseUrl = '';
  @Input() referringUrl = '';
  @Input() showGuidedFlow: string | boolean = false;
  @Input() keyword = '';
  @Input() selectedLocation: SelectedLocation;
  @Input() bookOnline = false;
  @Input() showQuicklinks = true;
  // Would anyone really pass a filters model in the input?
  @Input() filtersModel: FiltersModel;
  searchFieldsGroup: HTMLElement;
  configShowQuickLinks: boolean;
  quickLinksCategories: QuickLinksCategories[] = [];

  @ViewChild('searchInputs') searchInputs: ElementRef;
  @HostListener('window:click', ['$event'])
  onClick(event: MouseEvent) {
    this.searchFieldsGroup = document.querySelector('div.search-fields-group');
    if (this.searchFieldsGroup?.style) {
      if (!this.searchInputs.nativeElement.contains(event.target)) {
        this.searchFieldsGroup.style.boxShadow = 'none';
      } else {
        if (window.innerWidth > 767) {
          this.searchFieldsGroup.style.boxShadow = '0px 2px 8px rgba(77, 82, 90, 0.3)';
        }
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.searchFieldsGroup = document.querySelector('div.search-fields-group');
    if (this.searchFieldsGroup?.style) {
      if (event.target.innerWidth < 768) {
        this.searchFieldsGroup.style.boxShadow = 'none';
      }
    }
  }

  constructor(
    private location: Location,
    private configurationService: ConfigurationService,
    private router: Router,
    private cookieService: CookieService,
    private urlBuilder: FadUrlBuilder
  ) {}

  ngOnInit(): void {
    this.searchFieldsGroup = document.querySelector('div.search-fields-group');
    this.configShowQuickLinks = this.configurationService.showquickLinks();
    this.landingPageHeader = this.configurationService.getLandingPageHeader();

    this.quickLinksCategories = this.configurationService.quickLinksCategories();
  }

  getSearchResults(searchTerm = ''): void {
    window.sessionStorage.removeItem(SessionObjectNames.LocationSessionId);
    this.keyword = searchTerm !== '' ? searchTerm : this.keyword;
    const existingMedGroup = this.filtersModel?.providerMedicalGroup;
    const existingOnlineBooking = this.filtersModel?.onlineSchedulingApplied;
    this.filtersModel = new FiltersModel(this.configurationService);
    this.filtersModel.providerMedicalGroup = this.filtersModel.selectedMedicalGroup = existingMedGroup;
    this.filtersModel.onlineSchedulingApplied = this.filtersModel.onlineSchedulingToggle = existingOnlineBooking;

    if (this.selectedLocation) {
      this.cookieService.addLocationCookieToBrowser(this.selectedLocation);
    }
    let url = '';

    if (this.filtersModel.providerMedicalGroup && this.filtersModel.providerMedicalGroup.length > 0) {
      url = this.urlBuilder.build(
        {
          medGroup: this.filtersModel.providerMedicalGroup.length > 1 ? this.filtersModel.providerMedicalGroup.map(x=>x.name).join('|')
                    : this.filtersModel.providerMedicalGroup[0].name,
          searchTerm: this.keyword,
          selectedLocation: this.selectedLocation
        } as UrlBuilderParams,
        this.fadBaseUrl
      );
    } else {
      url = this.urlBuilder.build(
        {
          medGroup: '',
          searchTerm: this.keyword,
          selectedLocation: this.selectedLocation
        } as UrlBuilderParams,
        this.fadBaseUrl
      );
    }

    const queryParams: Params = {};
    if (this.filtersModel.providerMedicalGroup?.length > 0 && this.selectedLocation?.city && this.selectedLocation?.state) {
      queryParams.location = `${this.selectedLocation.city.toUrlFriendly()}-${this.selectedLocation.state.toUrlFriendly()}`;
    }
    if(this.filtersModel.providerMedicalGroup?.length > 1) {
      queryParams.medgroup =  this.filtersModel.providerMedicalGroup.map(x=>x.name).join('|').toString();
     }
    const queryParamsString = Object.keys(queryParams)
      .map((key) => `${key}=${queryParams[key]}`)
      .join('&');
    this.navigatePage(queryParamsString ? `${url}?${queryParamsString}` : url);
  }

  navigatePage(url: string) {
    window.location.href = url;
  }

  reloadProviderEvent($event) {
    window.location.href = `${this.fadBaseUrl}/${$event.npi}-${$event.firstName.makeURLFriendly()}-${$event.lastName.makeURLFriendly()}`;
  }
}
