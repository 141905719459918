<div class="checkbox">
  <span>
    <div>
      <label class="container">
        <input type="checkbox" [checked]="isProviderRoleTypesSelected" id="urgent-care" (change)="checkProviderRole()" />
        Include hospital-based and urgent care providers
        <span class="checkmark"></span>
      </label>
    </div>
  </span>
</div>
