import { Component, Input } from '@angular/core';
import { Provider } from '../../models/provider';

@Component({
  selector: 'cs-credentials',
  templateUrl: './credentials.component.html',
  styleUrls: ['./credentials.component.scss']
})
export class CredentialsComponent {
  @Input() provider: Provider;
  @Input() educationArray: any[];
  @Input() memberships: string[];
}
