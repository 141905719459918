import { ErrorHandler, Injectable } from '@angular/core';
import { AppInsightsMonitoringService } from './app-insights-monitoring.service';



@Injectable({
providedIn: 'root'
})
export class ErrorHandlerService extends ErrorHandler {
constructor(private appInsightService: AppInsightsMonitoringService) {
super();
}



handleError(error: Error): void {
this.appInsightService.logException(error);
}
}
