<div class="dropdownWithList">
  <div class="dropdown">
    <input
      #searchinput
      maxlength="50"
      [placeholder]="placeholderInput"
      (keypress)="inputSearch()"
      (keyup.backspace)="inputSearch()"
      type="text"
      spellcheck="false"
    />
  </div>
  <ul role="listbox" *ngIf="showDropDown">
    <fieldset>
      <legend><span class="sr-only">{{placeholderInput}}</span></legend>
      <li
        class="toggle-col-option"
        *ngFor="let dropdown of filterDropdownList; let i = index"
        role="option"
        (click)="itemClickHandler(dropdown)"
        [attr.aria-labelledby]="'dropdown-label-' + i"
        tabindex="0"
        (keydown.enter)="itemClickHandler(dropdown)"
        (keydown.space)="itemClickHandler(dropdown)"
      >
        <span class="option-checkbox">
          <svg class="unchecked" *ngIf="!dropdown.selected" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <rect rx="2.5" width="19" height="19" x="0.5" y="0.5" />
          </svg>
          <svg class="checked" *ngIf="dropdown.selected" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <rect rx="3" />
            <path d="M10.0001 15L4.16675 9.16663L5.83341 7.49996L10.0001 11.6666L20.0001 1.66663V4.99996L10.0001 15Z" />
          </svg>
        </span>
        {{ dropdown.name }}&nbsp;<span *ngIf="showCount" class="option-count">({{ dropdown.count }})</span>
      </li>
    </fieldset>
  </ul>
</div>
<div class="dropdown-buttons">
  <div (click)="clearFilters()" class="clear-button-container">
    <button [disabled]="selectedItems.length === 0" class="clear-all-button">Clear</button>
  </div>
  <div (click)="applyFilters()" class="apply-button-container">
    <button class="apply-filter-button">Apply</button>
  </div>
</div>
