export const ApiConstants = {
  providerDetailsApi: 'v2/providers/search/',
  suggestionsBaseApiUrl: 'v2/suggestions/',
  locationDetailsApi: 'v1/location/',
  providerSearchApi: 'v2/providers/search',
  searchWithoutInputApiUrl:
    '&page=1&distance=10&sortby=Default&include=ExtendedData,OnlineBookingSupport,NextAvailableTimes,AvailableTimes&maxAppointments=5&facets=all:0&searchMode=All',
  addPagesinUrl: 'page=',
  addDistanceinUrl: '&distance=',
  addSortbyinUrl: '&sortby=Default',
  addMaxAppointmentsinUrl: 'maxAppointments=5',
  addIncludeOptionsinUrl: '&include=ExtendedData,OnlineBookingSupport,NextAvailableTimes,AvailableTimes&maxAppointments=5',
  addIncludeOptionsinUrlForGuidedFlow: '&include=ExtendedData',
  addFacetsinUrl: '&facets=all:0',
  addsearchModeinUrl: '&searchMode=All',  
  addProviderRoleTypes: '&providerRoleTypes=Urgent Care|Hospital Based|Non-hospital Based'
};
