import { UrlSegment, UrlSegmentGroup } from '@angular/router';
import { SelectedLocation } from '../../models/location';
import { UrlBuilderParams } from './url-builder-params';
import '../string-prototypes';

export abstract class FadUrlBuilderStrategy {
  private segments: UrlSegment[] = [];
  abstract shouldBuild(urlBuilderParams: UrlBuilderParams): boolean;

  abstract createUrlSegments(urlBuilderParams: UrlBuilderParams): void;
   
  addSegments(...newSegments: string[]): void {
    const items: UrlSegment[] = newSegments.map((s) => new UrlSegment(s.toUrlFriendly().trimTrailingForwardSlash(), {}));
    this.segments.push(...items);
  }

  addCityAndStateSegment(selectedLocation: SelectedLocation): void {
    if (selectedLocation.city && selectedLocation.state) {
      this.addSegments(
        `${selectedLocation.neighborhood ? selectedLocation.neighborhood.toLowerCase().toUrlFriendly() + '-' : ''}${selectedLocation.city
          .toLowerCase()
          .toUrlFriendly()}-${selectedLocation.state.toLowerCase()}${selectedLocation.zip ? '-' + selectedLocation.zip.toLowerCase() : ''}`
      );
    } else if (selectedLocation.name) {
      this.addSegments(selectedLocation.name.toLowerCase().replace(/,/g, '').toUrlFriendly());
    }
  }

  addSpecialtySegment(selectedSpeciality: string): void {
    const urlSegment = new UrlSegment(
      selectedSpeciality     
        .replace(/[^a-zA-Z0-9 &/-]/g, '') // ,:  () '=> replaced to '' whereas & - and / are retained in the url
        .replace(/[ /]/g, '-') // space and / in the string => replaced to -
        .trimTrailingForwardSlash(),
      {}
    );
    this.segments.push(urlSegment);
  }

  build(urlBuilderParams: UrlBuilderParams, baseUrl: string = null): string {
    this.segments = [];

    this.createUrlSegments(urlBuilderParams);
    const url: string = new UrlSegmentGroup(this.segments, {}).toString().trimTrailingForwardSlash();
    if (baseUrl) {
      baseUrl = baseUrl.trimTrailingForwardSlash();
      // url already starts with a forward slash.
      return `${baseUrl}${url}`;
    }
    return url;
  }

  hasLocationData(urlBuilderParams: UrlBuilderParams): boolean {
    return (
      (!!urlBuilderParams.selectedLocation?.city && !!urlBuilderParams.selectedLocation?.state) || !!urlBuilderParams.selectedLocation?.name
    );
  }
}
