export enum FadEventTypes {
  RouteChanged = 'CommonSpirit.FindADoctor.RouteChanged',
  SearchResultsLoaded = 'CommonSpirit.FindADoctor.SearchResultsLoaded',
  ProviderLoaded = 'CommonSpirit.FindADoctor.ProviderLoaded',
  NavigateToSchedulingOnCerner = 'CommonSpirit.FindADoctor.NavigateToSchedulingOnCerner',
  ViewVideoClicked = 'CommonSpirit.FindADoctor.SearchResults.ViewVideoClicked',
  PostOidcTokentoMyAppointment_Cerner='CommonSpirit.MyAppointment.PostToken',
  PostApptDetailstoMyAppointment_Cerner='CommonSpirit.MyAppointment.ApptDetails',
  PostFADSearchResults_Cerner='CommonSpirit.MyAppointment.RedirectToFAD'
}
