import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConstants } from '../../constants/api-constants.legacy';
import { KeywordSuggestionResponse, Provider, ProviderSuggestionResponse, ProviderSuggestionV2Response } from '../../models/provider';
import { ApiResponse } from '../../utils/api-response';
import { RuntimeConfiguration } from '../configuration-injection.service';
import { ConfigurationService } from '../configuration.service';
import { LocationSearchRequest, ProviderSearchResponse } from '../filter.service';
import { ProviderService } from '../provider.service';

@Injectable({
  providedIn: 'root'
})
export class ProviderLegacyService extends ProviderService {
  apiOptions: { headers: HttpHeaders };
  runtimeConfig: RuntimeConfiguration;
  public apiConstants: { [key: string]: string };
  public apiBaseUrl: string;

  constructor(public http: HttpClient, public configurationService: ConfigurationService) {
    super();
    this.runtimeConfig = this.configurationService.getRuntimeConfiguration();
    this.apiBaseUrl = this.runtimeConfig.apiBaseUrl;
    this.apiConstants = ApiConstants;
    this.apiOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-tenant': this.configurationService.getTenantCode(),
        'ocp-apim-subscription-key': this.runtimeConfig.apimSubscriptionKey
      })
    };
  }

  protected addRequiredProviderHttpParams(httpParams?: HttpParams): HttpParams {
    if (!httpParams) {
      httpParams = new HttpParams();
    }
    const divisionCodes = this.configurationService.getDivisionCodes();
    if (divisionCodes.length > 0) {
      httpParams = httpParams.setPipeDelimitedArray('divisionCodes', divisionCodes);
      const marketCodes = this.configurationService.getMarketCodes();
      if (marketCodes) {
        httpParams = httpParams.setPipeDelimitedArray('marketCodes', marketCodes);
      }
    }
    return httpParams;
  }

  getProviderDetailsFull(npi: number | string, page = 1, pageSize = 20): Observable<ApiResponse<ProviderSearchResponse>> {
    npi = npi.toString().slice(0, 10);

    let httpParams = this.addRequiredProviderHttpParams();
    httpParams = httpParams.setNonString('page', page);
    httpParams = httpParams.setNonString('pageSize', pageSize);
    httpParams = httpParams.set('facets', 'All:0');
    httpParams = httpParams.set('include', 'metadata,extendeddata');
    httpParams = httpParams.set('npis', npi);

    const PROVIDER_DETAILS_BASE_API = `${this.apiBaseUrl}${this.apiConstants.providerDetailsApi}`;

    return this.http.get<ApiResponse<ProviderSearchResponse>>(PROVIDER_DETAILS_BASE_API, {
      headers: this.apiOptions.headers,
      params: httpParams
    });
  }

  getTypedProviderSuggestions(query: string, suggestionType: string): Observable<ApiResponse<ProviderSuggestionResponse>> {
    const url = `${this.apiBaseUrl}${this.apiConstants.suggestionsBaseApiUrl}${encodeURIComponent(query)}/providers`;
    let httpParams = this.addRequiredProviderHttpParams();
    httpParams = httpParams.append('mode', 'Auto');
    if (suggestionType === 'provider')
      {httpParams = httpParams.append('fields', 'primarySpecialty,procedures,conditions,insuranceCarriers,fullName');}
    if (suggestionType === 'specialty') {httpParams = httpParams.append('fields', 'specialties');}
    return this.http.get<ApiResponse<ProviderSuggestionResponse>>(url.toString(), {
      headers: this.apiOptions.headers,
      params: httpParams
    });
  }
  
  getTypedProviderSuggestionsV2(query: string): Observable<ApiResponse<ProviderSuggestionV2Response>> {
    const url = `${this.apiBaseUrl}${this.apiConstants.suggestionsBaseApiUrl}${encodeURIComponent(query)}/providers`;
    let httpParams = this.addRequiredProviderHttpParams();
    httpParams = httpParams.append('mode', 'Auto');
    httpParams = httpParams.append('fields', 'specialties');
    return this.http.get<ApiResponse<ProviderSuggestionV2Response>>(url.toString(), {
      headers: this.apiOptions.headers,
      params: httpParams
    });
  }

  getTypedKeywordSuggestions(query: string): Observable<ApiResponse<KeywordSuggestionResponse>> {
    const url = `${this.apiBaseUrl}${this.apiConstants.suggestionsBaseApiUrl}${encodeURIComponent(query)}/autocomplete`;
    let httpParams = this.addRequiredProviderHttpParams();
    httpParams = httpParams.append('mode', 'Auto');
    httpParams = httpParams.append('fields', 'procedures,conditions,insuranceCarriers');

    return this.http.get<ApiResponse<KeywordSuggestionResponse>>(url.toString(), {
      headers: this.apiOptions.headers,
      params: httpParams
    });
  }

  getTypedFilterSearchResults<T>(url: string, params: HttpParams): Observable<T> {
    params = this.addRequiredProviderHttpParams(params);

    return this.http.get<T>(url, {
      headers: this.apiOptions.headers,
      params
    });
  }

  getFilteredSearchResults(url: string, params?: HttpParams) {
    return this.http.get(url, {
      headers: this.apiOptions.headers,
      params
    });
  }

  getDocAsapIds() {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.get(this.configurationService.getDocAsapProviderLookupUrl(), options);
  }

  getLocationDetailsbyLocationId(request: LocationSearchRequest): Observable<ApiResponse<ProviderSearchResponse>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('page', request.page);
    httpParams = httpParams.append('include', 'ExtendedData,OnlineBookingSupport,NextAvailableTimes,AvailableTimes');
    httpParams = httpParams.append('maxAppointments', '5');
    httpParams = httpParams.append('facets', 'all:0');
    httpParams = httpParams.append('searchMode', 'All');
    httpParams = httpParams.append('sortby', request.sortby);
    httpParams = httpParams.append('distance', '10');
    httpParams = httpParams.append('locationIds', request.locationId);
    httpParams = httpParams.append('searchSessionId', request.searchSessionId);
    if (request.includeProviderRoleType) {
      httpParams = httpParams.setKeyValue(this.apiConstants.addProviderRoleTypes);
    }
    if (request.gender) {
      httpParams = httpParams.append('gender', request.gender);
    }
    if (request.patients === 'true') {
      httpParams = httpParams.append('patients', request.patients);
    }
    if (request.languages) {
      httpParams = httpParams.append('languages', request.languages);
    }
    if (request.specialties) {
      httpParams = httpParams.append('specialties', request.specialties);
    }
    if (request.medGroups && request.medGroups !== 'null') {
      httpParams = httpParams.append('medgroup', request.medGroups);
    }
    if (request.bookOnline === 'true') {
      httpParams = httpParams.append('bookOnline', request.bookOnline);
    }
    if (request.videoCalls === 'true') {
      httpParams = httpParams.append('videoCalls', request.videoCalls);
    }
    httpParams = this.addRequiredProviderHttpParams(httpParams);

    const PROVIDER_DETAILS_BASE_API = `${this.apiBaseUrl}${this.apiConstants.providerSearchApi}`;

    return this.http.get<ApiResponse<ProviderSearchResponse>>(PROVIDER_DETAILS_BASE_API, {
      headers: this.apiOptions.headers,
      params: httpParams
    });
  }
  normalizeProviderBeforeScheduling(provider: Provider): Provider {
    return provider;
  }
}
