import { Component, Input, ViewEncapsulation, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { Provider } from '../../models/provider';

@Component({
  selector: 'cs-provider-video',
  templateUrl: './provider-video.component.html',
  styleUrls: ['./provider-video.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProviderVideoComponent {
  @Input() provider: Provider;
  @Input() videoId: string;
  @Output() callAdobeService = new EventEmitter<Provider>();

  onStateChange(provider) {
    this.callAdobeService.emit(provider);
  }
}
