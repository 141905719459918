<div class="provider-stat fad-col-sm-12 provider-ownwords" *ngIf="ownWords">
  <h3>
    <span *ngIf="inMyOwnWordsTitle">{{ inMyOwnWordsTitle }}</span>
    <div class="ownwords-content" [ngClass]="{ 'show-less-content': hideExtraContent }">
      <p [innerHTML]="ownWords"></p>
      <p></p>
    </div>
  </h3>
  <div class="show-more-less" *ngIf="showMoreLessButton">
    <a href="javascript:;" (click)="hideExtraContent = !hideExtraContent; moreLessExpanded = !moreLessExpanded">
      {{ hideExtraContent ? 'More' : 'Less' }}
      <svg class="more-arrow" [ngClass]="{ 'less-arrow': !hideExtraContent }" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 1L10.5067 10.4933L1.01426 1" />
      </svg>
    </a>
  </div>
</div>
