/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
/* eslint-disable @typescript-eslint/no-empty-function */
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { LogoDetails } from '../../models/configuration';
import { OfficeAddress } from '../../models/office-address';
import { Provider } from '../../models/provider';
import { ProviderImage } from '../../models/provider-image';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Component({
  selector: 'cs-print-page',
  templateUrl: './print-page.component.html',
  styleUrls: ['./print-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrintPageComponent implements OnInit {
  @Input() provider: Provider;
  @Input() providerImage: ProviderImage;
  @Input() logoUrls: LogoDetails[] = [];
  @Input() providerSpecialities: string[];
  @Input() divisionCodes: string[];
  @Input() officeAddresses: OfficeAddress[] = [];
  @Input() centerPoint: OfficeAddress;
  @Input() getToKnowContent: any;
  @Input() nonSEOSecondarySpecialities: string[];
  @Input() malePlaceholder: string;
  @Input() femalePlaceholder: string;
  footer = '';
  headerImageUrl = '';
  
  getAltImageSrc(event: any) {
    event.target.src =
      this.provider.gender?.toLowerCase() === 'male'
        ? this.malePlaceholder
        : this.provider.gender?.toLowerCase() === 'female'
        ? this.femalePlaceholder
        : this.configurationService.blankPlaceholder;
  }

  constructor(private configurationService: ConfigurationService ) {
  }

  ngOnInit(): void {
    this.footer = this.configurationService.getPrintablePdfFooter();
    this.headerImageUrl = this.configurationService.getPrintablePdfHeaderImageUrl();
    console.log(this.headerImageUrl);
  }
}
