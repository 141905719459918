import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AccordionModule } from 'src/app/components/accordion/accordion.module';
import { AvailabilityDialogComponent } from 'src/app/components/availability-dialog/availability-dialog.component';
import { BadgesModule } from 'src/app/components/badges/badges.module';
import { CommonMoreFiltersComponent } from 'src/app/components/common-more-filters/common-more-filters.component';
import { CtaNewSearchComponent } from 'src/app/components/cta-new-search/cta-new-search.component';
import { DynamicFilterContentComponent } from 'src/app/components/dynamic-filter-content/dynamic-filter-content.component';
import { FilterDialogModule } from 'src/app/components/filter-dialog/filter-dialog.module';
import { FiltersComponent } from 'src/app/components/filters/filters.component';
import { MedicalCopyComponent } from 'src/app/components/medical-copy/medical-copy.component';
import { MobileFiltersComponent } from 'src/app/components/mobile-filters/mobile-filters.component';
import { ResultCardModule } from 'src/app/components/result-card/result-card.module';
import { SharedModule } from 'src/app/components/shared.module';
import { AngSlideToggleModule } from 'src/app/components/slide-toggle/ang-slide-toggle.module';
import { SearchResultsComponent } from './search-results.component';
import { MultiSelectSearchableDropDownModule } from 'src/app/components/common/multiple-checkbox-searchable-dropdown/multiple-checkbox-searchable-dropdown.module';
import { FiltersLeftPanelComponent } from 'src/app/components//filters-left-panel/filters-left-panel.component';

@NgModule({
  declarations: [
    SearchResultsComponent,
    FiltersComponent,
    CtaNewSearchComponent,
    MedicalCopyComponent,
    DynamicFilterContentComponent,
    MobileFiltersComponent,
    AvailabilityDialogComponent,
    CommonMoreFiltersComponent,
    FiltersLeftPanelComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    AngSlideToggleModule,
    FilterDialogModule,
    AccordionModule,
    BadgesModule,
    ResultCardModule,
    MultiSelectSearchableDropDownModule,
    RouterModule.forChild([
      {
        path: '',
        component: SearchResultsComponent,
        data: {
          storageKey: 'SearchResultsComponent'
        }
      }
    ])
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SearchResultsModule {}
