import { Injectable } from '@angular/core';
import { DefaultUrlSerializer, UrlTree } from '@angular/router';
@Injectable()
export class OverrideUrlSerializer extends DefaultUrlSerializer {
  serialize(tree: UrlTree): string {
    if (tree.toString() === '/') {
      return '';
    }
    return super.serialize(tree);
  }
}
