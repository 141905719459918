<div class="covid-vaccine-header">
  <div class="covid-vaccine-header-content">
    <img [src]="imgUrl" alt="" class="dh-logo" />
  </div>
</div>

<div class="covid-vaccine-body">
  <div class="covid-vaccine-body-container">
    <div class="covid-vaccine-body-content">
      <div class="covid-vaccine-body-content-left booking-info-container">
        <h1 *ngIf="authCallsFinished && locations?.length > 0" class="heading-text large">Schedule your <br />COVID-19 vaccine</h1>
        <h1 *ngIf="authCallsFinished && !isMyTurnPatient && showInvitationOnlyMessage" class="heading-text large">
          Schedule your <br />COVID-19 vaccine
        </h1>
        <h1
          *ngIf="authCallsFinished && !isMyTurnPatient && !showInvitationOnlyMessage && locations?.length === 0"
          class="heading-text large fs-70"
        >
          There are no appointments at this location
        </h1>
        <h1 *ngIf="authCallsFinished && isMyTurnPatient && locations?.length === 0" class="heading-text large">
          There are no appointments at this location
        </h1>
        <div *ngIf="authCallsFinished">
          <div *ngIf="locations?.length > 0 && !showInvitationOnlyMessage">
            <p class="copy">You are eligible to receive the COVID-19 vaccine! Choose a location below to schedule your vaccine.</p>
          </div>
          <div class="no-appointments-container" *ngIf="locations?.length === 0 && !showInvitationOnlyMessage && !isMyTurnPatient">
            <h2>We’re sorry! We will contact you when we receive more vaccines.</h2>
            <div class="copy">
              <p>As soon as more supplies are made available to us, we will add more appointment times. We appreciate your patience.</p>
              <p>
                We are working closely with the local health department and with community partners to urgently secure additional
                vaccination allocations so that we can offer vaccination to all of our patients.
              </p>
            </div>
          </div>
          <div class="invitation-only-container" *ngIf="showInvitationOnlyMessage && !isMyTurnPatient">
            <h2>Currently, COVID-19 vaccine scheduling is by invitation only</h2>
            <div class="copy">
              <p>
                Distribution of the vaccine is determined by the local county and state health department. We are participating in the
                administration of COVID-19 vaccines according to that distribution plan.
              </p>
              <p>
                Invitations to vaccinations are limited for now to those individuals in the currently qualifying categories. As soon as more
                supplies are made available to us, we will add more appointment times. We appreciate your patience. We are working closely
                with the local health department and with community partners to urgently secure additional vaccination allocations so that
                we can offer vaccination to all of our patients.
              </p>
            </div>
          </div>
          <div class="invitation-only-container" *ngIf="isMyTurnPatient && locations?.length === 0">
            <h2>As soon as more vaccine supply becomes available, we will add appointment times. Thank you for your patience.</h2>
            <div class="copy">
              <p>Please check <a href="https://myturn.ca.gov">myturn.ca.gov</a> for appointments in other locations.</p>
              <p>
                <button dhclButton buttonType="primary" buttonSize="small" (click)="returnToMyTurn()">Return to My Turn</button>
              </p>
            </div>
          </div>
          <div class="covid-vacination-cards">
            <cs-covid-vaccination-location-card
              *ngFor="let location of locations"
              [location]="location"
              class="result-card"
              (bookAppointment)="updateLocation($event)"
            ></cs-covid-vaccination-location-card>
          </div>
        </div>
      </div>

      <div class="covid-vaccine-body-content-right">
        <div class="shadows-box-container">
          <div class="text-section">
            <div class="text-heading">Need help?</div>
            <div class="text-content">
              If you are encountering technical issues, please call one of our representatives at
              <span>
                <a class="phone-number phone" href="tel:{{ phoneNo }}">{{ phoneNo }}</a>
              </span>
              .
            </div>
          </div>
          <div class="text-section">
            <div class="text-heading">Learn more</div>
            <div class="text-content">
              For additional information on the COVID-19 and the vaccine approval process, we recommend reviewing the FAQs on the
              <a href="https://www.cdc.gov/coronavirus/2019-ncov/vaccines/faq.html" target="_blank">CDC website</a> and
              <a href="https://www.fda.gov/vaccines-blood-biologics/vaccines/emergency-use-authorization-vaccines-explained" target="_blank"
                >FDA website.</a
              >
            </div>
          </div>
          <div class="text-section">
            <div class="text-heading">Helpful articles</div>
            <div class="text-content">
              <a href="https://dhmgblog.dignityhealth.org/covid-19-vaccine-myths-vs-facts" target="_blank"
                >COVID-19 Vaccine Myths vs Facts</a
              >
            </div>
          </div>
          <div class="text-section">
            <div class="text-heading">Our commitment to equitable vaccine distribution</div>
            <div class="text-content">
              We are committed to distributing vaccines equitably and quickly in accordance with guidelines from health agencies. We hope to
              be able to offer the vaccine to all our patients as soon as possible within those guidelines. We expect this to be possible as
              the vaccine supply increases. Thank you for your understanding and patience.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<dhcl-modal
  *ngIf="showModal"
  [alignTop]="true"
  [customClass]="'guided-flow-modal'"
  [closeOnOverlay]="false"
  [show]="true"
  (closeClicked)="showModal = false"
>
  <dhgf-guided-flow
    *ngIf="runtimeConfig.envName"
    [environment]="runtimeConfig.envName"
    [showNewSchedulingApp]="true"
    [isCaptchaEnabled]="isCaptchaEnabled"
    [isLocationAppt]="true"
    [locationIds]="apptTypes"
    [appointmentTypes]="locationIds"
    [mrn]="mrn"
    [v5Provider]="v5Provider"
    [providersSearchApimSubscriptionKey]="runtimeConfig.apimSubscriptionKey"
    [patientDetail]="covidPatientInfo"
    [isBookAgain]="isBookAgain"
    [selectedAppointmentSchedule]="selectedAppointmentSchedule"
    [showProviderPrefix]="showProviderPrefix"
    (closeClicked)="showModal = false"
    [useLegacyAPI]="useLegacyAPI"
  ></dhgf-guided-flow>
</dhcl-modal>
<dhcl-loading-spinner [showSpinner]="showSpinner"></dhcl-loading-spinner>
