import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { KeywordSuggestionResponse, Provider, ProviderSuggestionResponse, ProviderSuggestionV2Response } from '../models/provider';
import { ApiResponse } from '../utils/api-response';
import '../utils/http-params-prototypes';
import { LocationSearchRequest, ProviderSearchResponse } from './filter.service';
@Injectable({
  providedIn: 'root'
})
export abstract class ProviderService {
  abstract getProviderDetailsFull(npi: number | string, page?: number, pageSize?: number): Observable<ApiResponse<ProviderSearchResponse>>;

  abstract getTypedProviderSuggestions(query: string, suggestionType: string): Observable<ApiResponse<ProviderSuggestionResponse>>;

  abstract getTypedProviderSuggestionsV2(query: string): Observable<ApiResponse<ProviderSuggestionV2Response>>;

  abstract getTypedKeywordSuggestions(query: string): Observable<ApiResponse<KeywordSuggestionResponse>>;

  abstract getTypedFilterSearchResults<T>(url: string, params: HttpParams): Observable<T>;

  abstract getFilteredSearchResults(url: string, params?: HttpParams);

  abstract getDocAsapIds();

  abstract getLocationDetailsbyLocationId<T>(request: LocationSearchRequest): Observable<ApiResponse<ProviderSearchResponse>>;

  abstract normalizeProviderBeforeScheduling(provider: Provider): Provider;
}
