import { Injectable } from '@angular/core';
import { Provider } from '../models/provider';
import { CjaTagSearchFilter } from '../models/search-filter';
import { ConfigurationService } from './configuration.service';
import { RuntimeConfiguration } from './configuration-injection.service';
 
@Injectable({
  providedIn: 'root'
})
export class CjaTagService {
  tenantCode: string;
 
  constructor(private configurationService: ConfigurationService) { }

  init(runtimeConfig: RuntimeConfiguration): void {
    this.tenantCode = this.configurationService.getTenantCode();
    this.addScriptTag(runtimeConfig);
  }
 
  public logCJAEvent(data?: any): void {
    window.adobeDataLayer = window.adobeDataLayer || [];
    window.adobeDataLayer.push(data);
  }
 
  private view(provider: Provider, eventName: string) {
    this.logCJAEvent({
      event: 'cmp:show',
      event_name: eventName,
      page: {
        name: `${provider.displayFullName}`,
        section: 'Search and Schedule',
        system: `${this.configurationService.getTenantCode()}`,
        division: `${provider.divisionCodes[0]}`,
        facility: `${provider.officeNames}`,
        medicalGroup: `${provider.medicalGroupNames.join(', ')}`,
        serviceLine: `${provider.primarySpecialty}`,
        market: `${provider.marketCodes[0]}`,
        doctor_name: `${provider.displayFullName}`,
        doctor_NPI: `${provider.npi}`
      },
      site: {
        language: `${provider.languages}`
      },
      visitor: {
        loginStatus: ''
      },
      patientEngagement: 'false'
    });
  }
 
  providerProfileViewed(provider: Provider): void {
    this.view(provider, 'search_schedule:provider_profile_view');
  }
 
  providerVideoViewed(provider: Provider): void {
    this.view(provider, 'search_schedule:youtube_open');
  }
 
  searchResults(cjaTagSearchFilter: CjaTagSearchFilter): void {
    this.logCJAEvent({
      event: 'cmp:show',
      event_name: `${cjaTagSearchFilter.eventName}`,
      page: {
        name: '',
        section: 'Search and Schedule',
        system: `${this.configurationService.getTenantCode()}`,
        division: '',
        facility: `${cjaTagSearchFilter.facility}`,
        medicalGroup: `${cjaTagSearchFilter.medicalGroup}`,
        serviceLine: `${cjaTagSearchFilter.serviceLine}`,
        market: '',
        subMarket: '',
        url: `${cjaTagSearchFilter.searchURL}`
      },
      site: {
        language: 'en'
      },
      visitor: {
        loginStatus: ''
      },
      search: {
        schedule_filter_details: `${cjaTagSearchFilter.scheduleFilterDetails}`,
        schedule_location: `${cjaTagSearchFilter.scheduleLocation}`,
        schedule_book_online: `${cjaTagSearchFilter.scheduleBookOnline}`,
        schedule_keyword: `${cjaTagSearchFilter.scheduleKeyword}`,
        schedule_results_count: `${cjaTagSearchFilter.totalResultsCount}`
      },
      patientEngagement: 'false'
    });
  }
 
  providersImpressions(providers: Provider[]) {
    const data = {
      event: 'customEvent',
      event_name: 'search and schedule:provider detail:impressions',
      event_category: 'search and schedule',
      event_action: 'providers impression',
      event_label: 'rating and comments',
      doctor_name_list: providers.map((p) => p.displayFullName),
      doctor_npi_list: providers.map((p) => p.npi),
      doctor_starRating_list: providers.map((p) => (p.rating !== undefined ? p.rating?.overallRating.value : 'not set')),
      doctor_ratingCount_list: providers.map((p) => (p.rating !== undefined ? p.rating?.totalRatingCount : 'not set')),
      doctor_commentsCount_list: providers.map((p) => (p.rating !== undefined ? p.rating?.totalCommentCount : 'not set')),
      patientEngagement: 'false',
      visitor_loginStatus: ''
    };
    this.logCJAEvent(data);
  }
 
  providerRatingsAndComments(provider: Provider): void {
    const data = {
      event: 'customEvent',
      event_name: 'search and schedule:provider detail:profile view',
      event_category: 'search and schedule',
      event_action: 'provider profile view',
      event_label: 'rating and comments',
      event_doctor_name: `${provider.displayFullName}`,
      event_doctor_npi: `${provider.npi}`,
      event_doctor_hasStarRating: `${provider.rating?.totalRatingCount === 0 ? false : true}`,
      event_doctor_starRating: `${provider.rating !== undefined ? provider.rating?.overallRating.value : 'not set'}`,
      event_doctor_starRatingCount: `${provider.rating !== undefined ? provider.rating?.totalRatingCount : 'not set'}`,
      event_doctor_hasComments: `${provider.rating?.totalCommentCount === 0 ? false : true}`,
      event_doctor_commentsCount: `${provider.rating !== undefined ? provider.rating?.totalCommentCount : 'not set'}`,
      patientEngagement: 'false',
      visitor_loginStatus: ''
    };
    this.logCJAEvent(data);
  }

  scriptLoaded(adobeScriptSrc: string): boolean {
    return Array.from(document.getElementsByTagName('script')).some((script) => script.src.includes(adobeScriptSrc));
  }

  public addScriptTag(runtimeConfig: RuntimeConfiguration): void {
    if (!runtimeConfig.adobeScriptSrc || this.scriptLoaded(runtimeConfig.adobeScriptSrc)) {
      return;
    }
    const bodyElement = document.querySelector('body');
    const scriptTag: HTMLScriptElement = document.createElement('script');
    scriptTag.type = 'text/javascript';
    scriptTag.innerHTML = `(function(a,b,c,d){
      a='${runtimeConfig.adobeScriptSrc}';
      b=document;c='script';d=b.createElement(c);d.src=a;d.type='text/java'+c;d.async=true;
      a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a);
    })();`;
    bodyElement?.appendChild(scriptTag);
  }
}
