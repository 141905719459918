import { AvailabilitySelect } from './availability-select.enum';
import { MedGroup } from './medgroup';
import { ConfigurationService } from '../services/configuration.service';
import { SelectedLocation } from './location';
import { MedgroupDictionary } from './configuration';

export interface MedicalGroup {
  label: string;
  value: string;
  url: string;
  enableProviderRoleTypes?: boolean;
}

export interface FacetSummary {
  name: string;
  count: number;
  selected: boolean;
  code?: string;
}

export class FiltersModel {
  constructor(configurationService: ConfigurationService) {
    // Currently, we are storing the 0/20/40/60/80/100 in the FiltersModel which corresponds to the values in the slider control.
    this.providerSearchRadius = this.searchRadius = FiltersModelPartialHelper.getRadiusKeyForDistance(
      configurationService.getDefaultSearchDistance()
    );
    this.medicalGroupList = FiltersModelPartialHelper.getMedGroups(configurationService.getMedGroupCodes());
    this.medicalGroupList.sort((prev, next) => prev.label.localeCompare(next.label));
    this.medicalGroupDict = configurationService.getMedGroupCodes();
    this.selectedMedicalGroup = [];
    this.providerMedicalGroup = [];
  }

  isMappingNeeded(): boolean {
    return this.medicalGroupDict.filter((s: MedGroup) => (
        s.code !== s.title
      )).length > 0;
  }

  onlineSchedulingToggle = false;
  onlineSchedulingApplied = false;
  onlineSchedulingProvidersCount = 0;

  onlineSchedulingAllVisitToggle = false;
  onlineSchedulingAllVisitApplied = false;
  onlineSchedulingAllVisitProvidersCount = 0;

  videoVisitsToggle = false;
  videoVisitsApplied = false;
  videoVisitsProvidersCount = 0;

  newPatientsToggle = false;
  newPatientsApplied = false;
  newPatientsProvidersCount = 0;

  availabilityTitle = 'Availability';
  availabilitySelect: AvailabilitySelect = AvailabilitySelect.None;
  providerAvailability: AvailabilitySelect = AvailabilitySelect.None;
  todayCount = 0;
  next7Count = 0;
  next14Count = 0;
  next30Count = 0;

  specialtyFilterText = 'Specialty';
  selectedSpecialties: FacetSummary[] = [];
  providerSpecialties: FacetSummary[] = [];
  availableSpecialties: FacetSummary[] = [];

  hospitalFilterText = 'Hospital Affiliation';
  selectedHospitals: FacetSummary[] = [];
  providerHospitals: FacetSummary[] = [];
  availableHospitals: FacetSummary[] = [];

  medicalGroupTitle = 'Medical group';
  providerMedicalGroup: FacetSummary[] = [];
  selectedMedicalGroup: FacetSummary[] = [];
  medicalGroupListItems: MedicalGroup[] = [];
  medicalGroupList: MedicalGroup[] = [];
  medicalGroupDict: MedgroupDictionary[] = [];

  genderSelect = '';
  providerGender = '';
  femaleCount = 0;
  maleCount = 0;
  nonbinaryCount = 0;
  otherCount = 0;
  locationName = '';

  insuranceFilterText = 'Insurances accepted';
  selectedInsurances: FacetSummary[] = [];
  providerInsurances: FacetSummary[] = [];
  availableInsurances: FacetSummary[] = [];

  selectedLanguages: FacetSummary[] = [];
  providerLanguages: FacetSummary[] = [];
  availableLanguages: FacetSummary[] = [];

  searchRadius: number;
  providerSearchRadius: number;

  pageNumber: number;
  includeProviderRoleType?: boolean;

  searchDone = false;
  sortBy: string;
  sortByNumber: number;
}

export class FiltersModelPartialHelper {
  static radiusValueMapping = [
    {
      key: 0,
      value: 1
    },
    {
      key: 20,
      value: 5
    },
    {
      key: 40,
      value: 10
    },
    {
      key: 60,
      value: 25
    },
    {
      key: 80,
      value: 50
    },
    {
      key: 100,
      value: 100
    }
  ];
  public static hasRadiusKeyForDistance(distance: number): boolean {
    return FiltersModelPartialHelper.radiusValueMapping.some((m) => m.value === distance);
  }

  public static getRadiusKeyForDistance(distance: number) {
    return FiltersModelPartialHelper.radiusValueMapping.find((m) => m.value === distance)?.key;
  }

  public static setRadiusKeyForDistance(filtersModel: Partial<FiltersModel>, distance: number) {
    filtersModel.searchRadius = filtersModel.providerSearchRadius = this.getRadiusKeyForDistance(distance);
  }

  public static getDistanceForProviderSearchRadiusKey(filtersModel: Partial<FiltersModel>): number | undefined {
    return FiltersModelPartialHelper.radiusValueMapping.find((m) => m.key === filtersModel.providerSearchRadius)?.value;
  }

  public static getDistanceForSearchRadiusKey(filtersModel: Partial<FiltersModel>): number | undefined {
    return FiltersModelPartialHelper.radiusValueMapping.find((m) => m.key === filtersModel.searchRadius)?.value;
  }

  public static isDesktopFiltersSelected(
    filtersModel: Partial<FiltersModel>,
    selectedLocation: SelectedLocation,
    defaultSearchDistance: number,
    defaultSearchDistanceWithMedGroup: number,
    showSearchRadiusFilter: boolean,
    showInsuranceFilter: boolean
  ) {
    return (
      filtersModel.providerGender !== '' ||
      (showInsuranceFilter && filtersModel.providerInsurances && filtersModel.providerInsurances.length > 0) ||
      (filtersModel.providerLanguages && filtersModel.providerLanguages.length > 0) ||
      (showSearchRadiusFilter &&
        selectedLocation &&
        filtersModel.providerSearchRadius !== this.getRadiusKeyForDistance(defaultSearchDistance) &&
        (filtersModel.providerMedicalGroup.length === 0)) ||
      (showSearchRadiusFilter &&
        selectedLocation &&
        filtersModel.providerSearchRadius !== this.getRadiusKeyForDistance(defaultSearchDistanceWithMedGroup) &&
        filtersModel.providerMedicalGroup.length > 0)
    );
  }

  public static isMoreFiltersSelected(
    filtersModel: Partial<FiltersModel>,
    selectedLocation: SelectedLocation,
    defaultSearchDistance: number,
    defaultSearchDistanceWithMedGroup: number,
    showSearchRadiusFilter: boolean,
    showInsuranceFilter: boolean,
    showMedicalGroupFilter: boolean
  ) {
    return (
      filtersModel.genderSelect !== '' ||
      (showInsuranceFilter && filtersModel.selectedInsurances && filtersModel.selectedInsurances.length > 0) ||
      (showMedicalGroupFilter && filtersModel.selectedMedicalGroup && filtersModel.selectedMedicalGroup.length > 0) ||
      (filtersModel.selectedLanguages && filtersModel.selectedLanguages.length > 0) ||
      (showSearchRadiusFilter &&
        selectedLocation &&
        filtersModel.searchRadius !== FiltersModelPartialHelper.getRadiusKeyForDistance(defaultSearchDistance) &&
        (filtersModel.selectedMedicalGroup.length === 0)) ||
      (showSearchRadiusFilter &&
        selectedLocation &&
        filtersModel.searchRadius !== FiltersModelPartialHelper.getRadiusKeyForDistance(defaultSearchDistanceWithMedGroup) &&
        filtersModel.selectedMedicalGroup.length > 0)
    );
  }

  public static isMoreFilters(
    filtersModel: Partial<FiltersModel>,
    selectedLocation: SelectedLocation,
    defaultSearchDistance: number,
    showSearchRadiusFilter: boolean,
    showInsuranceFilter: boolean
  ){
    return(
      filtersModel.genderSelect !== '' ||
      (showInsuranceFilter && filtersModel.selectedInsurances && filtersModel.selectedInsurances.length > 0) ||
      (filtersModel.selectedLanguages && filtersModel.selectedLanguages.length > 0) ||
      (showSearchRadiusFilter &&
        selectedLocation &&
        filtersModel.searchRadius !== FiltersModelPartialHelper.getRadiusKeyForDistance(defaultSearchDistance))
      );
  }

  public static toClearFilters(filtersModel: Partial<FiltersModel>, isEvent: boolean, searchDistance: number = null, isMobileView = false, isDignityHealthDivision = false) {
    filtersModel.genderSelect = '';
    filtersModel.selectedInsurances = !isDignityHealthDivision?[]:filtersModel.selectedInsurances;
    filtersModel.selectedLanguages = [];
    filtersModel.selectedMedicalGroup = isDignityHealthDivision?[]:filtersModel.selectedMedicalGroup;
    if (isMobileView) {
      filtersModel.selectedHospitals = [];
      filtersModel.selectedMedicalGroup = [];
    }

    if (isEvent) {
      filtersModel.searchRadius = searchDistance;

      filtersModel.providerGender = filtersModel.genderSelect;
      filtersModel.providerInsurances = [...filtersModel.selectedInsurances];
      filtersModel.providerLanguages = [...filtersModel.selectedLanguages];
      filtersModel.providerMedicalGroup = [...filtersModel.selectedMedicalGroup];
      if (isMobileView) {
        filtersModel.providerHospitals = [...filtersModel.selectedHospitals];
        filtersModel.providerMedicalGroup = [...filtersModel.selectedMedicalGroup];
      }
    }
  }

  public static SpecialityToggle(specialty: any, filtersModel: Partial<FiltersModel>) {

    if (!filtersModel.selectedSpecialties.map((n) => n.name).includes(specialty.name)) {
      filtersModel.selectedSpecialties.push(specialty);
    } else {
      const tempSpecialties = filtersModel.selectedSpecialties.filter((s) => s.name === specialty.name)[0];
      filtersModel.selectedSpecialties.splice(filtersModel.selectedSpecialties.indexOf(tempSpecialties), 1);
    }
  }

  public static MedgroupToggle(medgroup: any, filtersModel: Partial<FiltersModel>) {
    if (!filtersModel.selectedMedicalGroup.map((n) => n.name).includes(medgroup.label)) {
      filtersModel.selectedMedicalGroup.push({ name: medgroup.label, count: 0, selected: true });
    } else {
      const tempMedgroup = filtersModel.selectedMedicalGroup.filter((s) => s.name === medgroup.label)[0];
      filtersModel.selectedMedicalGroup.splice(filtersModel.selectedMedicalGroup.indexOf(tempMedgroup), 1);
    }

    if (filtersModel.providerMedicalGroup.length === 0) {
      filtersModel.medicalGroupTitle = 'Medical group';
    } else if (filtersModel.providerMedicalGroup.length === 1) {
      filtersModel.medicalGroupTitle = filtersModel.providerMedicalGroup[0].name;
    } else {
      filtersModel.medicalGroupTitle = filtersModel.providerMedicalGroup.length.toString() + ' Medical groups';
    }
  }

  public static HospitalToggle(hospital: any, filtersModel: Partial<FiltersModel>) {
    if (!filtersModel.selectedHospitals.map((n) => n.name).includes(hospital.name)) {
      filtersModel.selectedHospitals.push(hospital);
    } else {
      const tempHospital = filtersModel.selectedHospitals.filter((s) => s.name === hospital.name)[0];
      filtersModel.selectedHospitals.splice(filtersModel.selectedHospitals.indexOf(tempHospital), 1);
    }
  }

  public static genderSelect(filtersModel: Partial<FiltersModel>, gender: string) {
    if (filtersModel.genderSelect === gender) {
      filtersModel.genderSelect = '';
    } else {
      filtersModel.genderSelect = gender;
    }
  }

  public static getMedGroups(medGroup: MedgroupDictionary[]) {
    return medGroup.map((group: MedGroup) => ({
        label: group.title,
        value: group.code,
        url: group.url,
        enableProviderRoleTypes: group.enableProviderRoleTypes ?? false
      }));
  }

  public static InsuranceToggle(insurance: any, filtersModel: Partial<FiltersModel>) {
    if (!filtersModel.selectedInsurances.map((n) => n.name).includes(insurance.name)) {
      filtersModel.selectedInsurances.push(insurance);
    } else {
      const tempInsurance = filtersModel.selectedInsurances.filter((s) => s.name === insurance.name)[0];
      filtersModel.selectedInsurances.splice(filtersModel.selectedInsurances.indexOf(tempInsurance), 1);
    }
  }
  public static LanguageToggle(language: any, filtersModel: Partial<FiltersModel>) {
    if (!filtersModel.selectedLanguages.map((n) => n.name).includes(language.name)) {
      filtersModel.selectedLanguages.push(language);
    } else {
      const tempLanguage = filtersModel.selectedLanguages.filter((s) => s.name === language.name)[0];
      filtersModel.selectedLanguages.splice(filtersModel.selectedLanguages.indexOf(tempLanguage), 1);
    }
  }

  public static clearDesktopFilters(filtersModel: Partial<FiltersModel>, searchDistance: number = null) {
    filtersModel.genderSelect = '';
    filtersModel.locationName = '';
    filtersModel.selectedInsurances = [];
    filtersModel.selectedLanguages = [];
    filtersModel.searchRadius = searchDistance;
    filtersModel.providerGender = filtersModel.genderSelect;
    filtersModel.providerInsurances = [...filtersModel.selectedInsurances];
    filtersModel.providerLanguages = [...filtersModel.selectedLanguages];
    filtersModel.onlineSchedulingApplied = false;
    filtersModel.videoVisitsApplied = false;
    filtersModel.newPatientsApplied = false;
    filtersModel.providerMedicalGroup = [];
    filtersModel.providerSpecialties = [];
    filtersModel.selectedSpecialties = [];
    filtersModel.specialtyFilterText = 'Specialty';
    filtersModel.providerAvailability = filtersModel.availabilitySelect = AvailabilitySelect.None;
    filtersModel.availabilityTitle = 'Availability';
    filtersModel.selectedHospitals = [];
    filtersModel.providerHospitals=[];
    filtersModel.insuranceFilterText = 'Insurances accepted';
    filtersModel.onlineSchedulingAllVisitApplied = false;
  }

  public static setSortByValue(filtersModel: Partial<FiltersModel>, newSortByLabel: string): void {
    if (filtersModel.sortBy === newSortByLabel) {
      filtersModel.sortBy = '';
    } else {
      filtersModel.sortBy = newSortByLabel;
    }
  }

}
