<div class="covid-vacination-card-wrapper">
  <div class="covid-vacination-card-wrapper-header">
    <div class="location-header-container">
      <p class="location-header" [class.full-width]="true">
        <strong>{{ location.providerName ?? location.name }}</strong>
      </p>
    </div>
    <div class="covid-vacination-card-wrapper-content">
      <div class="address">
        <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8.05463 23.5083C8.4761 24.1639 9.45951 24.1639 9.88098 23.5083C16.718 13.6741 17.9824 12.6439 17.9824 8.99122C17.9824 4.02732 13.9551 0 8.99122 0C3.98049 0 0 4.02732 0 8.99122C0 12.6439 1.21756 13.6741 8.05463 23.5083Z"
            fill="#C85200"
          />
        </svg>
        <div class="address-container">
          <p>{{ location.address }}</p>
          <p>{{ location.city }}, {{ location.state }}, {{ location.zip }}</p>
          <div class="get-directions">
            <a
              href="https://www.google.com/maps/search/{{ location.address }} {{ location.city }} {{ location.state }} {{ location.zip }}"
              target="_blank"
              >Get directions</a
            >
          </div>
        </div>
      </div>
      <div *ngIf="location.phone" class="address phone-icon">
        <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M15.2309 15.4189L15.9809 12.1689C16.0622 11.8158 15.8778 11.4533 15.5465 11.3096L12.0465 9.80962C11.7403 9.67838 11.3809 9.76587 11.1715 10.0252L9.62153 11.919C7.22465 10.794 5.23089 8.82839 4.08402 6.38157L5.97777 4.8316C6.23714 4.6191 6.32465 4.26286 6.1934 3.95662L4.69339 0.456688C4.54652 0.12232 4.18402 -0.0620512 3.83089 0.0191972L0.580885 0.769182C0.243384 0.847305 -0.000366211 1.1473 -0.000366211 1.50042C-0.000366211 9.50338 6.48402 16.0001 14.4997 16.0001C14.8497 16.0001 15.1528 15.7595 15.2309 15.4189Z"
          />
        </svg>
        <div class="address-container">
          <a class="phone-number phone" href="tel:{{ location.phone }}">{{ location.phone }}</a>
        </div>
      </div>
    </div>
    <div class="covid-vacination-card-wrapper-footer">
      <button dhclButton class="btn-space" buttonType="primary" (click)="btnOkClickHandler()" buttonSize="small">Book</button>
    </div>
  </div>
</div>
