import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LogService } from '../../services/log.service';



@Injectable()
export class HttpLogInterceptor implements HttpInterceptor {
constructor(private logService: LogService) {}



intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
return next.handle(req).pipe(
catchError((error) => {
if (error instanceof HttpErrorResponse) {
if (error.error instanceof ErrorEvent) {
this.logService.warn(`${error.error.message}`);
} else {
if (error.status >= 400 && error.status < 600) {
this.logService.error(`status:${error.status}- URL:${error.url} ::error message: ${error.error.message}`);
}
}
} else {
this.logService.warn(`${error.message}`);
}



return throwError(error);
})
);
}
}
