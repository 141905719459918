<div class="location-search" [ngClass]="{'changeStyle':changeBtnStyle}" #locationSuggestionsContainer>
  <span class="location-search-icon">
    <svg viewBox="0 0 17 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.92515 20.0837C9.20284 21.0669 7.79716 21.0669 7.07485 20.0837C5.82622 18.3839 4.28559 16.1563 3.06154 13.9812C2.44945 12.8935 1.92663 11.8366 1.5591 10.8779C1.18629 9.90543 1 9.10165 1 8.5C1 4.35786 4.35786 1 8.5 1C12.6421 1 16 4.35786 16 8.5C16 9.10165 15.8137 9.90543 15.4409 10.8779C15.0734 11.8366 14.5505 12.8935 13.9385 13.9812C12.7144 16.1563 11.1738 18.3839 9.92515 20.0837Z"
      />
      <path d="M8.5 11C9.88071 11 11 9.88071 11 8.5C11 7.11929 9.88071 6 8.5 6C7.11929 6 6 7.11929 6 8.5C6 9.88071 7.11929 11 8.5 11Z" />
    </svg>
  </span>
  <input
    #locationInput
    type="text"
    maxlength="80"
    placeholder="City or zip"
    [attr.aria-label]="getLocationError()"
    [ngClass]="{ active: autocompleteActive }"
    [(ngModel)]="locationName"
    (ngModelChange)="onInputChange($event)"
    (keyup.enter)="handleLocationEnter()"
    (keyup.escape)="setAutocompleteActive(false)"
    (keydown)="handleKeyPress($event)"
    (focus)="closeProviderAutoSuggestion()"
    (blur)="onFocusLost($event)"
    data-nav-type="find a doctor"
    data-nav-element="fad search text"
    data-nav-location="fad search initiation"
    role="combobox"
    aria-autocomplete="list"
    [attr.aria-expanded]="autocompleteActive"
    [attr.aria-busy]="inputInFocus && showLoadingMessage && locationName !== ''"
  />
  <ul
    *ngIf="inputInFocus && showLoadingMessage && locationName !== ''"
    [attr.aria-label]="autoSuggestionLoadingMessage"
    class="location-suggestions-container"
  >
    <li class="locations-list" *ngIf="locations.length === 0">
      <ul>
        <li class="loading-suggestion" [attr.aria-label]="autoSuggestionLoadingMessage">{{ autoSuggestionLoadingMessage }}</li>
      </ul>
    </li>
  </ul>
  <ul *ngIf="inputInFocus && autocompleteActive && locationName !== ''" class="location-suggestions-container">
    <li class="locations-list" *ngIf="locations.length > 0">
      <ul>
        <li
          class="p p2"
          *ngFor="let location of locations ;let position = index"
          (click)="setSelectedLocation(location)"
          (keydown.enter)="setSelectedLocation(location)"
          (keydown.space)="setSelectedLocation(location)"
          (mouseenter)="setIndex(position)"
          [attr.aria-selected]="highlightedlocation === position"
          [attr.aria-label]="location"
          [ngClass]="{ selected: highlightedlocation === position }"
          tabindex="0"
        >
          {{ location.name }}
        </li>
      </ul>
    </li>
  </ul>
  <span class="geolocation-icon">
    <svg
      tabindex="0"
      [attr.aria-label]="'use current location'"
      role="button"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      (click)="findGeoLocation()"
      (keydown.enter)="findGeoLocation()"
      (keyup.escape)="showTooltip()"
    >
      <path
        d="M12.3665 18.8776L19.8626 2.63629C20.4872 1.13666 18.863 -0.487554 17.3637 0.137444L1.12242 7.63353C-0.751432 8.50812 -0.126816 11.2566 1.872 11.2566H5.74347C7.40032 11.2566 8.74347 12.5997 8.74347 14.2566V18.128C8.74347 20.1268 11.4919 20.7518 12.3665 18.8776Z"
      />
    </svg>
    <span class="use-current-location-tooltip" *ngIf="isToolTipVisible">
      <p>Use current location</p>
    </span>
  </span>
</div>
