import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { QuickLinksCategories } from 'src/app/models/configuration';

@Component({
  selector: 'cs-quicklinks-search',
  templateUrl: './quicklinks-search.component.html',
  styleUrls: ['./quicklinks-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuicklinksSearchComponent implements OnInit {
  @Output() quicklinkCategoryClick = new EventEmitter<QuickLinksCategories>();
  @Input() quickLinksCategories: QuickLinksCategories[] = [];
  @Input() quickLinksButtonAlignment = false;

  calcWidth = 'calc(50% - 20px)';
  dynamicWidth = '100%';

  ngOnInit(): void {
    this.changeButtonAlignment();
  }

  quicklinkClick(quickLink: QuickLinksCategories) {
    this.quicklinkCategoryClick.emit(quickLink);
  }

  changeButtonAlignment() {
    document.documentElement.style.setProperty('--width',
      this.quickLinksButtonAlignment ? this.dynamicWidth : this.calcWidth);
  }
}
