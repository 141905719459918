import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'cs-suggest-an-edit',
  templateUrl: './suggest-an-edit.component.html',
  styleUrls: ['./suggest-an-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuggestAnEditComponent {
  @Input() suggestAnEditLinkContent: string;

  handleSuggestEditClick() {
    //save the access of non fad url in session and navigate to suggestAnEditLink
    if (!window.sessionStorage.getItem('nonFadUrlAccessed')) {
      window.sessionStorage.setItem('nonFadUrlAccessed', 'true');
    }
  }
}
