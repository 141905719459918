<div class="dhcl-section" tabindex="0" role="button" [attr.aria-expanded]="!isCollapsed">
  <div class="section-header" (click)="isCollapsed = !isCollapsed">
    <h3 class="section-header-h3">
      <div class="section-header-title">
        <span>{{ title }}</span>
        <cs-tooltip *ngIf="tooltip" [toolTipInfo]="tooltipInfo" [left]="left"> {{ tooltip }} </cs-tooltip>
      </div>
    </h3>
    <div>
      <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="isCollapsed">
        <path d="M8.99958 0V18" />
        <path d="M0 9.00001H18" />
      </svg>
      <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="!isCollapsed">
        <path d="M0 9.00001H18" />
      </svg>
    </div>
  </div>
  <div class="section-content" [hidden]="isCollapsed">
    <ng-content></ng-content>
  </div>
</div>
