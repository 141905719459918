import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { fadProviderDetailsUrlMatcher, fadSearchResultsVanityUrlMatcher } from './utils/fad-marketing-route-matcher';
import { inFadLiteMode } from './utils/utils';
import { ErrorPageComponent } from './components/error-page/error-page.component';

const routes: Routes = [
  {
    path: 'covid-vaccine/launch',
    loadChildren: () => import('./pages/vaccination-location/vaccination-location.module').then((m) => m.VaccinationLocationModule)
  },
  {
    path: 'find-doctor-widget',
    loadChildren: () => import('./pages/find-doctor-widget/find-doctor-widget.module').then((m) => m.FindDoctorWidgetModule)
  },
  {
    path: 'fad-lite',
    loadChildren: () => import('./pages/fad-lite-search-landing/fad-lite-search-landing.module').then((m) => m.FadLiteSearchLandingModule)
  },
  {
    matcher: fadSearchResultsVanityUrlMatcher,
    loadChildren: () => import('./pages/search-results/search-results.module').then((m) => m.SearchResultsModule),
    data: {
      storageKey: 'SearchResultsComponent'
    }
  },
  {
    matcher: fadProviderDetailsUrlMatcher,
    loadChildren: () => import('./pages/provider-details/provider-details.module').then((m) => m.ProviderDetailsModule)
  },
  {
    path: '',
    loadChildren: () => inFadLiteMode()? 
          import('./pages/fad-lite-search-landing/fad-lite-search-landing.module').then((m) => m.FadLiteSearchLandingModule):
          import('./pages/search-landing/search-landing.module').then((m) => m.SearchLandingModule)
  },
  { path: '**', component: ErrorPageComponent, pathMatch: 'full' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled'
      // This is useful to debug and see router events.
      // enableTracing: true
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}


