import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultipleCheckboxSearchableDropdownComponent } from './multiple-checkbox-searchable-dropdown.component';

@NgModule({
  imports: [CommonModule],
  declarations: [MultipleCheckboxSearchableDropdownComponent],
  exports: [MultipleCheckboxSearchableDropdownComponent]
})
export class MultiSelectSearchableDropDownModule {}
