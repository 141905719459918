<div class="fad-col-sm-12 affiliation-container">
  <div class="affiliation-header">
    Hospital affiliations
    <a (click)="hideExtraContent = !hideExtraContent" class="more-affiliations visibledesktop" *ngIf="affiliations.length > 2">
      {{ hideExtraContent ? 'View more' : 'View less' }}
      <svg class="more-arrow" [ngClass]="{ 'less-arrow': !hideExtraContent }" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L6 6L11 1" />
      </svg>
    </a>
    <a (click)="hideExtraContent = !hideExtraContent" class="more-affiliations visibledevice" *ngIf="affiliations.length > 1">
      {{ hideExtraContent ? 'View more' : 'View less' }}
      <svg class="more-arrow" [ngClass]="{ 'less-arrow': !hideExtraContent }" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L6 6L11 1" />
      </svg>
    </a>
  </div>
  <div class="visibledesktop">
    <div *ngFor="let hospital of affiliations.slice(0, 2); let i = index" class="hospital">
      <ng-container *ngTemplateOutlet="hospitalDetails; context: { hospital: hospital, i: i }"></ng-container>
    </div>
    <div *ngIf="!hideExtraContent && affiliations.length > 2">
      <div *ngFor="let hospital of affiliations.slice(2); let i = index" class="more-hospitals">
        <ng-container *ngTemplateOutlet="hospitalDetails; context: { hospital: hospital, i: i }"></ng-container>
      </div>
    </div>
  </div>
  <div class="visibledevice">
    <div *ngFor="let hospital of affiliations.slice(0, 1); let i = index" class="hospital">
      <ng-container *ngTemplateOutlet="hospitalDetails; context: { hospital: hospital, i: i }"></ng-container>
    </div>
    <div *ngIf="!hideExtraContent && affiliations.length > 1">
      <div *ngFor="let hospital of affiliations.slice(1); let i = index" class="more-hospitals">
        <ng-container *ngTemplateOutlet="hospitalDetails; context: { hospital: hospital, i: i }"></ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #hospitalDetails let-hospital="hospital" let-i="i">
  <div class="name-columns">
    <p #expandableList>{{ hospital }}</p>
  </div>
</ng-template>
