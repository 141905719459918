import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDistance'
})
export class FormatDistancePipe implements PipeTransform {
  transform(distance: number, decimalDigits = 1, units = 'mi'): string {
    if (distance === null || distance === undefined) {
      return '';
    }
    return `${distance.toFixed(decimalDigits)} ${units}`;
  }
}
