<div class="regional-helpline" [ngClass]="{ 'full-width': !showMyProviders }">
  <span class="regional-helpline-header" *ngIf="!showinSearchResultsPage">{{ displayInfo }}</span>
  <span class="need-help-helpline-header h2" *ngIf="showinSearchResultsPage">{{ displayHeadingInvalid }}</span>
  <div class="regional-helpline-items" *ngIf="this.helplineNumbers.length > 1">
    <div *ngFor="let line of helplineNumbers" class="helpline-item">
      <div class="row visibledevice">
        <div class="column heading">
          <p class="helpline-heading heading1 p p2">{{ line.heading }}</p>
        </div>
        <div class="column">
          <a href="tel:{{ line.phone }}" class="helpline-phone p p2"> {{ line.displayPhone }} </a>
        </div>
      </div>

      <p class="helpline-heading visibletablet p p3">{{ line.heading }}</p>
      <p class="helpline-phone-desktop visibletablet p p2">{{ line.displayPhone }}</p>
    </div>
  </div>
  <div class="regional-helpline-items" *ngIf="this.helplineNumbers.length == 1">
    <div *ngFor="let line of helplineNumbers" class="helpline-item">
      <p *ngIf="showinSearchResultsPage" class="helpline-heading p p2">{{ line.heading }}</p>
      <p class="helpline-phone-desktop visibledesktop p p2">{{ line.displayPhone }}</p>
      <a href="tel:{{ line.phone }}" class="helpline-phone visibledevice p p2">{{ line.displayPhone }}</a>
    </div>
  </div>
</div>
<div class="helplinks-divider" *ngIf="showEmergencyCare && showMyProviders"></div>
