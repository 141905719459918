import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from '../configuration.service';
import { ProviderService } from '../provider.service';
import { ProviderLegacyService } from './provider.legacy.service';
import { ProviderNewService } from './provider.new.service';

export function ProviderServiceFactory(http: HttpClient, configService: ConfigurationService): ProviderService {
  if (configService.useLegacyAPI()) {
    return new ProviderLegacyService(http, configService);
  }
  return new ProviderNewService(http, configService);
}
