import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LocationByIpResult, LocationDetailResult, LocationSuggestionResult, SelectedLocation } from '../models/location';
import { ApiResponse } from '../utils/api-response';

@Injectable({
  providedIn: 'root'
})
export abstract class LocationService {
  abstract getTypedLocationSuggestions(location: string, sessionToken?: string): Observable<ApiResponse<LocationSuggestionResult[]>>;

  abstract getLocationSuggestions(query: string, sessionToken?: string);

  abstract getLocationByIP(): Observable<ApiResponse<LocationByIpResult>>;

  abstract getTypedLocationDetail(placeId: string): Observable<ApiResponse<LocationDetailResult>>;

  abstract getLocationDetail(placeId: string): Observable<ApiResponse<LocationDetailResult>>;

  abstract queryLocation(location: string): Observable<SelectedLocation>;
}
