import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'cs-regional-helpline-numbers',
  templateUrl: './regional-helpline-numbers.component.html',
  styleUrls: ['./regional-helpline-numbers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegionalHelplineNumbersComponent {
  @Input() showinSearchResultsPage = false;
  @Input() helplineNumbers: {
    heading?: string;
    phone?: string;
  }[] = [];
  @Input() displayInfo: string;
  @Input() displayHeadingInvalid: string;
  @Input() showMyProviders: boolean;
  @Input() showEmergencyCare: boolean;
  @Input() isDesktopViewport: boolean;
}
