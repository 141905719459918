/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppointmentType } from '@dignity-health/ngx-guided-flow/lib/models/provider.model';
import { LogoDetails } from 'src/app/models/configuration';
import { OfficeAddress } from 'src/app/models/office-address';
import { OnlineBookingAvailability } from 'src/app/models/online-booking-availability';
import { Provider } from 'src/app/models/provider';
import { ProviderImage } from 'src/app/models/provider-image';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Component({
  selector: 'cs-provider-details-card',
  templateUrl: './provider-details-card.component.html',
  styleUrls: ['./provider-details-card.component.scss']
})
export class ProviderDetailsCardComponent implements OnInit {
  @Input() provider: Provider;
  @Input() logoUrls: LogoDetails[] = [];
  @Input() providerSpecialities: string[];
  @Input() providerImage: ProviderImage;
  @Input() nonSEOSecondarySpecialities: string[];
  @Input() ratingTooltipText: string;
  @Input() showNumReviews: boolean;
  @Input() showRatingText: boolean;
  @Input() showRating: boolean;
  @Input() isSchedulable: boolean;
  @Input() officeAddresses: OfficeAddress[] = [];
  stars = [1,2,3,4,5];
  @Output() scheduleProvider: EventEmitter<Provider> = new EventEmitter<Provider>();
  schedulingType: string;

  constructor(private configurationService: ConfigurationService) { }
  
  ngOnInit(): void {
    this.schedulingType = this.configurationService.getOnlineSchedulingType();
  }

  getAltImageSrc(event: any) {
    event.target.src =
      this.provider.gender?.toLowerCase() === 'male'
        ? this.configurationService.malePlaceholder
        : this.provider.gender?.toLowerCase() === 'female'
        ? this.configurationService.femalePlaceholder
        : this.configurationService.blankPlaceholder;
  }

  get showGuidedFlowCTA(): boolean {
    if (this.schedulingType === 'GuidedFlow') {
      const hasBookingLocation = (this.provider?.onlineBookingAvailability || []).find((location: OnlineBookingAvailability) => location.supportsOnlineBooking);

      const hasMappedApptType = (this.provider?.onlineBookingAvailability || []).find((location: OnlineBookingAvailability) => (location.appointmentTypes || []).find((apptType: AppointmentType) => !!apptType.patientAppointmentType));

      const hasPhoneNumber = this.officeAddresses[0]?.phones && this.officeAddresses[0]?.phones[0];
      if (this.provider?.isBookOnline && hasBookingLocation && hasMappedApptType && hasPhoneNumber && this.isSchedulable) 
      {
        return true;
      }
    }
    return false;
  }

  launchGuidedFlow(): void{ 
    this.scheduleProvider.emit(this.provider);
  }

  goToComments() {
    const commentSection: HTMLElement = document.querySelector('div.provider-comments-container');
    commentSection?.scrollIntoView();
  }
}
