<div class="desktop-filter-buttons" #filtersContainer>
  <div class="dignity-fiters" *ngIf="isDesktopViewport && isDignityHealthDivision">
    <div class="dignity-filter-buttons">
      <div class="dignity-filter-btn-aln">
        <div class="dignity-fitler-display">
          <div class="dignity-filter-txt">Filters</div>
          <div class="dignity-filter-bar"></div>
        </div>
        <div class="dignity-filter-main">
          <div class="dignity-filter-btn-first-rw">
            <cs-filter-dialog
              class="filter-container filter-btn-dh"
              *ngIf="showOnlineSchedulingFilter"
              [resetActive]="filtersModel.onlineSchedulingToggle"
              [filterTitle]="'Online scheduling-new patients'"
              [showCloseIcon]="filtersModel.onlineSchedulingApplied"
              [enableFiltering]="filtersModel.onlineSchedulingProvidersCount > 0 && !filtersModel.onlineSchedulingApplied"
              [hideDialogButtons]="true"
              (contentReset)="filterOnlineScheduling(false)"
              (selectionReset)="filtersModel.onlineSchedulingToggle = !filtersModel.onlineSchedulingToggle"
              [attr.aria-valuetext]="'Online scheduling toggle ' + filtersModel.onlineSchedulingApplied"
            >
              <div class="slider-toggle-padding">
                <cs-slide-toggle
                  [checked]="filtersModel.onlineSchedulingToggle"
                  [disabled]="filtersModel.onlineSchedulingProvidersCount === 0"
                  (keydown.enter)="filtersModel.onlineSchedulingProvidersCount > 0 && filterOnlineScheduling(filtersModel.onlineSchedulingToggle = !filtersModel.onlineSchedulingToggle)"
                  (keydown.space)="filtersModel.onlineSchedulingProvidersCount > 0 && filterOnlineScheduling(filtersModel.onlineSchedulingToggle = !filtersModel.onlineSchedulingToggle)"
                  (click)="filtersModel.onlineSchedulingProvidersCount > 0 && filterOnlineScheduling(filtersModel.onlineSchedulingToggle = !filtersModel.onlineSchedulingToggle)"
                  (contentClosed)="closeFilterDialog()"
                >
                </cs-slide-toggle>
              </div>
              <div class="dialog-page-content p p2">
                {{ this.applyFilterMessage(' offer online scheduling for new patients', filtersModel.onlineSchedulingProvidersCount > 0)}}
                <span *ngIf="filtersModel.onlineSchedulingProvidersCount > 0">({{ filtersModel.onlineSchedulingProvidersCount }})</span>
              </div>
            </cs-filter-dialog>

            <cs-filter-dialog
              class="filter-container filter-btn-dh"
              *ngIf="showVideoVisitsFilter"
              [resetActive]="filtersModel.videoVisitsToggle"
              [showCloseIcon]="filtersModel.videoVisitsApplied"
              [filterTitle]="'Online scheduling-video visits'"
              [enableFiltering]="filtersModel.videoVisitsProvidersCount > 0 && !filtersModel.videoVisitsApplied"
              [hideDialogButtons]="true"
              (selectionReset)="filtersModel.videoVisitsToggle = !filtersModel.videoVisitsToggle"
              (contentReset)="filterVideoVisits(false)"
            >
              <div class="slider-toggle-padding">
                <cs-slide-toggle
                  [checked]="filtersModel.videoVisitsToggle"
                  [disabled]="filtersModel.videoVisitsProvidersCount === 0"
                  (keydown.enter)="filtersModel.videoVisitsProvidersCount > 0 && filterVideoVisits(filtersModel.videoVisitsToggle = !filtersModel.videoVisitsToggle)"
                  (keydown.space)="filtersModel.videoVisitsProvidersCount > 0 && filterVideoVisits(filtersModel.videoVisitsToggle = !filtersModel.videoVisitsToggle)"
                  (click)="filtersModel.videoVisitsProvidersCount > 0 && filterVideoVisits(filtersModel.videoVisitsToggle = !filtersModel.videoVisitsToggle)"
                  (contentClosed)="closeFilterDialog()"
                >
                </cs-slide-toggle>
              </div>
              <div class="dialog-page-content p p2">
                {{ this.applyFilterMessage(' offer online scheduling for video visits', filtersModel.videoVisitsProvidersCount > 0 ||
                ((!filtersModel.searchDone) && LastVideoVisitsProvidersCount > 0))}}
                <span *ngIf="filtersModel.videoVisitsProvidersCount > 0">({{ filtersModel.videoVisitsProvidersCount }})</span>
                <span *ngIf="filtersModel.videoVisitsProvidersCount==0 && (!filtersModel.searchDone) && LastVideoVisitsProvidersCount > 0"
                  >({{ LastVideoVisitsProvidersCount }})</span
                >
              </div>
            </cs-filter-dialog>

            <cs-filter-dialog
              class="filter-container filter-btn-dh"
              *ngIf="showOnlineSchedulingAllVisitFilter"
              [enableFiltering]="filtersModel.onlineSchedulingAllVisitProvidersCount > 0 && !filtersModel.onlineSchedulingAllVisitApplied"
              [filterTitle]="'Online scheduling-all visits'"
              [resetActive]="filtersModel.onlineSchedulingAllVisitToggle"
              [showCloseIcon]="filtersModel.onlineSchedulingAllVisitApplied"
              [hideDialogButtons]="true"
              (selectionReset)="filtersModel.onlineSchedulingAllVisitToggle = !filtersModel.onlineSchedulingAllVisitToggle"
              (contentReset)="filterOnlineSchedulingAllVisit(false)"
              [attr.aria-valuetext]="'Online scheduling all visit toggle ' + filtersModel.onlineSchedulingAllVisitApplied"
            >
              <div class="slider-toggle-padding">
                <cs-slide-toggle
                  [checked]="filtersModel.onlineSchedulingAllVisitToggle"
                  [disabled]="filtersModel.onlineSchedulingAllVisitProvidersCount === 0"
                  (keydown.enter)="filtersModel.onlineSchedulingAllVisitProvidersCount > 0 && filterOnlineSchedulingAllVisit(filtersModel.onlineSchedulingAllVisitToggle = !filtersModel.onlineSchedulingAllVisitToggle)"
                  (keydown.space)="filtersModel.onlineSchedulingAllVisitProvidersCount > 0 && filterOnlineSchedulingAllVisit(filtersModel.onlineSchedulingAllVisitToggle = !filtersModel.onlineSchedulingAllVisitToggle)"
                  (click)="filtersModel.onlineSchedulingAllVisitProvidersCount > 0 && filterOnlineSchedulingAllVisit(filtersModel.onlineSchedulingAllVisitToggle = !filtersModel.onlineSchedulingAllVisitToggle)"
                  (contentClosed)="closeFilterDialog()"
                >
                </cs-slide-toggle>
              </div>
              <div class="dialog-page-content p p2">
                {{ this.applyFilterMessageOnlineSchedulingAllVisit(' offer online scheduling',
                filtersModel.onlineSchedulingAllVisitProvidersCount > 0)}}
                <span *ngIf="filtersModel.onlineSchedulingAllVisitProvidersCount > 0"
                  >({{ filtersModel.onlineSchedulingAllVisitProvidersCount }})</span
                >
              </div>
            </cs-filter-dialog>
            <cs-filter-dialog
              class="filter-container filter-btn-dh"
              *ngIf="showAcceptingNewPatientsFilter"
              [filterTitle]="'Accepting new patients'"
              [resetActive]="filtersModel.newPatientsToggle"
              [showCloseIcon]="filtersModel.newPatientsApplied"
              [hideDialogButtons]="true"
              [enableFiltering]="filtersModel.newPatientsProvidersCount > 0 && (filtersModel.newPatientsApplied || filtersModel.newPatientsToggle)"
              (selectionReset)="filtersModel.newPatientsToggle = !filtersModel.newPatientsToggle"
              (contentReset)="filterNewPatients(false)"
            >
              <div class="slider-toggle-padding">
                <cs-slide-toggle
                  [disabled]="filtersModel.newPatientsProvidersCount === 0"
                  [checked]="filtersModel.newPatientsToggle"
                  (keydown.enter)="filtersModel.newPatientsProvidersCount > 0 && filterNewPatients(filtersModel.newPatientsToggle = !filtersModel.newPatientsToggle)"
                  (keydown.space)="filtersModel.newPatientsProvidersCount > 0 && filterNewPatients(filtersModel.newPatientsToggle = !filtersModel.newPatientsToggle)"
                  (click)="filtersModel.newPatientsProvidersCount > 0 && filterNewPatients(filtersModel.newPatientsToggle = !filtersModel.newPatientsToggle)"
                  (contentClosed)="closeFilterDialog()"
                >
                </cs-slide-toggle>
              </div>
              <div class="dialog-page-content p p2">
                {{ this.applyFilterMessage(' accept new patients', filtersModel.newPatientsProvidersCount > 0)}}
              </div>
            </cs-filter-dialog>
            <cs-filter-dialog
              [filterTitle]="filtersModel.insuranceFilterText"
              [resetActive]="filtersModel.selectedInsurances.length > 0"
              [showCloseIcon]="filtersModel.providerInsurances.length > 0"
              (selectionReset)="insuranceReset()"
              (contentReset)="filterInsurances(false)"
              (applyFilters)="filterInsurances(true)"
              class="filter-container filter-btn-dh"
              (showDialogClick)="initializeInsurancesDialog()"
              *ngIf="showInsuranceAcceptedFilter"
            >
              <div class="dialog-page-content specialty-list" role="dialog" aria-label="List of Specialities">
                <div
                  tabindex="0"
                  role="checkbox"
                  [attr.aria-checked]="checkInsurance(insurance) === true"
                  *ngFor="let insurance of filtersModel.availableInsurances"
                  class="toggle-col-option"
                  (keydown.enter)="toggleInsurance(insurance)"
                  (click)="toggleInsurance(insurance)"
                >
                  <span class="option-checkbox">
                    <svg class="unchecked" *ngIf="!checkInsurance(insurance)" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <rect rx="2.5" width="19" height="19" x="0.5" y="0.5" />
                    </svg>
                    <svg class="checked" *ngIf="checkInsurance(insurance)" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <rect rx="3" />
                      <path d="M10.0001 15L4.16675 9.16663L5.83341 7.49996L10.0001 11.6666L20.0001 1.66663V4.99996L10.0001 15Z" />
                    </svg>
                  </span>
                  {{ insurance.name }}&nbsp;<span class="option-count">({{ insurance.count }}) </span>
                </div>
              </div>
            </cs-filter-dialog>
          </div>
        </div>
      </div>
      <div class="dignity-filter-btn-second-rw">
        <div class="dignity-filter-buttons-title">
          <cs-filter-dialog
            [filterTitle]="filtersModel.specialtyFilterText"
            [resetActive]="filtersModel.selectedSpecialties.length > 0"
            [showCloseIcon]="filtersModel.providerSpecialties.length > 0"
            (selectionReset)="specialtyReset()"
            (contentReset)="filterSpecialties(false)"
            (applyFilters)="filterSpecialties(true)"
            class="filter-container filter-btn-dh"
            (showDialogClick)="initializeSpecialtyDialog()"
            *ngIf="showSpecialtyFilter"
          >
            <div class="dialog-page-content specialty-list" role="dialog" aria-label="List of Specialities">
              <div
                tabindex="0"
                role="checkbox"
                [attr.aria-checked]="checkSpecialty(specialty) === true"
                *ngFor="let specialty of filtersModel.availableSpecialties"
                class="toggle-col-option"
                (keydown.enter)="toggleSpecialty(specialty)"
                (click)="toggleSpecialty(specialty)"
              >
                <span class="option-checkbox">
                  <svg class="unchecked" *ngIf="!checkSpecialty(specialty)" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <rect rx="2.5" width="19" height="19" x="0.5" y="0.5" />
                  </svg>
                  <svg class="checked" *ngIf="checkSpecialty(specialty)" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <rect rx="3" />
                    <path d="M10.0001 15L4.16675 9.16663L5.83341 7.49996L10.0001 11.6666L20.0001 1.66663V4.99996L10.0001 15Z" />
                  </svg>
                </span>
                {{ specialty.name }}&nbsp;<span class="option-count">({{ specialty.count }})</span>
              </div>
            </div>
          </cs-filter-dialog>
        </div>
        <div class="dignity-more-filters">
          <div class="dignity-more-filters-content">
            <div class="filter-container filter-btn-dh more-filters" *ngIf="showMoreFilters && useLegacyAPI">
              <div class="dignity-more-filters-txt" (click)="moreFiltersClicked()" [ngClass]="{ active: showModal || showCloseIcon}">
                More filters <span *ngIf="moreFilterCount > 0">{{moreFilterText}}</span>
              </div>
              <dhcl-modal
                [alignTop]="alignTop"
                [show]="showModal"
                (closeClicked)="showModal = !showModal"
                [closeOnOverlay]="true"
                [customClass]="'desktop-filters-modal'"
              >
                <div class="dialog-title">
                  <h3>More filters</h3>
                </div>
                <div class="dialog">
                  <cs-accordion [title]="'Gender'" *ngIf="showGenderFilter">
                    <div class="gender">
                      <div class="radio-button-option" (click)="selectGender('female')">
                        <svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
                          />
                          <path
                            *ngIf="filtersModel.genderSelect === 'female'"
                            d="M11 16.8974C14.257 16.8974 16.8974 14.257 16.8974 11C16.8974 7.74291 14.257 5.10254 11 5.10254C7.74291 5.10254 5.10254 7.74291 5.10254 11C5.10254 14.257 7.74291 16.8974 11 16.8974Z"
                          />
                        </svg>
                        Female&nbsp;
                        <span>({{ filtersModel.femaleCount }})</span>
                      </div>
                      <div class="radio-button-option" (click)="selectGender('male')">
                        <svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
                          />
                          <path
                            *ngIf="filtersModel.genderSelect === 'male'"
                            d="M11 16.8974C14.257 16.8974 16.8974 14.257 16.8974 11C16.8974 7.74291 14.257 5.10254 11 5.10254C7.74291 5.10254 5.10254 7.74291 5.10254 11C5.10254 14.257 7.74291 16.8974 11 16.8974Z"
                          />
                        </svg>
                        Male&nbsp;
                        <span>({{ filtersModel.maleCount }})</span>
                      </div>
                    </div>
                  </cs-accordion>
                  <cs-common-more-filters [filtersModel]="filtersModel" [showLanguageFilter]="showLanguageFilter"></cs-common-more-filters>
                  <cs-accordion
                    [title]="'Medical group'"
                    *ngIf="showMedicalGroupFilter && !multipleMedicalGroupSelection"
                    (applyFilters)="filterMedicalGroup(true)"
                  >
                    <div tabindex="0" role="dialog" aria-label="List of Medical groups" class="dialog-page-content p p2">
                      <dhcl-dropdown
                        [placeholder]="selectedMedicalGroupName??'Select Medical group'"
                        [dropdowns]="filtersModel.medicalGroupList"
                        [selectedValue]="selectedMedicalGroupName"
                        (changed)="selectMedGroup($event)"
                      >
                      </dhcl-dropdown>
                    </div>
                  </cs-accordion>
                  <cs-accordion [title]="'Search radius'" *ngIf="showSearchRadiusFilter && selectedLocation">
                    <div class="range-header">
                      Only show providers within
                      <div class="range-number">{{ displayRadius }}</div>
                      mi of search location
                    </div>
                    <div class="range-slider-container">
                      <input
                        type="range"
                        [(ngModel)]="filtersModel.searchRadius"
                        min="0"
                        max="100"
                        value="filtersModel.searchRadius"
                        step="20"
                      />
                      <div
                        class="range-slider-fill"
                        [ngStyle]="{
                          background:
                            'linear-gradient(90deg, var(--dhcl-color-secondary) 0%, var(--dhcl-color-secondary) ' +
                            filtersModel.searchRadius +
                            '%, #e3e1dc ' +
                            filtersModel.searchRadius +
                            '%)'
                        }"
                      ></div>
                      <div class="range-indicators">
                        <div class="first">1</div>
                        <div class="middle">
                          <div>5</div>
                          <div>10</div>
                          <div>25</div>
                          <div>50</div>
                        </div>
                        <div class="last">100</div>
                      </div>
                    </div>
                  </cs-accordion>
                </div>
                <div class="dialog-footer">
                  <div
                    class="clear-all-button"
                    [attr.aria-disabled]="!isFilterSelected"
                    tabindex="0"
                    role="button"
                    aria-label="Clear all"
                    [ngClass]="{ active: isFilterSelected }"
                    (click)="clearFilters()"
                  >
                    Clear all
                  </div>
                  <button dhclButton (click)="applyFilters()">Apply</button>
                </div>
              </dhcl-modal>
            </div>
            <div *ngIf="moreFilterCount > 0">
              <svg
                tabindex="0"
                viewBox="0 0 16 16"
                (click)="clearFilters($event)"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style="height: 15px; padding-left: 10px; cursor: pointer"
              >
                <path d="M1 1L15 15" stroke="#0071C2" stroke-width="2" />
                <path d="M1 14.998L15 1" stroke="#0071C2" stroke-width="2" />
              </svg>
            </div>
          </div>
          <div class="dignity-filter-bar"></div>
          <div class="dignity-filter-buttons-title">
            <div *ngIf="isDesktopFilterSelected" class="filter-container filter-btn-dh">
              <div
                class="clear-all-btn dignity-clear-all-btn"
                [attr.aria-disabled]="!isAnyFilterSelected"
                tabindex="0"
                role="button"
                aria-label="Clear all filters"
                [ngClass]="{ active: isAnyFilterSelected }"
                (keydown.enter)="clearDesktopFilters()"
                (click)="clearDesktopFilters()"
              >
                Clear all filters
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="desktop-filter-buttons" #filtersContainer>
  <cs-mobile-filters
    *ngIf="!isDesktopViewport && showMobileFilters"
    [filtersModel]="filtersModel"
    [medicalGroupToolTip]="medicalGroupToolTip"
    [medicalGroupHoverText]="medicalGroupHoverText"
    [affiliationToolTip]="affiliationToolTip"
    [affiliationHoverText]="affiliationHoverText"
    [defaultSearchDistanceMobile]="defaultSearchDistance"
    [defaultSearchDistanceWithMG]="defaultSearchDistanceWithMedGroup"
    [showOnlineSchedulingFilter]="showOnlineSchedulingFilter"
    [showVideoVisitsFilter]="showVideoVisitsFilter"
    [showAcceptingNewPatientsFilter]="showAcceptingNewPatientsFilter"
    [showAvailabilityFilter]="showAvailabilityFilter"
    [showSpecialtyFilter]="showSpecialtyFilter"
    [showMedicalGroupFilter]="showMedicalGroupFilter"
    [showAffiliationFilter]="showAffiliationFilter"
    [showGenderFilter]="showGenderFilter"
    [showInsuranceFilter]="showInsuranceFilter"
    [showLanguageFilter]="showLanguageFilter"
    [selectedLocation]="selectedLocation"
    [alignTop]="alignTop"
    [showInsuranceAcceptedFilter]="showInsuranceAcceptedFilter"
    [showOnlineSchedulingAllVisitFilter]="showOnlineSchedulingAllVisitFilter"
    [isDignityHealthDivision]="isDignityHealthDivision"
    [multipleMedicalGroupSelection]="multipleMedicalGroupSelection"
    (filtersModelChange)="handleFilterChange($event)"
  ></cs-mobile-filters>
  <div *ngIf="isDesktopViewport && !isDignityHealthDivision">
    <cs-filter-dialog
      class="filter-container"
      *ngIf="showOnlineSchedulingFilter"
      [filterTitle]="'Online scheduling'"
      [resetActive]="filtersModel.onlineSchedulingToggle"
      [showCloseIcon]="filtersModel.onlineSchedulingApplied"
      [enableFiltering]="filtersModel.onlineSchedulingProvidersCount > 0 && !filtersModel.onlineSchedulingApplied"
      [hideDialogButtons]="true"
      (selectionReset)="filtersModel.onlineSchedulingToggle = !filtersModel.onlineSchedulingToggle"
      (contentReset)="filterOnlineScheduling(false)"
      [attr.aria-valuetext]="'Online scheduling toggle ' + filtersModel.onlineSchedulingApplied"
    >
      <div class="slider-toggle-padding">
        <cs-slide-toggle
          [disabled]="filtersModel.onlineSchedulingProvidersCount === 0"
          [checked]="filtersModel.onlineSchedulingToggle"
          (keydown.enter)="filtersModel.onlineSchedulingProvidersCount > 0 && filterOnlineScheduling(filtersModel.onlineSchedulingToggle = !filtersModel.onlineSchedulingToggle)"
          (keydown.space)="filtersModel.onlineSchedulingProvidersCount > 0 && filterOnlineScheduling(filtersModel.onlineSchedulingToggle = !filtersModel.onlineSchedulingToggle)"
          (click)="filtersModel.onlineSchedulingProvidersCount > 0 && filterOnlineScheduling(filtersModel.onlineSchedulingToggle = !filtersModel.onlineSchedulingToggle)"
          (contentClosed)="closeFilterDialog()"
        >
        </cs-slide-toggle>
      </div>
      <div class="dialog-page-content p p2">
        {{ this.applyFilterMessage(' offer online scheduling', filtersModel.onlineSchedulingProvidersCount > 0)}}
        <span *ngIf="filtersModel.onlineSchedulingProvidersCount > 0">({{ filtersModel.onlineSchedulingProvidersCount }})</span>
      </div>
    </cs-filter-dialog>

    <cs-filter-dialog
      class="filter-container"
      *ngIf="showVideoVisitsFilter"
      [filterTitle]="'Video visits'"
      [resetActive]="filtersModel.videoVisitsToggle"
      [showCloseIcon]="filtersModel.videoVisitsApplied"
      [enableFiltering]="filtersModel.videoVisitsProvidersCount > 0 && !filtersModel.videoVisitsApplied"
      [hideDialogButtons]="true"
      (selectionReset)="filtersModel.videoVisitsToggle = !filtersModel.videoVisitsToggle"
      (contentReset)="filterVideoVisits(false)"
    >
      <div class="slider-toggle-padding">
        <cs-slide-toggle
          [disabled]="filtersModel.videoVisitsProvidersCount === 0"
          [checked]="filtersModel.videoVisitsToggle"
          (keydown.enter)="filtersModel.videoVisitsProvidersCount > 0 && filterVideoVisits(filtersModel.videoVisitsToggle = !filtersModel.videoVisitsToggle)"
          (keydown.space)="filtersModel.videoVisitsProvidersCount > 0 && filterVideoVisits(filtersModel.videoVisitsToggle = !filtersModel.videoVisitsToggle)"
          (click)="filtersModel.videoVisitsProvidersCount > 0 && filterVideoVisits(filtersModel.videoVisitsToggle = !filtersModel.videoVisitsToggle)"
          (contentClosed)="closeFilterDialog()"
        >
        </cs-slide-toggle>
      </div>
      <div class="dialog-page-content p p2">
        {{ this.applyFilterMessage(' offer video visits', filtersModel.videoVisitsProvidersCount > 0 || ((!filtersModel.searchDone) &&
        LastVideoVisitsProvidersCount > 0))}}
        <span *ngIf="filtersModel.videoVisitsProvidersCount > 0">({{ filtersModel.videoVisitsProvidersCount }})</span>
        <span *ngIf="filtersModel.videoVisitsProvidersCount==0 && (!filtersModel.searchDone) && LastVideoVisitsProvidersCount > 0"
          >({{ LastVideoVisitsProvidersCount }})</span
        >
      </div>
    </cs-filter-dialog>

    <cs-filter-dialog
      class="filter-container"
      *ngIf="showAcceptingNewPatientsFilter"
      [filterTitle]="'Accepting new patients'"
      [resetActive]="filtersModel.newPatientsToggle"
      [showCloseIcon]="filtersModel.newPatientsApplied"
      [hideDialogButtons]="true"
      [enableFiltering]="filtersModel.newPatientsProvidersCount > 0 && (filtersModel.newPatientsApplied || filtersModel.newPatientsToggle)"
      (selectionReset)="filtersModel.newPatientsToggle = !filtersModel.newPatientsToggle"
      (contentReset)="filterNewPatients(false)"
    >
      <div class="slider-toggle-padding">
        <cs-slide-toggle
          [disabled]="filtersModel.newPatientsProvidersCount === 0"
          [checked]="filtersModel.newPatientsToggle"
          (keydown.enter)="filtersModel.newPatientsProvidersCount > 0 && filterNewPatients(filtersModel.newPatientsToggle = !filtersModel.newPatientsToggle)"
          (keydown.space)="filtersModel.newPatientsProvidersCount > 0 && filterNewPatients(filtersModel.newPatientsToggle = !filtersModel.newPatientsToggle)"
          (click)="filtersModel.newPatientsProvidersCount > 0 && filterNewPatients(filtersModel.newPatientsToggle = !filtersModel.newPatientsToggle)"
          (contentClosed)="closeFilterDialog()"
        >
        </cs-slide-toggle>
      </div>
      <div class="dialog-page-content p p2">
        {{ this.applyFilterMessage(' accept new patients', filtersModel.newPatientsProvidersCount > 0)}}
        <span *ngIf="filtersModel.newPatientsProvidersCount > 0">({{ filtersModel.newPatientsProvidersCount }})</span>
      </div>
    </cs-filter-dialog>

    <cs-filter-dialog
      class="filter-container"
      *ngIf="showAvailabilityFilter"
      [filterTitle]="filtersModel.availabilityTitle"
      [resetActive]="filtersModel.availabilitySelect !== AvailabilitySelect.None"
      [showCloseIcon]="filtersModel.providerAvailability !== AvailabilitySelect.None"
      (selectionReset)="filtersModel.availabilitySelect = AvailabilitySelect.None"
      (contentReset)="filterAvailability(false)"
      (applyFilters)="filterAvailability(true)"
    >
      <div class="dialog-page-content availability-list" tabindex="0" role="dialog" aria-label="Availability List">
        <cs-availability-dialog [filtersModel]="filtersModel"> </cs-availability-dialog>
      </div>
    </cs-filter-dialog>

    <cs-filter-dialog
      [filterTitle]="filtersModel.specialtyFilterText"
      [resetActive]="filtersModel.selectedSpecialties.length > 0"
      [showCloseIcon]="filtersModel.providerSpecialties.length > 0"
      (selectionReset)="specialtyReset()"
      (contentReset)="filterSpecialties(false)"
      (applyFilters)="filterSpecialties(true)"
      class="filter-container"
      (showDialogClick)="initializeSpecialtyDialog()"
      *ngIf="showSpecialtyFilter"
    >
      <div class="dialog-page-content specialty-list" role="dialog" aria-label="List of Specialities">
        <div
          tabindex="0"
          role="checkbox"
          [attr.aria-checked]="checkSpecialty(specialty) === true"
          *ngFor="let specialty of filtersModel.availableSpecialties"
          class="toggle-col-option"
          (keydown.enter)="toggleSpecialty(specialty)"
          (click)="toggleSpecialty(specialty)"
        >
          <span class="option-checkbox">
            <svg class="unchecked" *ngIf="!checkSpecialty(specialty)" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <rect rx="2.5" width="19" height="19" x="0.5" y="0.5" />
            </svg>
            <svg class="checked" *ngIf="checkSpecialty(specialty)" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <rect rx="3" />
              <path d="M10.0001 15L4.16675 9.16663L5.83341 7.49996L10.0001 11.6666L20.0001 1.66663V4.99996L10.0001 15Z" />
            </svg>
          </span>
          {{ specialty.name }}&nbsp;<span class="option-count">({{ specialty.count }})</span>
        </div>
      </div>
    </cs-filter-dialog>

    <div class="filter-container" *ngIf="showMedicalGroupFilter && !multipleMedicalGroupSelection">
      <cs-filter-dialog
        [filterTitle]="filtersModel.medicalGroupTitle"
        [resetActive]="filtersModel.selectedMedicalGroup && filtersModel.selectedMedicalGroup.length > 0"
        [showCloseIcon]="filtersModel.providerMedicalGroup && filtersModel.providerMedicalGroup.length > 0"
        (selectionReset)="clearMedGroupFilters();"
        (contentReset)="filterMedicalGroup(false)"
        (applyFilters)="filterMedicalGroup(true)"
        (buttonHover)="isHoveringOnMedGroup = $event"
      >
        <div tabindex="0" role="dialog" aria-label="List of Medical groups" class="dialog-page-content p p2">
          <div *ngIf="medicalGroupToolTip !== ''">{{ medicalGroupToolTip }}</div>
          <dhcl-dropdown
            [placeholder]="selectedMedicalGroupName??'Select Medical group'"
            [dropdowns]="filtersModel.medicalGroupList"
            [selectedValue]="selectedMedicalGroupName"
            (changed)="selectMedGroup($event)"
          >
          </dhcl-dropdown>
        </div>
      </cs-filter-dialog>
      <span class="medical-group-filter-tooltip" *ngIf="isHoveringOnMedGroup && medicalGroupHoverText !== ''">
        <p class="p p2">{{ medicalGroupHoverText }}</p>
      </span>
    </div>

    <cs-filter-dialog
      [filterTitle]="filtersModel.medicalGroupTitle"
      [resetActive]="filtersModel.selectedMedicalGroup && filtersModel.selectedMedicalGroup.length > 0"
      [showCloseIcon]="filtersModel.providerMedicalGroup && filtersModel.providerMedicalGroup.length > 0"
      (selectionReset)="clearMedGroupFilters()"
      (contentReset)="filterMedicalGroups(false)"
      (applyFilters)="filterMedicalGroups(true)"
      class="filter-container"
      (showDialogClick)="initializeMedicalGroupsDialog()"
      *ngIf="showMedicalGroupFilter && multipleMedicalGroupSelection"
    >
      <div class="dialog-page-content specialty-list" role="dialog" aria-label="List of Medical groups">
        <div
          tabindex="0"
          role="checkbox"
          [attr.aria-checked]="checkMedgroup(medgroupItem) === true"
          *ngFor="let medgroupItem of filtersModel.medicalGroupList"
          class="toggle-col-option"
          (click)="toggleMedgroup(medgroupItem)"
          (keydown.enter)="toggleMedgroup(medgroupItem)"
        >
          <span class="option-checkbox">
            <svg class="unchecked" *ngIf="!checkMedgroup(medgroupItem)" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <rect rx="2.5" width="19" height="19" x="0.5" y="0.5" />
            </svg>
            <svg class="checked" *ngIf="checkMedgroup(medgroupItem)" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <rect rx="3" />
              <path d="M10.0001 15L4.16675 9.16663L5.83341 7.49996L10.0001 11.6666L20.0001 1.66663V4.99996L10.0001 15Z" />
            </svg>
          </span>
          {{ medgroupItem.label }}
        </div>
      </div>
      <span class="medical-group-filter-tooltip" *ngIf="isHoveringOnMedGroup && medicalGroupHoverText !== ''">
        <p class="p p2">{{ medicalGroupHoverText }}</p>
      </span>
    </cs-filter-dialog>

    <div class="filter-container" *ngIf="showAffiliationFilter">
      <cs-filter-dialog
        [filterTitle]="filtersModel.hospitalFilterText"
        [resetActive]="filtersModel.selectedHospitals.length > 0"
        [showCloseIcon]="filtersModel.providerHospitals.length > 0"
        (selectionReset)="filtersModel.selectedHospitals = []"
        (contentReset)="filterHospitals(false)"
        (applyFilters)="filterHospitals(true)"
        (showDialogClick)="initializeAffiliationsDialog()"
        (buttonHover)="isHoveringOnAffiliation = $event"
      >
        <div class="dialog-page-content p p2 hospital-list" tabindex="0" role="dialog" aria-label="List of Hospitals">
          <div class="hospital-tooltip" *ngIf="affiliationToolTip !== ''">{{ affiliationToolTip }}</div>
          <div
            tabindex="0"
            role="menuitemcheckbox"
            *ngFor="let hospital of filtersModel.availableHospitals"
            class="toggle-col-option"
            (click)="toggleHospital(hospital)"
            (keydown.enter)="toggleHospital(hospital)"
          >
            <span class="option-checkbox">
              <svg
                role="checkbox"
                tabindex="0"
                class="unchecked"
                *ngIf="!checkHospital(hospital)"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
                aria-checked="false"
              >
                <rect rx="2.5" width="19" height="19" x="0.5" y="0.5" />
              </svg>
              <svg
                role="checkbox"
                tabindex="0"
                class="checked"
                *ngIf="checkHospital(hospital)"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
                aria-checked="true"
              >
                <rect rx="3" />
                <path d="M10.0001 15L4.16675 9.16663L5.83341 7.49996L10.0001 11.6666L20.0001 1.66663V4.99996L10.0001 15Z" />
              </svg>
            </span>
            {{ hospital.name }}&nbsp;<span class="option-count">({{ hospital.count }})</span>
          </div>
        </div>
      </cs-filter-dialog>
      <span class="medical-group-filter-tooltip" *ngIf="isHoveringOnAffiliation && affiliationHoverText !== ''">
        <p class="p p2">{{ affiliationHoverText }}</p>
      </span>
    </div>

    <cs-filter-dialog
      class="filter-container"
      *ngIf="showGenderFilter && !useLegacyAPI"
      [filterTitle]="'Gender'"
      [resetActive]="filtersModel.genderSelect !== ''"
      [showCloseIcon]="filtersModel.providerGender !==  ''"
      (selectionReset)="filtersModel.genderSelect =''"
      (contentReset)="filterGender(false)"
      (applyFilters)="filterGender(true)"
    >
      <div class="dialog-page-content availability-list" role="dialog" aria-label="Select Gender">
        <div
          tabindex="0"
          role="radio"
          class="radio-button-option"
          (keydown.enter)="selectGender('female')"
          (click)="selectGender('female')"
          [attr.aria-checked]="filtersModel.genderSelect === 'female'"
          aria-label="Female"
        >
          <svg role="radio" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z" />
            <path
              *ngIf="filtersModel.genderSelect === 'female'"
              d="M11 16.8974C14.257 16.8974 16.8974 14.257 16.8974 11C16.8974 7.74291 14.257 5.10254 11 5.10254C7.74291 5.10254 5.10254 7.74291 5.10254 11C5.10254 14.257 7.74291 16.8974 11 16.8974Z"
            />
          </svg>
          Female&nbsp;
          <span>({{ filtersModel.femaleCount }})</span>
        </div>
        <div
          tabindex="0"
          role="radio"
          class="radio-button-option"
          (keydown.enter)="selectGender('male')"
          (click)="selectGender('male')"
          [attr.aria-checked]="filtersModel.genderSelect === 'male'"
          aria-label="Male"
        >
          <svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z" />
            <path
              *ngIf="filtersModel.genderSelect === 'male'"
              d="M11 16.8974C14.257 16.8974 16.8974 14.257 16.8974 11C16.8974 7.74291 14.257 5.10254 11 5.10254C7.74291 5.10254 5.10254 7.74291 5.10254 11C5.10254 14.257 7.74291 16.8974 11 16.8974Z"
            />
          </svg>
          Male&nbsp;
          <span>({{ filtersModel.maleCount }})</span>
        </div>
      </div>
    </cs-filter-dialog>

    <div class="filter-container" *ngIf="selectedLocation">
      <cs-filter-dialog
        *ngIf="showSearchRadiusFilter && !useLegacyAPI"
        [filterTitle]="'Search Radius'"
        [resetActive]="filtersModel.searchRadius !== FiltersModelPartialHelperLocal.getRadiusKeyForDistance(this.defaultSearchDistance)"
        [showCloseIcon]="filtersModel.providerSearchRadius !==  FiltersModelPartialHelperLocal.getRadiusKeyForDistance(defaultSearchDistance)"
        (selectionReset)="filtersModel.searchRadius =FiltersModelPartialHelperLocal.getRadiusKeyForDistance(defaultSearchDistance)"
        (contentReset)="filterSearchRadius(false)"
        (applyFilters)="filterSearchRadius(true)"
      >
        <div class="range-header">
          Only show providers within
          <div class="range-number">{{ displayRadius }}</div>
          mi of search location
        </div>
        <div class="range-slider-container">
          <input type="range" [(ngModel)]="filtersModel.searchRadius" min="0" max="100" value="filtersModel.searchRadius" step="20" />
          <div
            class="range-slider-fill"
            [ngStyle]="{
            background:
            'linear-gradient(90deg, var(--dhcl-color-secondary) 0%, var(--dhcl-color-secondary) ' +
            filtersModel.searchRadius +
            '%, #e3e1dc ' +
            filtersModel.searchRadius +
            '%)'
        }"
          ></div>
          <div class="range-indicators">
            <div class="first">1</div>
            <div class="middle">
              <div>5</div>
              <div>10</div>
              <div>25</div>
              <div>50</div>
            </div>
            <div class="last">100</div>
          </div>
        </div>
      </cs-filter-dialog>
    </div>

    <cs-filter-dialog
      [filterTitle]="'Languages'"
      [resetActive]="filtersModel.selectedLanguages.length > 0 && filtersModel.availableLanguages.length > 0"
      [showCloseIcon]="filtersModel.providerLanguages.length > 0"
      (selectionReset)="filtersModel.selectedLanguages = []"
      (contentReset)="filterLanguages(false)"
      (applyFilters)="filterLanguages(true)"
      class="filter-container"
      (showDialogClick)="initializeLanguageDialog()"
      *ngIf="showLanguageFilter && !useLegacyAPI"
    >
      <div class="dialog-page-content specialty-list" role="dialog" aria-label="List of Available Languages">
        <div
          tabindex="0"
          role="checkbox"
          [attr.aria-checked]="checkLanguage(language) === true"
          *ngFor="let language of filtersModel.availableLanguages"
          class="toggle-col-option"
          (click)="toggleLanguage(language)"
          (keydown.enter)="toggleLanguage(language)"
        >
          <span class="option-checkbox">
            <svg class="unchecked" *ngIf="!checkLanguage(language)" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <rect rx="2.5" width="19" height="19" x="0.5" y="0.5" />
            </svg>
            <svg class="checked" *ngIf="checkLanguage(language)" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <rect rx="3" />
              <path d="M10.0001 15L4.16675 9.16663L5.83341 7.49996L10.0001 11.6666L20.0001 1.66663V4.99996L10.0001 15Z" />
            </svg>
          </span>
          {{ language.name }}&nbsp;<span class="option-count">({{ language.count }})</span>
        </div>
      </div>
    </cs-filter-dialog>

    <div class="filter-container more-filters" *ngIf="showMoreFilters && useLegacyAPI">
      <div class="filter-button" (click)="moreFiltersClicked()" [ngClass]="{ active: showModal || showCloseIcon}">
        More
        <svg
          class="clear-filter"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          (click)="clearFilters($event)"
          *ngIf="showCloseIcon"
        >
          <path d="M1 1L15 15" stroke="#0071C2" stroke-width="2" />
          <path d="M1 14.998L15 1" stroke="#0071C2" stroke-width="2" />
        </svg>
      </div>
      <dhcl-modal
        [alignTop]="alignTop"
        [show]="showModal"
        (closeClicked)="showModal = !showModal"
        [closeOnOverlay]="true"
        [customClass]="'desktop-filters-modal'"
      >
        <div class="dialog-title">
          <h3>More filters</h3>
        </div>
        <div class="dialog">
          <cs-accordion [title]="'Gender'" *ngIf="showGenderFilter">
            <div class="gender">
              <div class="radio-button-option" (click)="selectGender('female')">
                <svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
                  />
                  <path
                    *ngIf="filtersModel.genderSelect === 'female'"
                    d="M11 16.8974C14.257 16.8974 16.8974 14.257 16.8974 11C16.8974 7.74291 14.257 5.10254 11 5.10254C7.74291 5.10254 5.10254 7.74291 5.10254 11C5.10254 14.257 7.74291 16.8974 11 16.8974Z"
                  />
                </svg>
                Female&nbsp;
                <span>({{ filtersModel.femaleCount }})</span>
              </div>
              <div class="radio-button-option" (click)="selectGender('male')">
                <svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
                  />
                  <path
                    *ngIf="filtersModel.genderSelect === 'male'"
                    d="M11 16.8974C14.257 16.8974 16.8974 14.257 16.8974 11C16.8974 7.74291 14.257 5.10254 11 5.10254C7.74291 5.10254 5.10254 7.74291 5.10254 11C5.10254 14.257 7.74291 16.8974 11 16.8974Z"
                  />
                </svg>
                Male&nbsp;
                <span>({{ filtersModel.maleCount }})</span>
              </div>
            </div>
          </cs-accordion>

          <cs-common-more-filters
            [filtersModel]="filtersModel"
            [showInsuranceFilter]="showInsuranceFilter"
            [showLanguageFilter]="showLanguageFilter"
          ></cs-common-more-filters>

          <cs-accordion [title]="'Search radius'" *ngIf="showSearchRadiusFilter && selectedLocation">
            <div class="range-header">
              Only show providers within
              <div class="range-number">{{ displayRadius }}</div>
              mi of search location
            </div>
            <div class="range-slider-container">
              <input type="range" [(ngModel)]="filtersModel.searchRadius" min="0" max="100" value="filtersModel.searchRadius" step="20" />
              <div
                class="range-slider-fill"
                [ngStyle]="{
                  background:
                    'linear-gradient(90deg, var(--dhcl-color-secondary) 0%, var(--dhcl-color-secondary) ' +
                    filtersModel.searchRadius +
                    '%, #e3e1dc ' +
                    filtersModel.searchRadius +
                    '%)'
                }"
              ></div>
              <div class="range-indicators">
                <div class="first">1</div>
                <div class="middle">
                  <div>5</div>
                  <div>10</div>
                  <div>25</div>
                  <div>50</div>
                </div>
                <div class="last">100</div>
              </div>
            </div>
          </cs-accordion>
        </div>
        <div class="dialog-footer">
          <div
            class="clear-all-button"
            [attr.aria-disabled]="!isSelected"
            tabindex="0"
            role="button"
            aria-label="Clear all"
            [ngClass]="{ active: isSelected }"
            (click)="clearFilters()"
          >
            Clear all
          </div>
          <button dhclButton (click)="applyFilters()">Apply</button>
        </div>
      </dhcl-modal>
    </div>
    <div *ngIf="isDesktopFilterSelected" class="filter-container">
      <div
        class="clear-all-btn"
        [attr.aria-disabled]="!isAnyFilterSelected"
        tabindex="0"
        role="button"
        aria-label="Clear all filters"
        [ngClass]="{ active: isAnyFilterSelected }"
        (keydown.enter)="clearDesktopFilters()"
        (click)="clearDesktopFilters()"
      >
        Clear all filters
      </div>
    </div>
  </div>
</div>
