import { NgModule } from '@angular/core';
import {
  ButtonModule,
  DropdownModule,
  LoadingSpinnerModule,
  ModalModule,
  SortDropdownModule,
  PaginationModule,
  StarRatingModule,
  TooltipModule
} from '@dignity-health/ngx-components';

@NgModule({
  imports: [
    ButtonModule,
    ModalModule,
    DropdownModule,
    SortDropdownModule,
    StarRatingModule,
    LoadingSpinnerModule,
    PaginationModule,
    TooltipModule
  ],
  exports: [
    ButtonModule,
    ModalModule,
    DropdownModule,
    SortDropdownModule,
    StarRatingModule,
    LoadingSpinnerModule,
    PaginationModule,
    TooltipModule
  ]
})
export class DignityHealthComponentsModule {}
