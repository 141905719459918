import { Directive, Input, Renderer2, ElementRef } from '@angular/core';

@Directive({
  selector: '[csFocused]'
})
export class FocusedDirective {
  @Input()
  set csFocused(value: boolean) {
    if (value) {
      const element = this.renderer.selectRootElement(this.elementRef.nativeElement, true);
      element.scrollIntoViewIfNeeded({ behavior: 'smooth' });
    }
  }

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}
}
