import { AfterViewChecked, Component, Inject, Input, LOCALE_ID, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { formatDate, Location, ViewportScroller } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { OfficeAddress } from '../../models/office-address';
import { Provider, ProviderInterfaceHelper } from '../../models/provider';
import { ProviderImage } from '../../models/provider-image';
import { ProviderService } from '../../services/provider.service';
import { HierarchySecurityService } from '../../configs/HierarchySecurityService';
import { ProviderRatingService } from '../../services/provider-rating.service';
import { tap } from 'rxjs/operators';
import { EventPublisherService } from '../../services/event-publisher.service';
import { FadEventTypes } from '../../models/fad-event-types';
import { FadEvent } from '../../models/fad-event';
import { ConfigurationService } from '../../services/configuration.service';
import { LogService } from '../../services/log.service';
import { ProviderVideo } from 'src/app/models/provider-video';
import { hiddenContentAvailable } from '../../utils/utils';
import { ResizeService } from 'src/app/services/resize.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ProviderOffice } from 'src/app/models/provider-office';
import { OnlineBookingAvailability } from 'src/app/models/online-booking-availability';
import { AppointmentType } from 'src/app/models/appointment-type';
import { FadLinkService } from 'src/app/services/fad-link.service';
import { LogoConstants } from 'src/app/constants/logo-constants';
import { TitleTagHandlerService } from 'src/app/services/title-tag-handler.service';
import { ApiResponse } from 'src/app/utils/api-response';
import { ProviderSearchResponse } from 'src/app/services/filter.service';
import { ApiConstants } from 'src/app/constants/api-constants.new';
import { LogoDetails } from 'src/app/models/configuration';
import { RuntimeConfiguration } from 'src/app/services/configuration-injection.service';
import { CernerModalService } from 'src/app/services/cerner-modal.service';
import { BlockitSessionService } from 'src/app/services/blockit-session.service';
import { CjaTagService } from '../../services/cja-tag.service';

@Component({
  selector: 'cs-provider-details',
  templateUrl: './provider-details.component.html',
  styleUrls: ['./provider-details.component.scss']
})
export class ProviderDetailsComponent implements OnInit, OnChanges, AfterViewChecked, OnDestroy {
  @Input() npi: string;
  @Input() configurationUrl = './fad-configuration.json';
  @Input() referringUrl = '';
  showGuidedFlow: string | boolean = false;
  showNewSchedulingApp: string | boolean = false;
  showDocAsap: string | boolean = false;
  docAsapId?: string;
  providerSchedulingFeature?: boolean;
  apiResponse = false;
  errorMessage = '';
  showGuidedFlowModal = false;
  searchResultsOrigin: boolean;
  provider: Provider;
  facets: any[];
  physicianSchema: any;
  faqPageSchema: any;
  primaryAddress: OfficeAddress;
  selectedOfficeAddress: OfficeAddress;
  officeAddresses: OfficeAddress[] = [];
  config: any;
  docAsapIsChecked = false;
  isShowProviderVideo = false;
  showStarRatings = false;
  scrolledToView = false;
  disclaimer: string;
  showUpdatedProviderDetailPageUX = false;
  getToKnowDisclaimer: string;
  navList: any[] = [
    { routeName: 'About' },
    { routeName: 'Locations' },
    { routeName: 'Reviews' },
    { routeName: 'Specialties' }
  ];

  launchGuidedFlow: boolean;
  guidedFlowProvider: Provider;
  runtimeConfig: RuntimeConfiguration;
  isCaptchaEnabled: boolean;
  market: string;
  embedSource: string;
  showProviderPrefix: boolean;

  //inputs for provider comments
  ratingFooterText: string;
  ratingsFooterLinkText: string;
  ratingsFooterLink: string;
  isShowProviderReviews = false;
  showMoreLessButton: boolean;
  commentsArrayLength: number;

  //inputs for provider credetials
  educationArray: any[] = [];
  memberships: string[];

  //input for provider video
  videoId: string;

  //input for get to know
  getToKnowContent = [];
  divisionCodes: string[];
  showHospitalAffiliations = false;
  inMyOwnWordsTitle: string;
  hideExtraPhilosophyContent = true;
  showMoreLessButtonForPhilosophy = false;
  hideExtraOwnwordsContent = true;
  showMoreLessButtonForOwnwords = false;

  //input for provider hero
  providerImage: ProviderImage;
  showSuggestAnEditLink: boolean;
  logoUrls: LogoDetails[] = [];
  showProviderLogos: boolean;
  ratingTooltipText: string;
  showRatingText: boolean;
  showNumReviews: boolean;
  showStarRatingsForProviderHero: boolean;
  showStarRatingsBanner: boolean;
  useNewProviderDetailsRoute: boolean;
  providerSpecialities: string[];
  suggestAnEditLinkContent: string;
  malePlaceholder: string;
  femalePlaceholder: string;
  //inputs for LocationList component
  hideExtraContentForLocationList = true;
  showMoreLessButtonForLocationList = false;
  centerPoint: OfficeAddress;
  isDesktopViewportForLocationList = false;
  hasMoreThanOneOffice = false;

  //input for schedule an appointment
  srcUrl: SafeResourceUrl;
  officeNames: Array<string> = [];
  showTemporarilyUnavailableCopy = false;
  showCallToScheduleCopy = false;
  selectedOfficeRank: number;
  onlineSchedulingType: string;
  nonSEOSecondarySpecialities: string[];

  fadBaseURL: URL;
  enableScheduleToAppointmentButton = true;
  falBaseURL: string;
  showBacktoLoctionSearchBtn = false;
  redirectFromFAL = false;
  olsDepartmentId?: string;
  olsProviderId?: string;
  useLegacyAPI = false;
  showPrintablePdf = false;
  selfUrl: string;
  videoSchema: any;
  isCernerPage=false;
  bookingApptBaseUrl_Cerner = 'https://mocka.dignityhealth.me';
  olsBlockIt: string;
  constructor(
    public sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private providerService: ProviderService,
    private securityService: HierarchySecurityService,
    private ratingService: ProviderRatingService,
    private eventPublisherService: EventPublisherService,
    private configurationService: ConfigurationService,
    private cernerModalService: CernerModalService,
    private logService: LogService,
    public resizeService: ResizeService,
    private fadLinkServer: FadLinkService,
    @Inject(LOCALE_ID) private locale: string,
    private titleTagHandlerService: TitleTagHandlerService,
    private router: Router,
    private viewportScroller: ViewportScroller,
    private location: Location,
    private blockitService: BlockitSessionService,
    private cjaTagService: CjaTagService
  ) {
    this.useLegacyAPI = this.configurationService?.useLegacyAPI();
    this.useNewProviderDetailsRoute = this.configurationService.UseNewProviderDetailsRoute();
    this.runtimeConfig = configurationService.getRuntimeConfiguration();
    this.isCaptchaEnabled =
    document.querySelector('#captcha-enabled')?.getAttribute('value') === 'true' || configurationService.getCaptchaEnabled();
    this.market = this.configurationService.getConfigType() || '';
    this.embedSource = this.configurationService.getEmbedSource();
    this.showProviderPrefix = this.configurationService.showProviderPrefix();
  }
  ngOnDestroy(): void {
    this.titleTagHandlerService.removePageCanonicals(this.selfUrl);
    this.removeStructuredData();
  }
  removeStructuredData(): void {
    const videoSchema = document.getElementById('videoSchema');
    const providerSchema = document.getElementById('physicianSchema');
    const faqPageSchema = document.getElementById('faqPageSchema');
        if (videoSchema != null) {
          videoSchema.remove();
        }
        if (providerSchema != null) {
          providerSchema.remove();
        }
        if (faqPageSchema != null) {
          faqPageSchema.remove();
        }
  }

  ngOnInit(): void {
    this.blockitService.getBlockItURL();
    this.checkFALRedirection();
    this.showUpdatedProviderDetailPageUX = this.configurationService.getShowUpdatedProviderDetailPageUX();
    this.onlineSchedulingType = this.configurationService.getOnlineSchedulingType();
    this.renderSchedulingComponent();
    this.renderProviderDetails();
    this.initGuidedFlow();
    this.initSchedulingAppFlow();
    window.scrollTo(0, 0);
    this.disclaimer = this.configurationService.getDisclaimerCopy();
    this.divisionCodes = this.configurationService.getDivisionCodes();
    this.inMyOwnWordsTitle = this.configurationService.getInMyOwnWordsTitle();
    this.fadBaseURL = new URL(this.configurationService.getRuntimeConfiguration().fadBaseUrl);
    this.falBaseURL = this.configurationService.getRuntimeConfiguration().falBaseUrl;
    this.enableScheduleToAppointmentButton = this.onlineSchedulingType === 'GuidedFlow' ? true : false;
    this.showPrintablePdf = this.configurationService?.showPrintablePdf && this.configurationService?.showPrintablePdf();
    this.isCernerPage=this.configurationService.getCernerPage();
  }

  public navigateToSection(section: string): void {
    const sectionElement: HTMLElement = document.getElementById(section);
    sectionElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
  }

  get isMyChart(): boolean {
    return this.configurationService.getEmbedSource() != null && this.configurationService.getEmbedSource() === 'mychart';
  }

  get isCerner(): boolean {
    return this.configurationService.getEmbedSource() != null && this.configurationService.getEmbedSource() === 'cerner';
  }

  get isSchedulingVisible(): boolean {
    const urgentCareRoles: string[] = this.configurationService.getProviderRolesForUrgentCare();
    if (this.provider.roleType && this.provider.roleType !== '') {
      return !urgentCareRoles.includes(this.provider.roleType);
    } else {
      return true;
    }
  }

  ngOnChanges(): void {
    this.renderSchedulingComponent();
  }

  renderSchedulingComponent(): void {
    if (this.selectedOfficeAddress && this.provider) {
      this.filterInvalidApptTypes();
      this.showTemporarilyUnavailableCopy = this.provider.isBookOnline && !this.selectedOfficeAddress.supportsOnlineBooking;
      this.showCallToScheduleCopy = !this.provider.isBookOnline || (this.showDocAsap && !this.docAsapId);
      this.provider.offices.forEach((office: ProviderOffice) => {
        office.addresses.forEach((address: OfficeAddress) => {
          address.name = office.name;
          this.officeNames.push(office.name);

          if (!this.officeAddresses.includes(address)) {
            this.officeAddresses.push(address);
          }
        });
      });
      this.officeAddresses.sort((a: OfficeAddress, b: OfficeAddress) => a.rank - b.rank);
      this.selectedOfficeRank = this.officeAddresses.indexOf(this.selectedOfficeAddress) + 1;
    }
  }

  filterInvalidApptTypes(): void {
    (this.provider.onlineBookingAvailability || []).forEach((location: OnlineBookingAvailability) => {
      location.appointmentTypes = (location.appointmentTypes || []).filter((apptType: AppointmentType) => apptType.patientAppointmentType && apptType.patientAppointmentType !== '');

      if (location.appointmentTypes.length === 0) {
        location.availableTimes = [];
        location.nextAvailableTimes = [];
        location.supportsOnlineBooking = false;

        this.provider.offices.forEach((providerOffice: ProviderOffice) => {
          const matchingOffice = providerOffice.addresses.find((addr: OfficeAddress) => addr.id === location.officeAddressId);

          if (matchingOffice) {
            matchingOffice.supportsOnlineBooking = false;
          }
        });
      }
    });
  }

  ngAfterViewChecked(): void {
    if (sessionStorage.getItem('scrollToComments') === 'true') {
      const commentSection: HTMLElement = document.querySelector('div.provider-comments-container');
      if (commentSection && !this.scrolledToView) {
        commentSection.scrollIntoView();
        sessionStorage.removeItem('scrollToComments');
        this.scrolledToView = true;
      }
    }

    const shouldHidePhilosophyContent = this.hiddenContentAvailable('philosophy-content', '.philosophy-content');
    this.hideExtraPhilosophyContent = shouldHidePhilosophyContent;
    this.showMoreLessButtonForPhilosophy = shouldHidePhilosophyContent;

    const shouldHideOwnWordsContent = this.hiddenContentAvailable('ownwords-content', '.ownwords-content p');
    this.hideExtraOwnwordsContent = shouldHideOwnWordsContent;
    this.showMoreLessButtonForOwnwords = shouldHideOwnWordsContent;
  }

  renderProviderDetails(): void {
    const providerId = this.route.snapshot.paramMap.get('providerId');
    const regexMatch = /(\d+)/s;
    const routeNpi = regexMatch.exec(providerId);
    const mNpi = routeNpi !== null ? routeNpi && routeNpi[0] : providerId;

    this.providerService
      .getProviderDetailsFull(this.npi ?? mNpi)
      .pipe(
        tap((providerResponse: ApiResponse<ProviderSearchResponse>) => {
          let provider = providerResponse.result?.providers[0];
          const facets = providerResponse.result?.facets;
          if (provider) {
            provider = this.providerService.normalizeProviderBeforeScheduling(provider);
            this.providerEvent(provider);
          }

          this.facets = facets;
          this.provider = provider;
          const marketCodes = this.configurationService.getMarketCodes().length > 0 ? this.configurationService.getMarketCodes()[0] : null;
          const divisionCodes = this.configurationService?.getDivisionCodes().length > 0 ? this.configurationService.getDivisionCodes()[0] : null;
          const externalSystems = this.provider?.externalSystems?.length > 0
          &&
          (typeof this.provider?.externalSystems?.find(({ systemName }) => systemName.toLowerCase() == ApiConstants.olsDepartmentId) !== 'undefined'
              && typeof this.provider?.externalSystems?.find(({ systemName }) => systemName.toLowerCase() == ApiConstants.olsProviderId) !== 'undefined') ? true : false;

          const externalSystemsDH = this.provider?.externalSystems?.length > 0
            &&
            (typeof this.provider?.externalSystems?.find(({ systemName }) => systemName.toLowerCase() == ApiConstants.olsBlockIt) !== 'undefined') ? true : false;

          if (this.configurationService.getOnlineSchedulingType() === 'ThirdParty'
&& this.provider?.externalSystems?.length > 0
          && marketCodes?.toLowerCase() == 'kentucky'
          && externalSystems && this.provider?.isBookOnline) {
            this.initOlsKY();
          }
          else if (this.configurationService.getOnlineSchedulingType() === 'ThirdParty'
&& this.provider?.externalSystems?.length > 0
            && divisionCodes?.toLowerCase() == 'dignity-health'
            && externalSystemsDH) {
            this.initOlsDHForBlockIt();
            }
          else {
          this.initDocAsap();
        }
          if (this.provider) {
            this.provider.loadingRating = true;
            this.poviderRatingMap([this.npi ?? mNpi]);
            this.setProviderGenderFull();
            this.buildSeoSchema(this.provider);
            this.updateProviderFullName();
            this.cjaTagService.providerProfileViewed(this.provider);
            this.initCredentialInputs();
            this.initProviderVideo();
            this.setIsBookOnline();

            this.isShowProviderVideo = this.securityService.shouldShowVideos(this.provider);
            this.showHospitalAffiliations = this.configurationService.showHospitalAffiliations() && this.provider.hospitalNames?.length > 0;
            this.processMedicalIds();

            this.provider.acceptsVideoCalls = !!this.provider.acceptsVideoCalls;
            this.showSuggestAnEditLink = this.configurationService.showSuggestAnEdit();
            this.processImage();

            this.ratingTooltipText =
              this.securityService.getRatingsTooltipText(this.provider) ??
              this.configurationService.defaultProviderRatings.defaultRatingsTooltipText;
            this.providerSpecialities = ProviderInterfaceHelper.getProviderTopSpecialities(this.provider);
            this.nonSEOSecondarySpecialities = ProviderInterfaceHelper.getProviderNonSEOSpecialities(
              this.provider,
              this.providerSpecialities
            );
            this.processLinkAndLogo();
            this.cjaTagService.providerRatingsAndComments(this.provider);
            this.malePlaceholder = this.configurationService.malePlaceholder;
            this.femalePlaceholder = this.configurationService.femalePlaceholder;
            this.InitLocationListInputs();
          } else {
            this.errorMessage = 'Sorry, this page could not be found';
          }

          // Do this last because it makes the spinner stop and
          // our e2e tests wait for the spinner to stop before inspecting the page.
          // This was causing several timing related false test failures.
          this.apiResponse = true;
        })
      )
      .subscribe(
        (result) => result,
        () => {
          this.apiResponse = true;
          this.errorMessage = 'Sorry, something went wrong';
        }
      );

      this.searchResultsOrigin = sessionStorage.getItem('searchResultsOrigin') === 'true';
  }

  processLinkAndLogo(): void{
    this.suggestAnEditLinkContent = this.configurationService
    .suggestAnEditLinkScheme()
    ?.replace(/\{(0)\}/, this.provider.npi.toString());
  this.suggestAnEditLinkContent = this.suggestAnEditLinkContent.replace(/\{(1)\}/, this.provider.displayFullName);
  if (this.suggestAnEditLinkContent) {
    this.suggestAnEditLinkContent += this.referringUrl;
  }
  this.suggestAnEditLinkContent = encodeURI(this.suggestAnEditLinkContent);
  this.showProviderLogos = this.securityService.shouldShowLogos(this.provider);
  if (this.showProviderLogos) {
    this.processShowProviderLogos();
  }
  }

  processImage(): void {
    const imagesBySizeDecreasing = this.getResizedImages();
     const placeholderProviderImage: ProviderImage = {
      url:
        this.provider.gender?.toLowerCase() === 'male'
          ? this.configurationService.malePlaceholder
          : this.provider.gender?.toLowerCase() === 'female'
          ? this.configurationService.femalePlaceholder
          : this.configurationService.blankPlaceholder
    };

    this.providerImage = imagesBySizeDecreasing[0] ?? placeholderProviderImage;
    this.providerImage.url = this.providerImage.url?.replace(/^http:\/\//i, 'https://');
  }

  getResizedImages(): ProviderImage[] {
    const providerImagesCopy = this.provider.images?.slice();
    providerImagesCopy.sort((x: ProviderImage, y: ProviderImage) => y.width * y.height - x.width * x.height);

    return providerImagesCopy?.length > 0
        ? providerImagesCopy.filter((image: ProviderImage) => image.height === 160 && image.width === 120)
        : [];
  }

  setIsBookOnline(): void {
    if (!this.useLegacyAPI && this.configurationService.getOnlineSchedulingType() === 'none') {
      this.provider.isBookOnline = false;
    }
  }

  processShowProviderLogos(): void {
    if (
      !window.location.host.toLowerCase().includes(LogoConstants.fad) &&
      !window.location.host.toLowerCase().includes(LogoConstants.mycareteam) &&
      !window.location.host.toLowerCase().includes(LogoConstants.chart) &&
      !this.fadLinkServer.isSelfHosted() &&
      !this.falBaseURL?.toLowerCase().includes(window.location.host.toLowerCase())
    ) {
      this.logoUrls = ProviderInterfaceHelper.getProviderLogoDetailsAEM(this.provider, this.configurationService.getMedGroupCodes());
    } else {
      this.logoUrls = ProviderInterfaceHelper.getProviderLogoDetailsFAD(this.provider, this.configurationService.getMedGroupCodes());
      this.logoUrls.forEach((x) => x.url = this.fadBaseURL.origin + x.url);

    }
  }

  processMedicalIds(): void {
    this.provider.medicalGroupIds?.forEach((value: string) => {
      const medGroup = this.configurationService.getMedGroupCodes().find((x) => x.code === value);
      if (medGroup?.getToKnowContent) {
        let getToKnowContent = medGroup.getToKnowContent;
        getToKnowContent = ProviderInterfaceHelper.ProviderTokenHandler(this.provider, getToKnowContent);
        getToKnowContent = ProviderInterfaceHelper.MedGroupTokenHandler(Object.assign(medGroup), getToKnowContent);
        if (
          !window.location.host.toLowerCase().includes(LogoConstants.fad) &&
          !window.location.host.toLowerCase().includes(LogoConstants.mycareteam) &&
          !window.location.host.toLowerCase().includes(LogoConstants.chart) &&
          !this.fadLinkServer.isSelfHosted()
        ) {
          this.getToKnowContent.push({
            content: getToKnowContent,
            logo: medGroup.logoPathAEM
          });
        } else {
          this.getToKnowContent.push({
            content: getToKnowContent,
            logo: this.fadBaseURL.origin + medGroup.logoPathFAD
          });
        }
      }
    });
  }

  providerEvent(provider: Provider): void {
    this.officeAddresses = ProviderInterfaceHelper.getProviderOfficesSortedByRank(provider);
    this.primaryAddress = this.selectedOfficeAddress =
      this.officeAddresses.filter((a) => a.isPrimaryOffice === true)[0] ?? this.officeAddresses[0];

    this.eventPublisherService.publish(
      new FadEvent({
        type: FadEventTypes.ProviderLoaded,
        data: {
          displayFullName: provider.displayFullName,
          primarySpecialty: provider.primarySpecialty,
          primaryLocation: this.primaryAddress
            ? {
                city: this.primaryAddress.city,
                state: this.primaryAddress.state,
                zip: this.primaryAddress.zip
              }
            : undefined
        }
      })
    );

    if(this.configurationService.injectCanonicals()) {
      if(this.useNewProviderDetailsRoute) {
        const providerUrl = `${window.location.origin}/${provider.primarySpecialty.makeURLFriendly()}/${provider.firstName.makeURLFriendly()}-${provider.lastName.makeURLFriendly()}-${provider.npi}`;
        if(providerUrl != window.location.href) {
          const updatedProviderUrl = `${provider.primarySpecialty.makeURLFriendly()}/${provider.firstName.makeURLFriendly()}-${provider.lastName.toUrlFriendly()}-${provider.npi}`;
          this.replaceWindowLocation(updatedProviderUrl);
        }
      } else {
        const providerUrl = `${window.location.origin}/${provider.npi}/${provider.firstName.makeURLFriendly()}-${provider.lastName.makeURLFriendly()}`;
        if(providerUrl != window.location.href) {
          const updatedProviderUrl = `${provider.npi}-${provider.firstName.makeURLFriendly()}-${provider.lastName.makeURLFriendly()}`;
          this.replaceWindowLocation(updatedProviderUrl);
        }
      }
    }
    this.selfUrl = window.location.href;
    this.titleTagHandlerService.setProviderPageTitle(provider);
    this.titleTagHandlerService.setPageCanonicals(window.location.href);
  }

  replaceWindowLocation(strProviderLocation: string): void {
    this.location.replaceState(strProviderLocation);
  }

  poviderRatingMap(mNpi: string[]) {
    if (this.configurationService.acceptQueryString()) {
      if (sessionStorage.getItem('showRating') === 'true') {
        this.getRatings(mNpi);
      }
    }
    else if (this.configurationService.acceptQueryString() == undefined) {
      this.getRatings(mNpi);
    }
  }
  getRatings(mNpi: string[]) {
    this.showStarRatingsForProviderHero =
      this.securityService.shouldShowStarRatings(this.provider) ?? this.configurationService.defaultProviderRatings.defaultShowStarRatings;
    this.showStarRatingsBanner =
      this.securityService.shouldShowStarRatingsBanner(this.provider) ??
      this.configurationService.defaultProviderRatings.defaultShowStarRatingBanner;
    this.ratingService.getProviderRatings(mNpi, true).subscribe((starRatings) => {
      if (starRatings) {
        this.showStarRatings = this.securityService.shouldShowStarRatings(this.provider);
        if (this.showStarRatings) {
          this.provider.rating = starRatings[0];
        }
      }
      this.provider.loadingRating = false;
      this.showRatingText = this.securityService.shouldShowRatingText(this.provider);
      this.showNumReviews = this.securityService.shouldShowReviewCount(this.provider);
      this.initProviderCommentsInputs();
    });
  }

  buildSeoSchema(provider: Provider): void {
    const ratingList = provider?.rating?.comments.map((x) => ({
        '@type': 'Review',
        author: x.source,
        datePublished: formatDate(x.mentionTime, 'yyyy-MM-dd', this.locale),
        reviewBody: x.comment,
        reviewRating: {
          '@type': 'Rating',
          bestRating: '5',
          ratingValue: x.overallRating?.value,
          worstRating: '0'
        }
      }));
    const personalInfo = provider.personalInfo?.length > 0 ? provider.personalInfo : '';
    const healthPlanNetwork = provider.medicalGroupNames?.length > 0 ? provider.medicalGroupNames : [];
    const educationCredentials = { '@type':'EducationalOccupationalCredential' , name: provider.certifications };

    const schoolEducation = [];
    this.pushEducation(provider);
    const alumni = { '@type':'Person',name:schoolEducation };

    const providerServices = [];
    this.pushOLS(provider);
    
    const hasOfferCatalog = { '@type':'OfferCatalog',
      name: 'Appointment Services',
      itemListElement:[{ '@type':'Offer',
       itemOffered:{ '@type':'Service',
       name:providerServices } }] };
    this.physicianSchema = {
      '@context': 'https://schema.org/',
      '@type': '[Physician]',
      name: provider.displayFullName,
      descrption: personalInfo,
      url: window.location.href,
      address: {
        '@type': 'PostalAddress',
        addressLocality: this.primaryAddress?.city,
        addressRegion: this.primaryAddress?.state,
        postalCode: this.primaryAddress?.zip,
        streetAddress: this.primaryAddress?.address,
        telephone: this.primaryAddress?.phones,
        faxNumber: this.primaryAddress?.faxes,
        name: this.primaryAddress?.name
      },
      healthPlanNetworkId: healthPlanNetwork,
      hasCredential: educationCredentials,
      alumni: alumni,
      telephone: this.primaryAddress?.phones?.length > 0 ? this.primaryAddress.phones[0] : null,
      medicalSpecialty: {
        '@type': 'MedicalSpecialty',
        name: provider.specialties
      },
      image: provider.images.filter((image: ProviderImage) => image.height === 160 && image.width === 120)[0]?.url,
      award: this.provider.awards,
      isAcceptingNewPatients: provider.acceptsNewPatients,
      knowsLanguage: provider.languages,
      aggregateRating:
        this.showStarRatings && this.hasRatingsData(provider)
          ? {
              '@type': 'AggregateRating',
              bestRating: '5',
              ratingValue: provider.rating.overallRating.value,
              worstRating: '0',
              reviewCount: provider.rating.totalCommentCount
            }
          : null,
      review: this.showStarRatings && this.hasRatingsData(provider) ? ratingList : null,
      hasOfferCatalog: hasOfferCatalog
    };

    if (this.showStarRatings && this.hasRatingsData(provider)) {
      this.physicianSchema.aggregateRating = {
        '@type': 'AggregateRating',
        bestRating: '5',
        ratingValue: provider.rating.overallRating.value,
        worstRating: '0',
        reviewCount: provider.rating.totalCommentCount
      };
    }

    if (this.showStarRatings && this.hasRatingsData(provider) && ratingList.length > 0) {
      this.physicianSchema.review = ratingList;
    }
    this.displayDictionaries(provider);
    this.buildSeoSchemaCONT(provider);
  }

  pushEducation(provider: Provider): void {
    const schoolEducation = [];
    if (provider.medicalSchools != null && provider.medicalSchools.length > 0) { 
      schoolEducation.push(...provider.medicalSchools);
    }

    if (provider.residencies != null && provider.residencies.length > 0) { 
      schoolEducation.push(...provider.residencies);
    }

    if (provider.education != null && provider.education.length > 0) { 
      schoolEducation.push(...provider.education);
    }
    
    if (provider.fellowships != null && provider.fellowships.length > 0) { 
      schoolEducation.push(...provider.fellowships);
    }
    
    if (provider.internships != null && provider.internships.length > 0) { 
      schoolEducation.push(...provider.internships);
    }
  }

  pushOLS(provider: Provider): void {
    const providerServices = [];
    if (provider.acceptsVideoCalls != null && provider.acceptsVideoCalls) { 
      providerServices.push('Video Visits');
    }
    
    if (provider.onlineBookingAvailability != null && provider.onlineBookingAvailability[0]?.supportsOnlineBooking) { 
      providerServices.push('Online Scheduling');
    }
    
    if (!!provider.acceptsNewPatients != null && provider.acceptsNewPatients) { 
      providerServices.push('Accepting new patient');
    }
  }

  displayDictionaries(provider: Provider): void {
    const mainEntity = [];

    if (provider.insuranceCarriers?.length > 0) {
      mainEntity.push({
        '@type': 'Question',
        name: 'Insurance Carriers',
        acceptedAnswer: {
          '@type': 'Answer',
          text: provider.insuranceCarriers?.join(';')
        }
      });
    }

    if (provider.specialties?.length > 0) {
      mainEntity.push({
        '@type': 'Question',
        name: 'Specialties',
        acceptedAnswer: {
          '@type': 'Answer',
          text: provider.specialties?.join(';')
        }
      });
    }

    if (provider.procedures?.length > 0) {
      mainEntity.push({
        '@type': 'Question',
        name: 'Procedures Performed',
        acceptedAnswer: {
          '@type': 'Answer',
          text: provider.procedures?.join(';')
        }
      });
    }

    if (provider.conditions?.length > 0) {
      mainEntity.push({
        '@type': 'Question',
        name: 'Conditions treated',
        acceptedAnswer: {
          '@type': 'Answer',
          text: provider.conditions?.join(';')
        }
      });
    }

    if (provider.awards?.length > 0) {
      mainEntity.push({
        '@type': 'Question',
        name: 'Awards',
        acceptedAnswer: {
          '@type': 'Answer',
          text: provider.awards?.join(';')
        }
      });
    }

    this.faqPageSchema = {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity
    };
  }

  buildSeoSchemaCONT(provider: Provider): void {
    if (!document.getElementById('physicianSchema')) {
      const physicianScript = document.createElement('script');
      physicianScript.type = 'application/ld+json';
      physicianScript.id = 'physicianSchema';
      physicianScript.innerText = JSON.stringify(this.physicianSchema);
      document.getElementsByTagName('head')[0].appendChild(physicianScript);
    } else {
      document.getElementById('physicianSchema').innerText = JSON.stringify(this.physicianSchema);
    }

    if (!document.getElementById('faqPageSchema')) {
      const faqPageScript = document.createElement('script');
      faqPageScript.type = 'application/ld+json';
      faqPageScript.id = 'faqPageSchema';
      faqPageScript.innerText = JSON.stringify(this.faqPageSchema);
      document.getElementsByTagName('head')[0].appendChild(faqPageScript);
    } else {
      document.getElementById('faqPageSchema').innerText = JSON.stringify(this.faqPageSchema);
    }

    if(provider.videos?.length > 0) {
      let videoUrl = '';
      let description = `Watch this video to learn more about ${provider.displayFullName}`;
      let uploadDate = '';
      let thumbnailUrl = '';

      if(this.provider.offices.length > 0) {
        description = description + ` at ${provider.offices[0].name}`; 
      }

      provider.videos?.forEach((video: ProviderVideo) => {
        if(video.url.trim() != '') {
          videoUrl = video?.url;
          uploadDate = video?.publishedAt;
          thumbnailUrl = video?.thumbnails?.url;
        }
      });

      this.videoSchema = {
        '@context': 'https://schema.org/',
        '@type': 'VideoObject',
        embedUrl: videoUrl,
        description: description,
        name: this.provider.displayFullName,
        contentUrl: videoUrl,
        uploadDate: uploadDate,
        thumbnailUrl: thumbnailUrl
      };

      if (!document.getElementById('videoSchema')) {
        const videoPageScript = document.createElement('script');
        videoPageScript.type = 'application/ld+json';
        videoPageScript.id = 'videoSchema';
        videoPageScript.innerText = JSON.stringify(this.videoSchema);
        document.getElementsByTagName('head')[0].appendChild(videoPageScript);
      } else {
        document.getElementById('videoSchema').innerText = JSON.stringify(this.videoSchema);
      }
    }
  }
  
  setProviderGenderFull(): void {
    if (this.provider) {
      if (typeof this.provider.gender != 'undefined' && this.provider.gender?.toLowerCase() === 'm') {
        this.provider.gender = 'Male';
      } else if (typeof this.provider.gender != 'undefined' && this.provider.gender?.toLowerCase() === 'f') {
        this.provider.gender = 'Female';
      } else if (typeof this.provider.gender != 'undefined' && this.provider.gender?.toLowerCase() === 'n') {
        this.provider.gender = 'Non-Binary';
      } else if (typeof this.provider.gender != 'undefined' && this.provider.gender?.toLowerCase() === 'o') {
        this.provider.gender = 'Other';
      } else {
        this.provider.gender = null;
      }
    }
  }

  displayGetToKnowSection(): boolean {
    return (
      (this.provider.languages.length > 0 ||
        this.provider.gender.length > 0 ||
        this.provider.yearsOfExperience > 0 ||
        this.provider.philosophy.length > 0 ||
        this.provider.inMyOwnWords.length > 0 ||
        this.provider.hospitalNames?.length > 0) &&
      this.provider.displayFullName.length > 0
    );
  }

  displayCredentialsSection(): boolean {
    return (
      this.provider.medicalSchools.length > 0 ||
      this.provider.residencies.length > 0 ||
      this.provider.fellowships.length > 0 ||
      this.provider.internships.length > 0 ||
      this.provider.certifications.length > 0 ||
      this.provider.memberships?.length > 0 ||
      this.provider.education?.length > 0
    );
  }

  handleSelectedOfficeChanged(office: OfficeAddress): void {
    this.selectedOfficeAddress = office;
  }

  initGuidedFlow(): void {
    this.showGuidedFlow = this.configurationService.getOnlineSchedulingType() === 'GuidedFlow'; // Get Value Injected into App Root
  }

  initOlsKY(): void {
      this.showDocAsap = true;
      this.docAsapIsChecked = true;
      this.olsDepartmentId = typeof this.provider?.externalSystems?.find(({ systemName }) => systemName.toLowerCase() == ApiConstants.olsDepartmentId) !== 'undefined' ? this.provider?.externalSystems?.find(({ systemName }) => systemName.toLowerCase() == ApiConstants.olsDepartmentId).systemId : null;
      this.olsProviderId = typeof this.provider?.externalSystems?.find(({ systemName }) => systemName.toLowerCase() == ApiConstants.olsProviderId) !== 'undefined' ? this.provider?.externalSystems?.find(({ systemName }) => systemName.toLowerCase() == ApiConstants.olsProviderId).systemId : null;
      if (this.olsDepartmentId && this.olsProviderId)
      {
        this.srcUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          `${this.configurationService.getRuntimeConfiguration().myChartUrl}&dept=${this.olsDepartmentId.replace(/\s+/g, ' ').trim()}&id=${this.olsProviderId.replace(/\s+/g, ' ').trim()}`
        );
      }
  }

  initOlsDHForBlockIt(): void {
    this.showDocAsap = true;
    this.docAsapIsChecked = true;
    this.olsBlockIt = typeof this.provider?.externalSystems?.find(({ systemName }) => systemName.toLowerCase() == ApiConstants.olsBlockIt) !== 'undefined' ? this.provider?.externalSystems?.find(({ systemName }) => systemName.toLowerCase() == ApiConstants.olsBlockIt).systemId : null;
    if (this.olsBlockIt) {
      this.srcUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        `${this.configurationService.getRuntimeConfiguration().blockItUrl}${this.olsBlockIt}`
      );
    }
  }

  initDocAsap(): void {
    this.providerSchedulingFeature = this.configurationService.getProviderSchedulingFeature();
    if (this.configurationService.getOnlineSchedulingType() === 'ThirdParty') {
      this.showDocAsap = true;
      this.docAsapIsChecked = true;
      if (this.providerSchedulingFeature) {
       this.docAsapId = this.provider?.isBookOnline ? this.provider?.providerSchedulingId ?? null : null;
       this.logService.error('from api: ' + this.docAsapId); //Note: will be removed after testing
      } else {
        this.providerService.getDocAsapIds().subscribe(
          (docAsapIds: { npi: number; docAsapId: number }[]) => {
            const matchingProvider = docAsapIds.find((provider: { npi: number; docAsapId: number }) => this.provider.npi.toString() === provider.npi.toString());
            if (matchingProvider) {
              this.docAsapId = matchingProvider.docAsapId.toString();
            }
          },
          (err) => {
            this.logService.error(err);
            this.docAsapIsChecked = true;
          }
        );
        this.logService.error('from ids.json:' + this.docAsapId); //Note: will be removed after testing
      }
    }

    if (this.docAsapId && this.provider.ehrCode) {
      this.srcUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        `https://docasap.com/white-label/specialty_id/0/key_reason/-1/key_doc_id/${this.docAsapId}/key_level/3/key_type/INLINE/key_partner_code/${this.provider.ehrCode}/key_mobile_inline_button/0/iframeWidth/550/iframeHeight/300/key_lazy_loading/0`
      );
    }
  }

  initSchedulingAppFlow(): void {
    this.showNewSchedulingApp = this.configurationService.showNewSchedulingApp();
  }

  initProviderCommentsInputs(): void {
    this.ratingFooterText =
      this.securityService.getRatingsFooterText(this.provider) ?? this.configurationService.defaultProviderRatings.defaultRatingsFooterText;
    this.ratingsFooterLinkText =
      this.securityService.getRatingsFooterLinkText(this.provider) ??
      this.configurationService.defaultProviderRatings.defaultRatingsFooterLinkText;
    this.ratingsFooterLink =
      this.securityService.getRatingsFooterLink(this.provider) ?? this.configurationService.defaultProviderRatings.defaultRatingsFooterLink;
    this.isShowProviderReviews = this.securityService.shouldShowProviderReviews(this.provider);
    this.commentsArrayLength = this.provider.rating?.comments.length;
    this.showMoreLessButton = this.commentsArrayLength >= 6;
  }

  InitLocationListInputs(): void {
    this.showMoreLessButtonForLocationList = this.hideExtraContentForLocationList = this.officeAddresses.length > 2;
    this.isDesktopViewportForLocationList = window.innerWidth > 991;
    this.hasMoreThanOneOffice = this.officeAddresses?.length > 1;
  }

  initCredentialInputs(): void {
    if (this.useLegacyAPI) {
      this.concatValuesIntoArray('education', true);
      this.concatValuesIntoArray('fellowships', true);
      this.concatValuesIntoArray('residencies', true);
      this.concatValuesIntoArray('practicums', true);
      this.concatValuesIntoArray('internships', true);
      this.concatValuesIntoArray('medicalSchools', true);
    } else {
      this.concatValuesIntoArray('education', false);
      this.concatValuesIntoArray('fellowships', false);
      this.concatValuesIntoArray('residencies', false);
      this.concatValuesIntoArray('practicums', false);
      this.concatValuesIntoArray('internships', false);
      this.concatValuesIntoArray('medicalSchools', false);
    }
    this.memberships = this.provider.memberships?.map((p) => p.programName) ?? [];
  }

  concatValuesIntoArray(credentialType: string, legacy: boolean): void {
    switch (credentialType) {
      case 'education': {
        if (this.provider.education !== undefined && this.provider.education?.length > 0) {
          if (legacy) {
            this.educationArray = this.educationArray.concat(this.provider.education);
          } else {
            this.educationArray = this.educationArray.concat(this.provider.education?.map((a) => '<b>Professional Education:</b> ' + a));
          }
        }
        break;
      }
      case 'fellowships': {
        if (this.provider.fellowships !== undefined && this.provider.fellowships?.length > 0) {
          if (legacy) {
            this.educationArray = this.educationArray.concat(this.provider.fellowships);
          } else {
            this.educationArray = this.educationArray.concat(this.provider.fellowships?.map((a) => '<b>Fellowship:</b> ' + a));
          }
        }
        break;
      }
      case 'residencies': {
        if (this.provider.residencies !== undefined && this.provider.residencies?.length > 0) {
          if (legacy) {
            this.educationArray = this.educationArray.concat(this.provider.residencies);
          } else {
            this.educationArray = this.educationArray.concat(this.provider.residencies?.map((a) => '<b>Residency:</b> ' + a));
          }
        }
        break;
      }
      case 'practicums': {
        if (this.provider.practicums !== undefined && this.provider.practicums?.length > 0) {
          this.educationArray = this.educationArray.concat(this.provider.practicums);
        }
        break;
      }
      case 'internships': {
        if (this.provider.internships !== undefined && this.provider.internships?.length > 0) {
          this.educationArray = this.educationArray.concat(this.provider.internships);
        }
        break;
      }
      case 'medicalSchools': {
        if (this.provider.medicalSchools !== undefined && this.provider.medicalSchools?.length > 0) {
          if (legacy) {
            this.educationArray = this.educationArray.concat(this.provider.medicalSchools);
          } else {
            this.educationArray = this.educationArray.concat(this.provider.medicalSchools?.map((a) => '<b>Medical School:</b> ' + a));
          }
        }
        break;
      }
    }
  }

  initProviderVideo(): void {
    this.provider.videos?.forEach((video: ProviderVideo) => {
      if (!this.videoId) {
        if(video.url.trim() != '') {
          this.videoId = video?.url?.split('?v=')[1].substring(0, 11);
        }
      }
    });
  }

  onStateChange(provider: Provider): void {
    this.cjaTagService.providerVideoViewed(provider);
  }

  updateProviderFullName(): void {
    if (!this.configurationService.showProviderPrefix()) {
      this.provider.displayFullName = ProviderInterfaceHelper.removeProviderNamePrefix(
        this.provider.firstName,
        this.provider.lastName,
        this.provider.degree
      );
    }
  }

  hiddenContentAvailable(parentContainer: string, childContainer: string): boolean {
    return hiddenContentAvailable(parentContainer, childContainer);
  }

  hasRatingsData(provider: Provider): boolean {
    if (
      provider?.rating?.totalRatingCount >= 30 &&
      provider?.rating?.totalSurveyCount >= 30 &&
      provider?.rating?.overallRating?.value > 0 &&
      provider.rating?.overallRating?.questionRatings !== null
    ) {
      return true;
    }
    return false;
  }

  private checkFALRedirection(): void {
    const snapshot = this.route.snapshot;
    const params = { ...snapshot.queryParams };
    if (params.FALlocation) {
      window.sessionStorage.removeItem('falLocationDetailsParam');
      window.sessionStorage.setItem('falLocationDetailsParam', params.FALlocation);
      if (this.route.snapshot.queryParams.FALredirecttoFAD === 'true') {
        if (params.FALlocation !== null && params.FALlocation.length > 0) {
          this.showBacktoLoctionSearchBtn = true;
        }
        this.redirectFromFAL = true;
      }
      delete params.FALlocation;
      this.router.navigate([], { queryParams: params });
    }
  }

  scheduleProviderHandler(provider: Provider): void {
    if(this.isCernerPage)
      {
        //<------The below code is used in cerner Page to focus on Schedule Appointment Modal Popup----->
        this.eventPublisherService.publish(
          new FadEvent({
            type: FadEventTypes.PostApptDetailstoMyAppointment_Cerner,
            data: 'RescheduleAppointment'
          })
        );
      }
    this.guidedFlowProvider = this.providerService.normalizeProviderBeforeScheduling(provider);
    this.launchGuidedFlow = this.configurationService.showGuidedFlowButton();
  }

  closeScheduleModal() {
    this.launchGuidedFlow = false;
  }

  get handleNavigateToSchedulingSpa(): boolean {
    return (
      this.configurationService.getEmbedSource() === 'cerner' ||
      this.configurationService.getEmbedSource() === 'mychart' ||
      this.fadLinkServer.isSelfHosted()
    );
  }

  navigateToSchedulingSpa(event: any): void {
    window.sessionStorage.removeItem('singleSpecialty');
    if (event) {
      this.eventPublisherService.publish(
        new FadEvent({
          type: FadEventTypes.NavigateToSchedulingOnCerner,
          data: event
        })
      );
    }

    if(this.isCernerPage)
      {
        //<------The below code is used in cerner Page to get token from my-appointment-spa----->
        this.eventPublisherService.publish(
          new FadEvent({
            type: FadEventTypes.PostApptDetailstoMyAppointment_Cerner,
            data: event
          })
        );
        const tokenInfo = window.sessionStorage.getItem(
          this.runtimeConfig.Cerner_MyAppointments_OIDC_Token
        );
        if (tokenInfo) {
          
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          this.eventPublisherService.publish(
            new FadEvent({
              type: FadEventTypes.PostOidcTokentoMyAppointment_Cerner,
              data: tokenInfo
            })
          );
        }
      }
  }

  getCernerClass(className: string): string {
    return this.cernerModalService.getCernerClass(className);
  }
  
  printPage() {
    window.print();
  }
}
