import { Component, OnInit, Input } from '@angular/core';
import { Provider, ProviderInterfaceHelper } from '../../models/provider';
import { Banner } from '../../models/banner';
import { ConfigurationService } from '../../services/configuration.service';
import { MedgroupDictionary } from '../../models/configuration';
import { LogoConstants } from 'src/app/constants/logo-constants';
import { FadLinkService } from 'src/app/services/fad-link.service';

@Component({
  selector: 'cs-in-network-banner',
  templateUrl: './in-network-banner.component.html',
  styleUrls: ['./in-network-banner.component.scss']
})
export class InNetworkBannerComponent implements OnInit {
  @Input() provider: Provider;
  banner: Banner;
  divisionCode: string;
  divisionCodes: string[];
  banners: Banner[] = [];
  showProviderLogos: boolean;
  tenantCode: string;
  medGroupDict: MedgroupDictionary[] = [];

  constructor(private configurationService: ConfigurationService, private fadLinkServer: FadLinkService) {}

  ngOnInit(): void {
    this.tenantCode = this.configurationService.getTenantCode();
    this.divisionCodes = this.configurationService.getDivisionCodes();
    this.medGroupDict = this.configurationService.getMedGroupCodes();
    this.showProviderMedGroupLogos();
    this.generateBanner();
  }

  private showProviderMedGroupLogos() {
    this.showProviderLogos = this.configurationService.showMedGroupLogo();
  }

  generateBanner(): Banner[] {
    this.banners = [];
    const medGroups = [];

    if (this.divisionCodes.includes('dignity-health')) {
      this.provider.medicalGroupIds.forEach((value: string) => {
        if (medGroups.length < 1 && this.medGroupDict.find((x) => x.code === value)) {
          medGroups.push(value);
        } else {
          return;
        }
      });
    } else {
      this.medGroupDict.forEach((group) => {
        medGroups.push(group.code);
      });
    }

    medGroups.forEach((value: string) => {
      const medGroup = this.medGroupDict.find((x) => x.code === value);
      if (medGroup.banner) {
        let banner = medGroup.banner as Banner;

        if (!this.showProviderLogos || !medGroup.logoPathAEM || !medGroup.logoPathFAD) {
          banner.content = banner.content?.replace(/<img[a-zA-Z0-9!@#$%^&*)(\\" {}+=._-]+>/g, '');
        }

        if (
          !window.location.host.toLowerCase().includes(LogoConstants.fad) &&
          !window.location.host.toLowerCase().includes(LogoConstants.mycareteam) &&
          !this.fadLinkServer.isSelfHosted()
        ) {
          banner = ProviderInterfaceHelper.BannerImageTokenHandler(medGroup.logoPathAEM, banner);
          banner = ProviderInterfaceHelper.MedGroupLinkTokenHandler(banner);
          banner.bannerImage = medGroup.logoPathAEM;
        } else {
          banner = ProviderInterfaceHelper.BannerImageTokenHandler(medGroup.logoPathFAD, banner);
          banner = ProviderInterfaceHelper.MedGroupLinkTokenHandler(banner);
          banner.bannerImage = medGroup.logoPathFAD;
        }

        this.banners.push(banner);
      } else {
        this.banners = [];
      }
    });
    return this.banners;
  }
}
