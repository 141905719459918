import { ChangeDetectionStrategy, Component, Input } from '@angular/core';


@Component({
  selector: 'cs-legal-copy',
  templateUrl: './legal-copy.component.html',
  styleUrls: ['./legal-copy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LegalCopyComponent {
  @Input() legalCopy: string;
  @Input() legalCopyToolTip: string;
  @Input() legalCopyToolTipInfo = '';

  get getLegalCopyStartText(){    
    return this.legalCopy && this.legalCopy.split('__icon__')[0];
  }

  get getLegalCopyEndText(){
    return this.legalCopy && this.legalCopy.split('__icon__')[1];
  }
}
