<div class="list-container" *ngIf="listItems.length > 0" [ngClass]="listCustomClass">
  <div class="list-header fad-col-sm-12" *ngIf="sectionTitle">
    <h2>{{ sectionTitle }}<br /></h2>
  </div>
  <div class="insurance-disclaimer fad-col-sm-12" *ngIf="isInsuranceSection">
    <svg class="info-icon" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.25 8.25C16.25 3.8629 12.6371 0.25 8.25 0.25C3.83065 0.25 0.25 3.8629 0.25 8.25C0.25 12.6694 3.83065 16.25 8.25 16.25C12.6371 16.25 16.25 12.6694 16.25 8.25ZM8.25 9.8629C9.05645 9.8629 9.73387 10.5403 9.73387 11.3468C9.73387 12.1855 9.05645 12.8306 8.25 12.8306C7.41129 12.8306 6.76613 12.1855 6.76613 11.3468C6.76613 10.5403 7.41129 9.8629 8.25 9.8629ZM6.83065 4.54032C6.79839 4.31452 6.99194 4.12097 7.21774 4.12097H9.25C9.47581 4.12097 9.66935 4.31452 9.6371 4.54032L9.41129 8.92742C9.37903 9.15323 9.21774 9.28226 9.02419 9.28226H7.44355C7.25 9.28226 7.08871 9.15323 7.05645 8.92742L6.83065 4.54032Z"
      />
    </svg>
    <p>{{ insuranceCarriersDisclaimerText }}</p>
  </div>
  <div class="affiliations-description" *ngIf="isAffiliationSection">
    <p>Below is a list of facilities where this provider is credentialed and approved to perform procedures.</p>
  </div>
  <div #listContainer class="fad-col-sm-12 item-list" [ngClass]="{ 'show-less-content': hideExtraContent }">
    <div [ngClass]="{ 'item-columns': useColumns }" *ngIf="!isAffiliationSection && !isCredentialsSection && !isAwardsSection" role="list">
      <p #expandableList *ngFor="let item of listItems" role="listitem">{{ item }}</p>
      <p></p>
    </div>
    <div *ngIf="isAffiliationSection" class="item-columns">
      <p #expandableList *ngFor="let item of listItems">{{ item }}</p>
    </div>
    <div *ngIf="isCredentialsSection" class="credentials-list">
      <ul>
        <li #expandableList *ngFor="let item of listItems" [innerHtml]="item"></li>
      </ul>
    </div>
    <div [ngClass]="{ 'item-columns': useColumns }" *ngIf="isAwardsSection" class="awards-columns">
      <p #expandableList *ngFor="let item of listItems">{{ item }}</p>
    </div>
  </div>
  <div class="show-more-less" *ngIf="showMoreLessButton">
    <a href="javascript:;" (click)="toggleListExpanded()">
      {{ hideExtraContent ? 'More' : 'Less' }}
      <svg class="more-arrow" [ngClass]="{ 'less-arrow': !hideExtraContent }" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 1L10.5067 10.4933L1.01426 1" />
      </svg>
    </a>
  </div>
</div>
