import { Component, Input } from '@angular/core';
import { ConfigurationService } from '../../services/configuration.service';
import { ErrorLink } from 'src/app/models/configuration';
import { TitleTagHandlerService } from 'src/app/services/title-tag-handler.service';

@Component({
  selector: 'cs-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent {
  @Input() errorMessage = null;

  constructor(private configurationService: ConfigurationService, private titleTagHandlerService: TitleTagHandlerService) { 
    this.setTitleMetaTags();
  }

   get isDesktopViewport(): boolean {
    return window.innerWidth > 991;
  }

  get errorLinks(): ErrorLink[] {
    return this.configurationService.getErrorLinks();
  }

  setTitleMetaTags(): void {
   this.titleTagHandlerService.setErrorPageTagTitle('Error Page');
 }

}
