<div id="fad-comments" class="provider-comments-container-new" *ngIf="isUpdatedProviderDetails && !isMobileView" id="{{routId}}">
  <h2 class="patient-saying">Patient Reviews</h2>
  <div class="rating-comments">
    <div class="ratings-container">
      <p class="overall-rating">Overall rating</p>
      <p class="rating-value">{{ provider.rating.overallRating.value }}</p>
      <dhcl-star-rating
        [rating]="provider.rating.overallRating.value"
        reviews="1"
        showNumReviews="false"
        showRatingText="false"
      ></dhcl-star-rating>
      <p class="rating-count p3">{{ provider.rating?.totalRatingCount }} ratings</p>
    </div>
    <div role="list" *ngIf="provider.rating.overallRating.questionRatings" class="questionaire">
      <div role="listitem" *ngFor="let questionCommentDetail of provider.rating.overallRating.questionRatings">
        <div>
          <p class="p2">{{ questionCommentDetail.name }}</p>
          <div class="rating">
            <dhcl-star-rating
              [reviews]="questionCommentDetail.responseCount"
              [rating]="questionCommentDetail.value"
              showNumReviews="false"
            ></dhcl-star-rating>
          </div>
        </div>
      </div>
      <div class="line-break"></div>
    </div>
    <ng-container *ngTemplateOutlet="userComments"></ng-container>
  </div>
</div>
<div
  id="fad-comments"
  class="provider-comments-container"
  id="{{routId}}"
  *ngIf="!isUpdatedProviderDetails || (isUpdatedProviderDetails && isMobileView)"
>
  <h2 *ngIf="!isUpdatedProviderDetails" class="patient-saying">What patients are saying</h2>
  <h2 *ngIf="isUpdatedProviderDetails" class="patient-saying">Patient Reviews</h2>
  <div class="rating-comments">
    <div class="ratings-container" [ngClass]="{ 'center-container': isUpdatedProviderDetails }">
      <p class="overall-rating">Overall rating</p>
      <p class="rating-value">{{ provider.rating.overallRating.value }}</p>
      <dhcl-star-rating
        [rating]="provider.rating.overallRating.value"
        reviews="1"
        showNumReviews="false"
        showRatingText="false"
      ></dhcl-star-rating>
      <p class="rating-count p3">{{ provider.rating?.totalRatingCount }} ratings</p>
      <div class="divider"></div>
      <div *ngIf="provider.rating.overallRating.questionRatings">
        <div *ngFor="let questionCommentDetail of provider.rating.overallRating.questionRatings">
          <div class="overall-comment-text">
            <p class="p2">{{ questionCommentDetail.name }}</p>
            <div class="rating">
              <dhcl-star-rating
                [reviews]="questionCommentDetail.responseCount"
                [rating]="questionCommentDetail.value"
                showNumReviews="false"
              ></dhcl-star-rating>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="ratingFooterText && !isUpdatedProviderDetails" class="divider"></div>
      <div *ngIf="ratingFooterText && !isUpdatedProviderDetails" class="ratings-disclaimer">
        <svg class="info-icon" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6 0C2.68652 0 0 2.68749 0 6C0 9.31446 2.68652 12 6 12C9.3135 12 12 9.31446 12 6C12 2.68749 9.3135 0 6 0ZM6 2.66129C6.56118 2.66129 7.0161 3.11623 7.0161 3.67742C7.0161 4.23861 6.56118 4.69355 6 4.69355C5.43881 4.69355 4.98387 4.23861 4.98387 3.67742C4.98387 3.11623 5.43881 2.66129 6 2.66129ZM7.35486 8.80644C7.35486 8.96676 7.22484 9.09678 7.06452 9.09678H4.93549C4.77515 9.09678 4.64516 8.96676 4.64516 8.80644V8.22582C4.64516 8.0655 4.77515 7.93548 4.93549 7.93548H5.22581V6.38712H4.93549C4.77515 6.38712 4.64516 6.2571 4.64516 6.09678V5.51613C4.64516 5.3558 4.77515 5.22581 4.93549 5.22581H6.4839C6.64422 5.22581 6.77418 5.3558 6.77418 5.51613V7.93548H7.06452C7.22484 7.93548 7.35486 8.0655 7.35486 8.22582V8.80644Z"
            fill="#4D525A"
          />
        </svg>
        <p class="p3">
          {{ ratingFooterText }}
          <a
            *ngIf="ratingsFooterLinkText && ratingsFooterLink"
            href="{{ ratingsFooterLink }}"
            (click)="$event.preventDefault(); goToRatingFooterLink()"
            >{{ ratingsFooterLinkText }}</a
          >
        </p>
      </div>
    </div>
    <ng-container *ngTemplateOutlet="userComments"></ng-container>
  </div>
</div>
<ng-template #userComments>
  <div class="comments-section" *ngIf="isShowProviderReviews">
    <h3 class="comment-header.fad-col-sm-2">Comments ({{ provider.rating.totalCommentCount }})</h3>
    <div class="provider-comments" *ngIf="commentsArrayLength === 0">
      <p class="no-comments p2">There are currently no comments for this provider.</p>
    </div>
    <div role="list" class="provider-comments" *ngIf="commentsArrayLength > 0">
      <div role="listitem" *ngFor="let commentDetail of provider.rating.comments.slice(0, commentsSliceIndex)">
        <div
          class="rating"
          tabindex="0"
          [attr.aria-label]="(commentDetail.overallRating?.value) + ' out of 5 stars. Review from  ' + (commentDetail.source) + ' ' + (commentDetail.comment) + ' on ' + (commentDetail.mentionTime | date)"
        >
          <dhcl-star-rating
            [rating]="commentDetail.overallRating?.value"
            showRatingText="false"
            showNumReviews="false"
            reviews="1"
          ></dhcl-star-rating>
          <div style="display: flex; flex-direction: row">
            <span *ngIf="!isUpdatedProviderDetails" class="date">{{ commentDetail.mentionTime | date }}</span>
          </div>
          <div class="comment-text">
            <span><p aria-hidden="true">{{ commentDetail.comment }}</p></span>
            <div style="display: flex; flex-direction: row">
              <span
                ><p *ngIf="isUpdatedProviderDetails" class="comment-footer p2" aria-hidden="true">
                  {{ commentDetail.mentionTime | date }}
                </p></span
              >
              <span><p *ngIf="isUpdatedProviderDetails" class="comment-footer" aria-hidden="true">•</p></span>
              <span><p *ngIf="isUpdatedProviderDetails" class="comment-footer p2" aria-hidden="true">{{ commentDetail.source}}</p></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="show-more-less" *ngIf="showMoreLessButton">
      <a href="javascript:;" role="button" (click)="toggleReviewComments()">
        {{ !moreLessExpanded ? 'More' : 'Less' }}
        <svg class="more-arrow" [ngClass]="{ 'less-arrow': moreLessExpanded }" xmlns="http://www.w3.org/2000/svg">
          <path d="M20 1L10.5067 10.4933L1.01426 1" />
        </svg>
      </a>
    </div>
    <div *ngIf="ratingFooterText && isUpdatedProviderDetails" class="ratings-disclaimer-new">
      <svg class="info-icon" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6 0C2.68652 0 0 2.68749 0 6C0 9.31446 2.68652 12 6 12C9.3135 12 12 9.31446 12 6C12 2.68749 9.3135 0 6 0ZM6 2.66129C6.56118 2.66129 7.0161 3.11623 7.0161 3.67742C7.0161 4.23861 6.56118 4.69355 6 4.69355C5.43881 4.69355 4.98387 4.23861 4.98387 3.67742C4.98387 3.11623 5.43881 2.66129 6 2.66129ZM7.35486 8.80644C7.35486 8.96676 7.22484 9.09678 7.06452 9.09678H4.93549C4.77515 9.09678 4.64516 8.96676 4.64516 8.80644V8.22582C4.64516 8.0655 4.77515 7.93548 4.93549 7.93548H5.22581V6.38712H4.93549C4.77515 6.38712 4.64516 6.2571 4.64516 6.09678V5.51613C4.64516 5.3558 4.77515 5.22581 4.93549 5.22581H6.4839C6.64422 5.22581 6.77418 5.3558 6.77418 5.51613V7.93548H7.06452C7.22484 7.93548 7.35486 8.0655 7.35486 8.22582V8.80644Z"
          fill="#0071C2"
        />
      </svg>
      <p class="p3">
        {{ ratingFooterText }}
        <a
          *ngIf="ratingsFooterLinkText && ratingsFooterLink"
          href="{{ ratingsFooterLink }}"
          (click)="$event.preventDefault(); goToRatingFooterLink()"
          >{{ ratingsFooterLinkText }}</a
        >
      </p>
    </div>
    <div *ngIf="ratingFooterText && isUpdatedProviderDetails" class="divider"></div>
  </div>
</ng-template>
