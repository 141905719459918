<div role="radiogroup" class="dialog-container">
  <div
    tabindex="0"
    role="radio"
    class="radio-button-option"
    (click)="selectAvailability(AvailabilitySelect.Today)"
    (keydown.enter)="selectAvailability(AvailabilitySelect.Today)"
    [attr.aria-checked]="filtersModel.availabilitySelect === AvailabilitySelect.Today"
  >
    <svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
      <path d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z" />
      <path
        *ngIf="filtersModel.availabilitySelect === AvailabilitySelect.Today"
        d="M11 16.8974C14.257 16.8974 16.8974 14.257 16.8974 11C16.8974 7.74291 14.257 5.10254 11 5.10254C7.74291 5.10254 5.10254 7.74291 5.10254 11C5.10254 14.257 7.74291 16.8974 11 16.8974Z"
      />
    </svg>
    Today&nbsp;
    <span>({{ filtersModel.todayCount }})</span>
  </div>
  <div
    tabindex="0"
    role="radio"
    class="radio-button-option"
    (click)="selectAvailability(AvailabilitySelect.Next7Days)"
    (keydown.enter)="selectAvailability(AvailabilitySelect.Next7Days)"
    [attr.aria-checked]="filtersModel.availabilitySelect === AvailabilitySelect.Next7Days"
  >
    <svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
      <path d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z" />
      <path
        *ngIf="filtersModel.availabilitySelect === AvailabilitySelect.Next7Days"
        d="M11 16.8974C14.257 16.8974 16.8974 14.257 16.8974 11C16.8974 7.74291 14.257 5.10254 11 5.10254C7.74291 5.10254 5.10254 7.74291 5.10254 11C5.10254 14.257 7.74291 16.8974 11 16.8974Z"
      />
    </svg>
    Next 7 days&nbsp;
    <span>({{ filtersModel.next7Count }})</span>
  </div>
  <div
    tabindex="0"
    role="radio"
    class="radio-button-option"
    (click)="selectAvailability(AvailabilitySelect.Next14Days)"
    (keydown.enter)="selectAvailability(AvailabilitySelect.Next14Days)"
    [attr.aria-checked]="filtersModel.availabilitySelect === AvailabilitySelect.Next14Days"
  >
    <svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
      <path d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z" />
      <path
        *ngIf="filtersModel.availabilitySelect === AvailabilitySelect.Next14Days"
        d="M11 16.8974C14.257 16.8974 16.8974 14.257 16.8974 11C16.8974 7.74291 14.257 5.10254 11 5.10254C7.74291 5.10254 5.10254 7.74291 5.10254 11C5.10254 14.257 7.74291 16.8974 11 16.8974Z"
      />
    </svg>
    Next 14 days&nbsp;
    <span>({{ filtersModel.next14Count }})</span>
  </div>
  <div
    tabindex="0"
    role="radio"
    class="radio-button-option"
    (click)="selectAvailability(AvailabilitySelect.Next30Days)"
    (keydown.enter)="selectAvailability(AvailabilitySelect.Next30Days)"
    [attr.aria-checked]="filtersModel.availabilitySelect === AvailabilitySelect.Next30Days"
  >
    <svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
      <path d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z" />
      <path
        *ngIf="filtersModel.availabilitySelect === AvailabilitySelect.Next30Days"
        d="M11 16.8974C14.257 16.8974 16.8974 14.257 16.8974 11C16.8974 7.74291 14.257 5.10254 11 5.10254C7.74291 5.10254 5.10254 7.74291 5.10254 11C5.10254 14.257 7.74291 16.8974 11 16.8974Z"
      />
    </svg>
    Next 30 days&nbsp;
    <span>({{ filtersModel.next30Count }})</span>
  </div>
</div>
