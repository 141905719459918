import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, OnChanges } from '@angular/core';
import { FacetSummary, FiltersModel, FiltersModelPartialHelper, MedicalGroup } from '../../models/filters';
import { SelectedLocation } from '../../models/location';
import { SortingOption } from '../../models/configuration';
import { ConfigurationService } from '../../services/configuration.service';
import { ResizeService } from '../../services/resize.service';
import { AvailabilitySelect } from '../../models/availability-select.enum';

@Component({
  selector: 'cs-mobile-filters',
  templateUrl: './mobile-filters.component.html',
  styleUrls: ['./mobile-filters.component.scss']
})
export class MobileFiltersComponent implements OnInit, OnChanges {
  @Input() filtersModel: FiltersModel;
  @Input() selectedLocation: SelectedLocation;
  @Input() buttonTitle = 'Filters';
  @Input() moreFilters = false;

  @Input() defaultSearchDistanceMobile: number;
  @Input() defaultSearchDistanceWithMG: number;

  @Input() showOnlineSchedulingFilter: boolean;
  @Input() showVideoVisitsFilter: boolean;
  @Input() showAcceptingNewPatientsFilter: boolean;
  @Input() showAvailabilityFilter: boolean;
  @Input() showSpecialtyFilter: boolean;
  @Input() showAffiliationFilter: boolean;
  @Input() affiliationToolTip: string;
  @Input() affiliationHoverText: string;
  @Input() showMedicalGroupFilter: boolean;
  @Input() showGenderFilter: boolean;
  @Input() showSearchRadiusFilter: boolean;
  @Input() showInsuranceFilter: boolean;
  @Input() showLanguageFilter: boolean;
  @Input() alignTop: boolean;
  @Input() multipleMedicalGroupSelection: boolean;
  @Input() showInsuranceAcceptedFilter: boolean;
  @Input() isDignityHealthDivision: boolean;
  @Input() showOnlineSchedulingAllVisitFilter: boolean;

  @Output() filtersModelChange = new EventEmitter<FiltersModel>();
  showModal = false;
  medicalGroupToolTip: string;
  medicalGroupHoverText: string;
  AvailabilitySelect = AvailabilitySelect;
  showSortAndFilters: boolean;
  selectedSortBy: number;
  sortDropdowns: SortingOption[];
  showSortAndLeftFilterMobile: boolean;
  showLocationName = true;
  onlineSchedulingbuttonText: string;
  videoVisitButtonText: string;
  selectedMedicalGroupName = '';
  isVMFHMarket:boolean;
  isMobileCernerPage=false;

  get isTabletViewport() {
    return window.innerWidth > 419 && window.innerWidth < 1025;
  }

  get displayRadius() {
    return FiltersModelPartialHelper.getDistanceForSearchRadiusKey(this.filtersModel);
  }

  get showCloseIcon() {
    return (
      this.filtersModel.onlineSchedulingApplied ||
      this.filtersModel.videoVisitsApplied ||
      this.filtersModel.newPatientsApplied ||
      this.filtersModel.providerMedicalGroup?.length > 0 ||
      this.filtersModel.providerAvailability !== AvailabilitySelect.None ||
      (this.filtersModel.providerSpecialties && this.filtersModel.providerSpecialties.length > 0) ||
      (this.filtersModel.providerHospitals && this.filtersModel.providerHospitals.length > 0) ||
      FiltersModelPartialHelper.isDesktopFiltersSelected(
        this.filtersModel,
        this.selectedLocation,
        this.defaultSearchDistanceMobile,
        this.defaultSearchDistanceWithMG,
        this.showSearchRadiusFilter,
        this.showInsuranceFilter
      )
    );
  }

  get isFilterSelected() {
    return (
      this.filtersModel.onlineSchedulingToggle ||
      this.filtersModel.videoVisitsToggle ||
      this.filtersModel.newPatientsToggle ||
      this.filtersModel.availabilitySelect !== AvailabilitySelect.None ||
      (this.filtersModel.selectedSpecialties && this.filtersModel.selectedSpecialties.length > 0) ||
      (this.filtersModel.selectedHospitals && this.filtersModel.selectedHospitals.length > 0) ||
      this.filtersModel.selectedMedicalGroup ||
      FiltersModelPartialHelper.isMoreFiltersSelected(
        this.filtersModel,
        this.selectedLocation,
        this.defaultSearchDistanceMobile,
        this.defaultSearchDistanceWithMG,
        this.showSearchRadiusFilter,
        this.showInsuranceFilter,
        this.showMedicalGroupFilter
      )
    );
  }

  get dialogHeight() {
    return this.isMobileCernerPage?430:(window.innerHeight - 163);
  }

  constructor(public resizeService: ResizeService, private configService: ConfigurationService) { }

  ngOnInit(): void {
    this.medicalGroupToolTip = this.configService.getMedicalGroupFilterToolTipText();
    this.medicalGroupHoverText = this.configService.getMedicalGroupFilterHoverText();

    this.affiliationToolTip = this.configService.getHospitalAffiliationFilterToolTipText();
    this.affiliationHoverText = this.configService.getHospitalAffiliationFilterHoverText();

    this.defaultSearchDistanceMobile = this.configService.getDefaultSearchDistance();
    this.defaultSearchDistanceWithMG = this.configService.getDefaultSearchDistanceWhenMedGroupFilterApplied();

    this.showOnlineSchedulingFilter = this.configService.showOnlineSchedulingFilter();
    this.showVideoVisitsFilter = this.configService.showVideoVisitsFilter();
    this.showAcceptingNewPatientsFilter = this.configService.showAcceptingNewPatientsFilter();
    this.showAvailabilityFilter = this.configService.showAvailabilityFilter();
    this.showSpecialtyFilter = this.configService.showSpecialtyFilter();
    this.showAffiliationFilter = this.configService.showAffiliationFilter();
    this.showMedicalGroupFilter = this.configService.showMedicalGroupFilter();
    this.showGenderFilter = this.configService.showGenderFilter();
    this.showSearchRadiusFilter = this.configService.showSearchRadiusFilter();
    this.showSortAndFilters = this.configService.getIsFiltersLeftSidePanelRequired();
    this.sortDropdowns = this.configService.getSortingConfig().options;
    this.onlineSchedulingbuttonText = this.isDignityHealthDivision ? 'Online scheduling-new patients' : 'Online scheduling';
    this.videoVisitButtonText = this.isDignityHealthDivision ? 'Online scheduling-video visits' : 'Video visits';
    this.isVMFHMarket = this.configService.getMarketCodes().includes('vmfh');
    this.isMobileCernerPage=this.configService.getCernerPage();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.filtersModel.currentValue.sortBy) {
      this.configService.getSortingConfig().options.forEach(option => {
        if (option.queryParam === this.filtersModel.sortBy) {
          this.selectedSortBy = option.value;
        }
      });
    }
  }

  applyFilters() {
    this.filtersModel.onlineSchedulingApplied = this.filtersModel.onlineSchedulingToggle;
    this.filtersModel.videoVisitsApplied = this.filtersModel.videoVisitsToggle;
    this.filtersModel.onlineSchedulingAllVisitApplied = this.filtersModel.onlineSchedulingAllVisitToggle;
    this.filtersModel.newPatientsApplied = this.filtersModel.newPatientsToggle;
    this.filtersModel.providerAvailability = this.filtersModel.availabilitySelect;
    this.filtersModel.providerSpecialties = [...this.filtersModel.selectedSpecialties];
    this.filtersModel.providerHospitals = [...this.filtersModel.selectedHospitals];
    this.filtersModel.providerMedicalGroup = this.filtersModel.selectedMedicalGroup;
    this.filtersModel.providerGender = this.filtersModel.genderSelect;
    this.filtersModel.providerInsurances = [...this.filtersModel.selectedInsurances];
    this.filtersModel.providerLanguages = [...this.filtersModel.selectedLanguages];

    if (this.filtersModel.providerMedicalGroup.length > 0) {
      this.filtersModel.providerSearchRadius = this.filtersModel.searchRadius = 
      this.filtersModel.searchRadius ?? FiltersModelPartialHelper.getRadiusKeyForDistance(this.defaultSearchDistanceWithMG);
    } else {
      this.filtersModel.providerSearchRadius = this.filtersModel.searchRadius =
        this.filtersModel.searchRadius ?? FiltersModelPartialHelper.getRadiusKeyForDistance(this.defaultSearchDistanceMobile);
    }

    this.showModal = false;
    this.filtersModelChange.emit(this.filtersModel);
  }

  clearFilters(event: any = null) {
    window.sessionStorage.removeItem('singleSpecialty');
    this.selectedMedicalGroupName = 'Select Medical group';
    this.filterLocationName();
    if (event) {
      event.stopPropagation();

      this.filtersModel.onlineSchedulingToggle = false;
      this.filtersModel.videoVisitsToggle = false;
      this.filtersModel.onlineSchedulingAllVisitToggle = false;
      this.filtersModel.newPatientsToggle = false;
      this.filtersModel.availabilitySelect = AvailabilitySelect.None;
      this.filtersModel.selectedSpecialties = [];
      this.filtersModel.selectedHospitals = [];
      this.filtersModel.selectedMedicalGroup = [];
      FiltersModelPartialHelper.toClearFilters(this.filtersModel, true, this.defaultSearchDistanceMobile, true);

      this.filtersModel.onlineSchedulingApplied = this.filtersModel.onlineSchedulingToggle;
      this.filtersModel.videoVisitsApplied = this.filtersModel.videoVisitsToggle;
      this.filtersModel.onlineSchedulingAllVisitApplied = this.filtersModel.onlineSchedulingAllVisitToggle;
      this.filtersModel.newPatientsApplied = this.filtersModel.newPatientsToggle;
      this.filtersModel.providerAvailability = this.filtersModel.availabilitySelect;
      this.filtersModel.providerSpecialties = [...this.filtersModel.selectedSpecialties];
      this.filtersModel.providerHospitals = [...this.filtersModel.providerHospitals];
      this.filtersModel.providerMedicalGroup = this.filtersModel.selectedMedicalGroup;
      if (this.filtersModel.providerMedicalGroup.length == 0) {
        this.filtersModel.medicalGroupTitle = 'Medical group';
        if (sessionStorage.getItem('selectedMedicalGroup') || sessionStorage.getItem('medGroupQueryParam')) {
          sessionStorage.setItem('selectedMedicalGroup', 'null');
          sessionStorage.setItem('medGroupQueryParam', 'null');
        }
      }
      this.filtersModel.providerSearchRadius = this.filtersModel.searchRadius;
      this.filtersModelChange.emit(this.filtersModel);
    } else {
      this.filtersModel.onlineSchedulingToggle = false;
      this.filtersModel.videoVisitsToggle = false;
      this.filtersModel.onlineSchedulingAllVisitToggle = false;
      this.filtersModel.newPatientsToggle = false;
      this.filtersModel.availabilitySelect = AvailabilitySelect.None;
      this.filtersModel.selectedSpecialties = [];
      this.filtersModel.selectedHospitals = [];
      this.filtersModel.selectedMedicalGroup = [];
      this.filtersModel.providerMedicalGroup = [];
      this.filtersModel.searchRadius = FiltersModelPartialHelper.getRadiusKeyForDistance(this.defaultSearchDistanceMobile);
      FiltersModelPartialHelper.toClearFilters(this.filtersModel, false, this.defaultSearchDistanceMobile, true);
    }
  }

  toggleSpecialty(specialty: any) {
    FiltersModelPartialHelper.SpecialityToggle(specialty, this.filtersModel);
  }

  toggleMedgroup(medgroup: any) {
    FiltersModelPartialHelper.MedgroupToggle(medgroup, this.filtersModel);
  }

  checkSpecialty(specialty: any): boolean {
    return this.filtersModel?.selectedSpecialties?.map((n) => n.name?.toLowerCase()).includes(specialty?.name.toLowerCase()) ?? false;
  }

  checkMedgroup(medgroup: any): boolean {
    return this.filtersModel?.selectedMedicalGroup?.map((n) => n.name?.toLowerCase()).includes(medgroup?.label.toLowerCase()) ?? false;
  }

  toggleHospital(hospital: any) {
    FiltersModelPartialHelper.HospitalToggle(hospital, this.filtersModel);
  }

  checkHospital(hospital: any): boolean {
    return this.filtersModel?.selectedHospitals?.map((n) => n.name?.toLowerCase()).includes(hospital?.name.toLowerCase()) ?? false;
  }

  toggleInsurance(insurance: any) {
    FiltersModelPartialHelper.InsuranceToggle(insurance, this.filtersModel);
  }
  
  checkInsurance(insurance: any): boolean {
    return this.filtersModel?.selectedInsurances?.map((n) => n.name?.toLowerCase()).includes(insurance.name?.toLowerCase()) ?? false;
  }

  selectGender(gender: string) {
    FiltersModelPartialHelper.genderSelect(this.filtersModel, gender);
  }

  filtersClicked() {
    this.showModal = true;

    if (this.filtersModel) {
      this.filtersModel.selectedHospitals = [...this.filtersModel.selectedHospitals];
      this.filtersModel.selectedSpecialties = [...this.filtersModel.selectedSpecialties];
      this.filtersModel.selectedInsurances = [...this.filtersModel.selectedInsurances];
      this.filtersModel.selectedLanguages = [...this.filtersModel.selectedLanguages];
    }
  }
  
  selectMedicalGroup(medGroup)
  {
    this.filtersModel.selectedMedicalGroup = [];
    const tempGroup: MedicalGroup = medGroup as unknown as MedicalGroup;
    const selectedMedgroup: FacetSummary = {
      name: tempGroup.label,
      count: 0,
      selected: true
    };
    this.filtersModel.selectedMedicalGroup.push(selectedMedgroup);
    this.selectedMedicalGroupName = selectedMedgroup.name;
  }

  handleSortChange(event: SortingOption): void {
    this.selectedSortBy = event.value;
    this.filtersModel.sortByNumber = this.selectedSortBy;
    this.filtersModel.sortBy = event.queryParam;
  }

  showMobileFiltersForLeftDesign(): boolean {
    return (!this.resizeService.isDesktopViewport); 
  }

  filterLocationName(): void {
    this.showLocationName = false;
    this.filtersModel.locationName = '';
  }
}
