import { UrlBuilderParams } from './url-builder-params';
import { FadUrlBuilderStrategy } from './fad-url-builder-strategy';
import { ConfigurationService } from '../../services/configuration.service';

export class SpecialtyUrlBuilderStrategy extends FadUrlBuilderStrategy {

  
  useLegacyAPI = false;
  constructor(private configurationService: ConfigurationService)
  {
    super();
    this.useLegacyAPI = this.configurationService.useLegacyAPI();
  }
  
  shouldBuild(urlBuilderParams: UrlBuilderParams): boolean {
    return !!urlBuilderParams.specialty;
  }

  createUrlSegments(urlBuilderParams: UrlBuilderParams): void {
    this.addSegments('', 's');
  
    let speciality = urlBuilderParams.specialty;  
   
    if(!this.useLegacyAPI)
    {
      speciality = speciality.toLocaleLowerCase();
    }

    this.addSpecialtySegment(speciality);
   
    if (urlBuilderParams.selectedLocation) {
      this.addCityAndStateSegment(urlBuilderParams.selectedLocation);
    }
  }
}
