import { Component, Input, Output, ViewChild, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { YouTubePlayer } from '@angular/youtube-player';
import { Provider } from '../../models/provider';

@Component({
  selector: 'cs-you-tube-player',
  templateUrl: './you-tube-player.component.html',
  styleUrls: ['./you-tube-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class YouTubePlayerComponent {
  @Input() videoId: any;
  @Input() provider: Provider;
  @ViewChild('player') player: YouTubePlayer;
  playerVars: YT.PlayerVars;
  isAnalyticsTriggered = false;
  @Output() callAdobeService = new EventEmitter<Provider>();
  constructor() {
    this.init();
  }

  init(): void {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }

  onStateChange(event: YT.OnStateChangeEvent) {
    if (event.data === 1 && !this.isAnalyticsTriggered) {
      this.callAdobeService.emit(this.provider);
      this.isAnalyticsTriggered = true;
    }
  }
}
