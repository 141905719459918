import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { FadEvent } from 'src/app/models/fad-event';
import { FadEventTypes } from 'src/app/models/fad-event-types';
import { ProviderOffice } from 'src/app/models/provider-office';
import { EventPublisherService } from 'src/app/services/event-publisher.service';
import { FadLinkService } from 'src/app/services/fad-link.service';
import { AppointmentTime } from '../../models/appointment-time';
import { OfficeAddress } from '../../models/office-address';
import { Provider } from '../../models/provider';
import { RuntimeConfiguration } from '../../services/configuration-injection.service';
import { ConfigurationService } from '../../services/configuration.service';

declare const ga: any;

@Component({
  selector: 'cs-schedule-an-appointment',
  templateUrl: './schedule-an-appointment.component.html',
  styleUrls: ['./schedule-an-appointment.component.scss']
})
export class ScheduleAnAppointmentComponent implements OnInit{
  @Input() provider: Provider;
  @Input() selectedOfficeAddress: OfficeAddress;
  @Input() showGuidedFlow = false;
  @Input() docAsapId?: string;
  @Input() srcUrl?: SafeResourceUrl;
  @Input() showDocAsap = false;
  @Input() facets: any[] = [];
  @Input() showNewSchedulingApp = false;
  @Input() enableScheduleToAppointmentButton = true;
  @Output() selectedOfficeChanged = new EventEmitter<any>();
  @Input() onlineSchedulingType: string;
  @Input() olsProviderId?: string;
  @Input() olsDepartmentId?: string;
  @Input() olsBlockIt?: string;

  runtimeConfig: RuntimeConfiguration;
  officeAddresses: OfficeAddress[] = [];
  officeNames: Array<string> = [];
  showTemporarilyUnavailableCopy = false;
  showCallToScheduleCopy = false;
  selectedOfficeRank: number;
  market: string;
  loginLink = '/login?flow=sched_flow&returnUrl=/ourdoctors/provider/bookyourappointment';
  newSchedulingAppLink = '/ourdoctors/provider/bookyourappointment';
  isCaptchaEnabled: boolean;
  embedSource: string;
  useLegacyAPI: boolean;
  isCernerPageRedirect=false;
  bookingUrl_Cerner = 'https://mocka.dignityhealth.me';
  ageRestrictionRuleForDH : string;
  showAgeRestrictionRuleForDH: boolean;

  constructor(
    private configService: ConfigurationService,
    private eventPublisherService: EventPublisherService,
    private fadLinkServer: FadLinkService
  ) {
    this.runtimeConfig = this.configService.getRuntimeConfiguration();
    this.isCaptchaEnabled =
      document.querySelector('#captcha-enabled')?.getAttribute('value') === 'true' || configService.getCaptchaEnabled();
    this.market = this.configService.getConfigType() || '';
    this.embedSource = this.configService.getEmbedSource();
    this.useLegacyAPI = this.configService.useLegacyAPI();
    this.isCernerPageRedirect=this.configService.getCernerPage();

  }

  ngOnInit() {
    this.getAgeRestrictionRuleForDH();
  }

  get handleNavigateToSchedulingSpa(): boolean {
    return (
      this.configService.getEmbedSource() === 'cerner' ||
      this.configService.getEmbedSource() === 'mychart' ||
      this.fadLinkServer.isSelfHosted()
    );
  }

  get showPatientAgeRestrictions(): boolean {
    return (
      this.configService.showPatientAgeRestrictions() &&
      Boolean(this.provider?.patientAgeGroups) &&
      Boolean(this.provider?.isBookOnline) &&
      this.providerSupportsOnlineBooking() &&
      this.configService.getOnlineSchedulingType() === 'GuidedFlow'
    );
  }

  providerSupportsOnlineBooking(): boolean {
    if (!this.provider || !this.provider.offices || this.provider.offices.length === 0) {
      return false;
    }

    let supportsOnlineBooking = false;
    this.provider.offices.forEach((office: ProviderOffice) => {
      const hasOfficeWithOnlineBooking = office.addresses.find((location: OfficeAddress) => location.supportsOnlineBooking);
      if (hasOfficeWithOnlineBooking) {
        supportsOnlineBooking = true;
      }
    });

    return supportsOnlineBooking;
  }

  selectOfficeCarousel(office: OfficeAddress): void {
    this.selectedOfficeAddress = office;
    this.selectedOfficeAddress.name = office.name;
    this.selectedOfficeRank = this.officeAddresses.indexOf(office) + 1;
    this.selectedOfficeChanged.emit(office);
  }

  handleTimeSelection(selection: { time: AppointmentTime; extras: any }): void {
    const schedAppObject = {
      visitMetadata: {
        appointmentAt: selection.time.time,
        appointmentType: selection.time.appointmentTypes[0].id,
        appointmentTypeLabel: selection.extras.appointmentTypeLabel,
        clientId:
          typeof ga === 'undefined' || typeof ga.getByName('tm_ga') === 'undefined'
            ? 'error with ga'
            : ga.getByName('tm_ga').get('clientId').toString(),
        initialLoadFromPCDE: true,
        captchaEnabled: this.isCaptchaEnabled,
        newPatient: !selection.time.appointmentTypes[0].id.startsWith('established'),
        scheduleId: selection.extras.scheduleId,
        returnUrlProfile: window.location.href
      },
      facility: {
        facilityAddress: this.selectedOfficeAddress.address,
        facilityId: selection.extras.facilitySchedulingId,
        facilityLatitude: this.selectedOfficeAddress.latitude,
        facilityLongitude: this.selectedOfficeAddress.longitude,
        facilityCity: this.selectedOfficeAddress.city,
        facilityName: this.selectedOfficeAddress.name,
        facilityPhoneNumber: this.selectedOfficeAddress.phones[0],
        facilityState: this.selectedOfficeAddress.state,
        facilityZip: this.selectedOfficeAddress.zip
      },
      provider: {
        npi: this.provider.npi,
        name: this.provider.displayFullName,
        acceptsNewPatients: this.provider.acceptsNewPatients,
        imageUrl: this.provider.images[0].url,
        specialty: this.provider.primarySpecialty
      },
      support: {
        supportLink: 'https://webchat.chat4help.net/DignityMyHome/?chatUsername=username&skill=4146152381',
        supportPhone: '(844) 274-8497',
        termsLink: 'https://terms.dignityhealth.org/cm/content.asp?pid=4&lid=4&facility_id=2'
      }
    };

    const base64 = window.btoa(JSON.stringify(schedAppObject));
    const visitData = {
      data: base64
    };
    window.sessionStorage.setItem('SchedApp_visit', JSON.stringify(visitData));

    if (this.showNewSchedulingApp === true) {
      this.setWindowLocationHref(this.newSchedulingAppLink);
    } else {
      this.setWindowLocationHref(this.loginLink);
    }
  }

  setWindowLocationHref(url: string): string {
    window.location.href = url;
    return url;
  }

  navigateToSchedulingSpa(event: any): void {
    window.sessionStorage.removeItem('singleSpecialty');
    if (event) {
      this.eventPublisherService.publish(
        new FadEvent({
          type: FadEventTypes.NavigateToSchedulingOnCerner,
          data: event
        })
      );
    }

      if(this.isCernerPageRedirect)
      {
        //<------The below code is used in cerner Page to get token from my-appointment-spa----->
        this.eventPublisherService.publish(
          new FadEvent({
            type: FadEventTypes.PostApptDetailstoMyAppointment_Cerner,
            data: event
          })
        );
        const tokenInfo = window.sessionStorage.getItem(
          this.runtimeConfig.Cerner_MyAppointments_OIDC_Token
        );
        if (tokenInfo) {
          
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          this.eventPublisherService.publish(
            new FadEvent({
              type: FadEventTypes.PostOidcTokentoMyAppointment_Cerner,
              data: tokenInfo
            })
          );
        }
      }
  }

  getAgeRestrictionRuleForDH() : void {

    const divisionCodes = this.configService?.getDivisionCodes().length > 0 ? this.configService.getDivisionCodes()[0] : null;
    if (divisionCodes != null && divisionCodes.toLowerCase() == 'dignity-health') {
      this.ageRestrictionRuleForDH = typeof this.provider?.externalSystems?.find(({ systemName }) => systemName.toLowerCase() == 'agerule') !== 'undefined' ? this.provider?.externalSystems?.find(({ systemName }) => systemName.toLowerCase() == 'agerule').systemId : null;
      if (this.ageRestrictionRuleForDH != null && this.ageRestrictionRuleForDH != undefined) {
        this.showAgeRestrictionRuleForDH = true;
      }
    }
  }
}
