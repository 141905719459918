export class Banner {
  firstProviderInNetwork?: boolean;
  bannerImage?: string;
  marketName?: string;
  header?: string;
  content?: string;
  learnMoreContent?: string;
  medicalGroupLinkName?: string;
  medicalGroupLink?: string;
}
