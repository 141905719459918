import { Injectable } from '@angular/core';
import { LocationByIpResult, LocationConverter, LocationCookieData, SelectedLocation } from '../models/location';
import { Observable, of } from 'rxjs';
import { ApiResponse } from '../utils/api-response';
import { tap } from 'rxjs/operators';
import { LocationService } from './location.service';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class CookieService {
  locationSearchCookieName: string;
  constructor(private locationService: LocationService, private configurationService: ConfigurationService) {
    this.locationSearchCookieName = this.configurationService.getLocationSearchCookieName();
  }

  getRawLocationCookieFromBrowser(): string {
    return document.cookie.split(';').find((cookie: string) => cookie.trim().includes(`${this.locationSearchCookieName}=`));
  }

  addLocationCookieToBrowser(location: LocationCookieData): void {
    const key = this.locationSearchCookieName;
    const locationString = encodeURIComponent(JSON.stringify(location));
    document.cookie = `${key}=${locationString};max-age=${60 * 60 * 24 * 30};path=/`;
  }

  createLocationCookie(): Observable<ApiResponse<LocationByIpResult>> {
    if (!this.configurationService.getLocationRequiredDefaultValue()) {
      // No need to get user's location if it's not required.
      return of(null);
    }
    const locationCookie = this.getLocationCookieFromBrowser();

    if (!locationCookie || (locationCookie && locationCookie.name === '')) {
      return this.locationService.getLocationByIP().pipe(
        tap((response: ApiResponse<LocationByIpResult>) => {
          const location = response.result.location;
          this.addLocationCookieToBrowser(location);
        })
      );
    }
    return of(null);
  }

  getLocationCookieFromBrowser(): LocationCookieData {
    const rawLocationCookie = this.getRawLocationCookieFromBrowser();
    const unparsedCookie = rawLocationCookie?.replace(`${this.locationSearchCookieName}=`, '');

    if (unparsedCookie && !unparsedCookie.trim().includes('undefined')) {
      return JSON.parse(decodeURIComponent(unparsedCookie));
    }
  }

  createSelectedLocationFromLocationCookie(): SelectedLocation {
    const locationCookie: LocationCookieData = this.getLocationCookieFromBrowser();
    if (!locationCookie) {
      return null;
    }

    return LocationConverter.convertTo(locationCookie);
  }

  isBannerDisabled(): boolean {
    return document.cookie.split(';').findIndex((c) => c.trim().startsWith('BannerDisabled=')) !== -1;
  }

  setBannerDisabledCookie() {
    document.cookie = `BannerDisable=true;max-age=${60 * 60 * 24};path=/`;
  }
}
