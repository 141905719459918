import { OnlineBookingAvailability } from './online-booking-availability';
import { ProviderImage } from './provider-image';
import { ProviderOffice } from './provider-office';
import { Membership } from './membership';
import { OfficeAddress } from './office-address';
import { MedGroup } from './medgroup';
import { ProviderVideo } from './provider-video';
import { LogoDetails, MedgroupDictionary } from './configuration';
import { Banner } from './banner';
import { ProviderRating } from './provider-rating';
import { DynamicFilterContentDetails } from './DynamicFilterContentDetails';
import * as SEOFocusedList from '../SEOFocusList/seo-focus-specialties.json';
import { ExternalSystems } from './externalSystems';

export interface ProviderSuggestionResponse {
  items: ProviderSuggestion[];
  providerCount: number;
}

export interface ProviderSuggestionV2Response extends Omit<ProviderSuggestionResponse, 'items'> {
  items: ProviderV2Suggestion[];
}

export interface ProviderSuggestion {
  text: string;
  providers: Provider[];
}

export interface ProviderV2Suggestion extends Omit<ProviderSuggestion, 'text'> {
  text: ProviderTextV2[];
}

export interface KeywordSuggestionResponse {
  items: KeywordSuggestion[];
}

export interface KeywordSuggestion {
  text: string;
  textAndQuery: string;
}

export interface Provider {
  id: string;
  npi: number;
  practitionerId?: string;
  displayLastName?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  displayFullName?: string;
  gender?: string;
  onlineBookingAvailability?: OnlineBookingAvailability[];
  recordNumber?: number;
  images?: ProviderImage[];
  offices?: ProviderOffice[];
  primarySpecialty?: string;
  specialties?: string[];
  languages?: string[];
  procedures?: string[];
  conditions?: string[];
  insuranceCarriers?: string[];
  insurancePlans?: string[];
  acceptsNewPatients?: boolean;
  medicalGroupNames?: string[];
  medicalGroupIds?: string[];
  yearsOfExperience?: number;
  hasReviews?: boolean;
  acceptsVideoCalls?: boolean;
  isBookOnline?: boolean;
  practiceNames?: string[];
  hospitalNames?: string[];
  officeNames?: string[];
  sourceSystem?: string;
  sourceSystemId?: string;
  providerUpdateTime?: Date;
  availabilityUpdateTime?: Date;
  searchScore?: number;
  providerType?: string;
  serviceLines?: string[];
  serviceNames?: string[];
  philosophy?: string;
  inMyOwnWords?: string;
  degree?: string;
  medicalSchools?: string[];
  residencies?: string[];
  fellowships?: string[];
  internships?: string[];
  practicums?: string[];
  certifications?: string[];
  awards?: string[];
  memberships?: Membership[];
  onlineBookingOfficeId?: string;
  providerURL?: string;
  firstNetworkProvider?: boolean;
  videos?: ProviderVideo[];
  divisionCodes?: string[];
  marketCodes?: string[];
  rating?: ProviderRating;
  providerSchedulingId?: string;
  personalInfo?: string;
  ehrCode?: string;
  roleType?: string;
  providerIdType?: string;
  patientAgeGroups?: string;
  education?: string[];
  externalSystems?: ExternalSystems[];
  loadingRating?: boolean;
}

export interface ProviderTextV2 {
  specialty: string;
  subSpecialty: string;
  id: number;
  domainSpecialtyId: number;
  specialtyFullName: string;
  abbreviation: string;
  subSpecialtyFullName: string;
  isPrimary: boolean;
  createdDate: Date;
  modifiedDate: Date;
  externalCode: string;
  specialtyGroups: [];
  clinicalTerms: [];
  identifiers: [];
}

export class ProviderInterfaceHelper {
  static getProviderOfficesSortedByDistanceFromSearch(provider: Provider): OfficeAddress[] {
    const providerOffices = ProviderInterfaceHelper.getProviderOffices(provider);

    providerOffices.sort((x: OfficeAddress, y: OfficeAddress) => x.distanceMilesFromSearch < y.distanceMilesFromSearch ? -1 : 1);

    return providerOffices;
  }

  static getProviderOfficesSortedByRank(provider: Provider): OfficeAddress[] {
    const officeAddresses = this.getProviderOffices(provider);
    officeAddresses.sort((a: OfficeAddress, b: OfficeAddress) => a.rank - b.rank);
    return officeAddresses;
  }

  static getProviderOffices(provider: Provider): OfficeAddress[] {
    const providerOffices: OfficeAddress[] = [];

    provider.offices?.forEach((office: ProviderOffice) => {
      office.addresses.forEach((a: OfficeAddress) => {
        if (
          !providerOffices.some(
            (o) => o.longitude === a.longitude && o.latitude === a.latitude && o.address.toLowerCase() === a.address.toLowerCase()
          )
        ) {
          const address: OfficeAddress = { ...a };
          address.name = a?.name ?? office.name;
          address.id = office?.id ?? office.id;
          providerOffices.push(address);
        }
      });
    });

    return providerOffices;
  }

  static hasProviderOfficePhone(provider: Provider): boolean {
    return !!ProviderInterfaceHelper.getProviderOfficesSortedByDistanceFromSearch(provider)[0]?.phones;
  }

  static ProviderTokenHandler(provider: Provider, getToKnowContent: string): string {
    return getToKnowContent.replace(/\{(providerDisplayFullNamePlaceHolder)\}/g, provider.displayFullName.toString());
  }

  static MedGroupTokenHandler(medGroup: MedGroup, getToKnowContent: string): string {
    getToKnowContent = getToKnowContent
      .replace(/\{(medGroupUrlPlaceHolder)\}/g, medGroup.url)
      .replace(/\{(medGroupTitlePlaceHolder)\}/g, medGroup.title);
    return getToKnowContent;
  }

  static MedGroupLinkTokenHandler(banner: Banner): Banner {
    banner.content = banner?.content
      ?.replace(/\{(bannerMedicalGroupLinkPlaceholder)\}/g, banner?.medicalGroupLink)
      .replace(/\{(bannerMedicalGroupNamePlaceholder)\}/g, banner?.medicalGroupLinkName);
    return banner;
  }

  static BannerImageTokenHandler(logoPath: string, banner: Banner): Banner {
    banner.content = banner?.content?.replace(/\{(bannerImagePlaceHolder)\}/g, logoPath);
    return banner;
  }

  static getProviderLogosAEM(provider: Provider, medGroupDictionary: MedgroupDictionary[]): string[] {
    const distinctLogoPath =
      provider.medicalGroupIds
        ?.map((medId) => medGroupDictionary.find((mg) => mg.code === medId)?.logoPathAEM?.trim())
        .filter((url) => !!url) ?? [];

    return distinctLogoPath.filter((n, i) => distinctLogoPath.indexOf(n) === i);
  }

  static getProviderLogoDetailsAEM(provider: Provider, medGroupDictionary: MedgroupDictionary[]): LogoDetails[] {
    const logoDetailsList:  LogoDetails[] = [];
    const distinctLogoPath =
      provider.medicalGroupIds
        ?.map((medId) => medGroupDictionary.find((mg) => mg.code === medId));
        
    const unique = [...new Map(distinctLogoPath.map((m) => [m.logoPathAEM, m])).values()];    
    
    for(const logo of unique) {
      if((logo?.logoPathAEM != null && logo?.logoPathAEM.trim() != '')) {
        logoDetailsList.push({ altName: logo?.logoDescription, url: logo?.logoPathAEM });
      }
    }   
    return logoDetailsList;
  }

  static getProviderLogosFAD(provider: Provider, medGroupDictionary: MedgroupDictionary[]): string[] {
    const distinctLogoPath =
      provider.medicalGroupIds
        ?.map((medId) => medGroupDictionary.find((mg) => mg.code === medId)?.logoPathFAD?.trim())
        .filter((url) => !!url) ?? [];

    return distinctLogoPath.filter((n, i) => distinctLogoPath.indexOf(n) === i);
  }

  static getProviderLogoDetailsFAD(provider: Provider, medGroupDictionary: MedgroupDictionary[]): LogoDetails[] {
    const logoDetailsList:  LogoDetails[] = [];
    const distinctLogoPath =
      provider.medicalGroupIds
        ?.map((medId) => medGroupDictionary.find((mg) => mg.code === medId));
        
    const unique = [...new Map(distinctLogoPath.map((m) => [m.logoPathFAD, m])).values()];    
    for(const logo of unique) {
      if((logo?.logoPathFAD != null && logo?.logoPathFAD.trim() != '')) {
        logoDetailsList.push({ altName: logo?.logoDescription, url: logo?.logoPathFAD });
      }
    }   
    return logoDetailsList;
  }

  static removeProviderNamePrefix(firstName?: string, lastName?: string, degree?: string): string {
    let degreeFormatted = '';
    if (degree) {
      degreeFormatted = `, ${degree}`;
    }
    return `${firstName} ${lastName}${degreeFormatted}`;
  }

  static getProviderSpecialities(provider: Provider): string[] {
    const providerSpecialities = [];

    if (provider.specialties.length > 0) {
      provider.specialties.forEach((value: string) => {
        providerSpecialities.push(value);
      });
    } else {
      providerSpecialities.push(provider.primarySpecialty);
    }

    return providerSpecialities;
  }

  static formatProviderDisplayFullName(provider: Provider): string {
    // Formatting displayFullName
    return provider.degree
      ? `${provider.firstName} ${provider.lastName}, ${provider.degree}`
      : `${provider.firstName} ${provider.lastName}`;
  }

  static dynamicContentLinkTokenHandler(dynamicFilterContentDetails: DynamicFilterContentDetails): DynamicFilterContentDetails {
    dynamicFilterContentDetails.paragraph = dynamicFilterContentDetails?.paragraph?.replace(
      /\{(learnMoreLinkPlaceholder)\}/g,
      dynamicFilterContentDetails?.learnMoreLink ?? ''
    );

    return dynamicFilterContentDetails;
  }

  static getProviderTopSpecialities(provider: Provider): string[] {
    const providerSpecialities: any = [];
    providerSpecialities.push(provider.primarySpecialty);
    if (provider.specialties.length > 1) {
      const listSpecialties = (SEOFocusedList as any).default;
      const filteredSpecialities =
        listSpecialties['specialties'].length > 0
          ? provider.specialties.filter((x) => listSpecialties['specialties'].indexOf(x) > -1 && x !== provider.primarySpecialty)
          : [];
      if (filteredSpecialities.length > 0) {
        providerSpecialities.push(filteredSpecialities[0]);
      } else {
        providerSpecialities.push(provider.specialties.filter((x) => x !== provider.primarySpecialty)[0]);
      }
    }
    return providerSpecialities;
  }

  static getProviderNonSEOSpecialities(provider: Provider, specialities: string[]): string[] {
    const nonSEOSpecialities: any = [];
    if (provider.specialties.length > 1 && specialities.length > 1) {
      nonSEOSpecialities.push(
        provider.specialties.filter(function(e) {
          return this.indexOf(e) < 0;
        }, specialities)
      );
    }
    return nonSEOSpecialities[0];
  }
}
